import * as React from "react";
import { TouchableOpacity, Text } from "react-native";
import { styles } from "../Style/styles.js";

TouchableOpacity.defaultProps = { activeOpacity: 0.5 };
const SmallStartButton = ({ onPress, title }) => (
  <TouchableOpacity onPress={onPress} style={styles.startButton_Small}>
    <Text style={styles.appButtonText_SmallButton}>{title}</Text>
  </TouchableOpacity>
);

export default SmallStartButton;
