/*
 *
 * Interview #1 Survey
 * Contains 8 questions all contained within this file
 *
 */

import {
  Text,
  View,
  Image,
  TouchableOpacity,
  TextInput,
  ScrollView,
} from "react-native";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { SafeAreaView } from "react-native-safe-area-context";
import * as React from "react";
import { styles } from "../Style/styles.js";
import PatientObject from "../HelperClasses/patientObject.js";
import RadioGroup from "react-native-radio-buttons-group";
import config from "../config.json";
import { refreshAuthToken } from "../Functions/RefreshAuthToken";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  heightPercentageToDP as hp,
  widthPercentageToDP,
} from "react-native-responsive-screen";

const getData = async () => {
  try {
    const jsonValue = await AsyncStorage.getItem("@patient_ID");
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
  }
};

const q1 = [
  "For each of the following possible side effects, pick the face from the rating scale on the next pages that represents your level of tolerance, if you were to experience it.",
];
const q1SymptomList = [
  "Loss of Appetite/Weight",
  "Brittle Nails",
  "Cough",
  "Decreased Energy",
  "Diarrhea",
  "Difficulty Sleeping",
  "Dizziness",
  "Dry, Peeling Skin",
  "Fatigue",
  "Hair Loss",
  "Higher Chance of Bleeding",
  "Itchy Skin",
  "Jaundice",
  "More Trips to the Clinic",
  "Nausea",
  "Numbness/Tingling",
  "Pain",
  "Shortness of Breath",
];

const q2 = [
  "Which one of the unwanted side effects of treatment from the previous questions would you hate the most?",
];
const q2SymptomList = [
  "Loss of Appetite/Weight",
  "Brittle Nails",
  "Cough",
  "Decreased Energy",
  "Diarrhea",
  "Difficulty Sleeping",
  "Dizziness",
  "Dry, Peeling Skin",
  "Hair Loss",
  "Higher Chance of Bleeding",
  "Itchy Skin",
  "Jaundice",
  "More Trips to the Clinic",
  "Nausea",
  "Numbness/Tingling",
  "Pain",
  "Fatigue",
  "Shortness of Breath",
];

const q3 = [
  "Would you be willing to tolerate ALL of the side effects of treatment listed previously if it meant you might live longer?",
];
const q3RadioButtonsData = [
  {
    id: "yes",
    label: "Yes",
    selected: false,
    color: "#AD122A",
    size: hp("4.5%"),
    labelStyle: styles.radioButtonLabels,
    containerStyle: styles.radioButtonContainer,
  },
  {
    id: "no",
    label: "No",
    selected: false,
    color: "#AD122A",
    size: hp("4.5%"),
    labelStyle: styles.radioButtonLabels,
    containerStyle: styles.radioButtonContainer,
  },
  {
    id: "idk",
    label: "I Don't Know",
    selected: false,
    color: "#AD122A",
    size: hp("4.5%"),
    labelStyle: styles.radioButtonLabels,
    containerStyle: styles.radioButtonContainer,
  },
];

const q4 = [
  "To reach an important personal goal, how long would you be willing to tolerate a higher chance of irritating or uncomfortable side effects of chemotherapy?",
];
const q4RadioButtonsData = [
  {
    id: "none",
    label: "No Time Period",
    selected: false,
    color: "#AD122A",
    size: hp("4.5%"),
    labelStyle: styles.radioButtonLabels,
    containerStyle: styles.radioButtonContainer,
  },
  {
    id: "months",
    label: "Months",
    selected: false,
    color: "#AD122A",
    size: hp("4.5%"),
    labelStyle: styles.radioButtonLabels,
    containerStyle: styles.radioButtonContainer,
  },
  {
    id: "years",
    label: "Years",
    selected: false,
    color: "#AD122A",
    size: hp("4.5%"),
    labelStyle: styles.radioButtonLabels,
    containerStyle: styles.radioButtonContainer,
  },
];

const q5 = ["How would you define 'treatment success' (of your chemotherapy)?"];

const q6 = [
  "Quality of Life questions, how the patient has been feeling recently",
];
const q6ListCategories = ["Physical", "Functional", "Additional Concerns"];
const q6List = [
  "I have a lack of energy", //Physical questions section
  "I have nausea",
  "Because of my physical condition, I have trouble meeting the needs of my family",
  "I have pain",
  "I am bothered by side effects of treatment",
  "I feel ill",
  "I am forced to spend time in bed", //end Physical questions
  "I am able to work (include work at home)", //Functional questions section
  "My work (include work at home) is fulfilling",
  "I am able to enjoy life",
  "I have accepted my illness",
  "I am sleeping well",
  "I am enjoying the things I usually do for fun",
  "I am content with the quality of my life right now", //end Functional questions
  "I have been short of breath", //Additional concerns section
  "I am losing weight",
  "My thinking is clear",
  "I have been coughing",
  "I have a good appetite",
  "I feel tightness in my chest",
  "Breathing is easy for me", //end Additional concerns questions
];
const numInCategory = 7; //Total number of questions in each section for q6
const numCategory2 = 7; //Number that the second category (Functional Questions) starts
const numCategory3 = 14; //Number that the third category (Additional Concerns Questions) starts

const q7 = [
  'Sort the the list below of unwanted side effects from "bad" to "least bad"\nThat is, drag and drop the list from the one you would hate the most on top (#1) and the one you would hate the least (#9) on the bottom',
];
const q7Data = [
  {
    id: "a",
    text: "Brittle nails",
  },
  {
    id: "b",
    text: "Decreased energy (excessive fatigue)",
  },
  {
    id: "c",
    text: "Dizziness",
  },
  {
    id: "d",
    text: "Unusual / increased bleeding",
  },
  {
    id: "e",
    text: "Jaundice (yellow skin)",
  },
  {
    id: "f",
    text: "More trips to clinic for treatment",
  },
  {
    id: "g",
    text: "Numbness and / or tingling",
  },
  {
    id: "h",
    text: "Shortness of breath",
  },
  {
    id: "i",
    text: "A lot more expensive",
  },
];

const q8 = ["Please type in any additional comments for your doctor:"];

//This is the number of questions for this survey
const numQuestions = 8;
const q1Index = 0;
const q2Index = 1;
const q3Index = 2;
const q4Index = 3;
const q5Index = 4;
const q6Index = 5;
// const q7Index = 6;
const q8Index = 7;

export default class Interview1Screen extends React.Component {
  constructor(props) {
    super(props);

    this.patient = new PatientObject();

    global.q1UnansweredSymptoms = [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
    ]; //contains the indexes of all the unanswered q1 Symptoms
    global.q1QuestionCounter = 1;
    global.q1SymptomListWithOtherAnswered = new Array(19);
    global.q6QuestionCounter = 0;

    global.answerListQ1 = [];
    global.answerListQ2 = [];
    global.answerListQ3 = [];
    global.answerListQ4 = [];
    global.answerListQ5 = [];
    global.answerListQ6 = [];
    global.answerListQ7 = [];
    global.answerListQ8 = [];

    this.textSpacer =
      "                                        " +
      "                                     " +
      "                                     " +
      "                                     " +
      "                                     " +
      "                                     " +
      "                                     ";

    global.int1AnswerArray = [
      answerListQ1,
      answerListQ2,
      answerListQ3,
      answerListQ4,
      answerListQ5,
      answerListQ6,
      answerListQ7,
      answerListQ8,
    ];

    /*
     * Contains and initializes all state variables in Interview #1
     */
    this.state = {
      //Survey current page number state variable
      currentPageNumber: 0,
      //Q1 state variables
      q1CurrentlySelectedQuestion: 0, //the index of the current question in q1SymptomList
      Q1button1Answered: false,
      Q1button2Answered: false,
      Q1button3Answered: false,
      Q1button4Answered: false,
      Q1button5Answered: false,
      Q1button6Answered: false,
      Q1button7Answered: false,
      Q1button8Answered: false,
      Q1button9Answered: false,
      Q1button10Answered: false,
      Q1button11Answered: false,
      Q1button12Answered: false,
      Q1button13Answered: false,
      Q1button14Answered: false,
      Q1button15Answered: false,
      Q1button16Answered: false,
      Q1button17Answered: false,
      Q1button18Answered: false,
      Q1otherButtonSelected: false,
      q1CurrentlySelectedAnswer: " ",
      justOneFlag: false,
      symptomInputStored: "Other",
      symptomInputTemp: "Other",
      //Q2 state variables
      Q2button1Selected: false,
      Q2button2Selected: false,
      Q2button3Selected: false,
      Q2button4Selected: false,
      Q2button5Selected: false,
      Q2button6Selected: false,
      Q2button7Selected: false,
      Q2button8Selected: false,
      Q2button9Selected: false,
      Q2button10Selected: false,
      Q2button11Selected: false,
      Q2button12Selected: false,
      Q2button13Selected: false,
      Q2button14Selected: false,
      Q2button15Selected: false,
      Q2button16Selected: false,
      Q2button17Selected: false,
      Q2button18Selected: false,
      Q2button19Selected: false,
      q2SelectedAnswer: "",
      //Q3 state variables
      q3RadioButtons: q3RadioButtonsData,
      q3SelectedAnswer: "",
      //Q4 state variables
      q4RadioButtons: q4RadioButtonsData,
      q4SelectedAnswer: "",
      //Q5 state variable
      q5SelectedAnswer: "type in response",
      //Q6 state variables
      q6Category: q6ListCategories[0],
      q6CurrentQuestion: q6List[0],
      q6CurrentlySelectedAnswer: " ",
      q6QuestionDisplay: 1,
      //Q7 state variable
      q7DragList: q7Data,
      //Q8 state variable
      q8SelectedAnswer: "none",
    };
    this.count = 1;
  }

  /* * *
   *
   * The following contains functions for Question#1
   *
   * * */

  /*
   * Helper method for q1NextQuestion
   * Returns true if current question has been answered
   * Returns false if current question has not been answered
   */
  hasThisQuestionBeenAnswered(numQ) {
    let answered = false;
    switch (numQ) {
      case 1:
        answered = this.state.Q1button1Answered;
        break;
      case 2:
        answered = this.state.Q1button2Answered;
        break;
      case 3:
        answered = this.state.Q1button3Answered;
        break;
      case 4:
        answered = this.state.Q1button4Answered;
        break;
      case 5:
        answered = this.state.Q1button5Answered;
        break;
      case 6:
        answered = this.state.Q1button6Answered;
        break;
      case 7:
        answered = this.state.Q1button7Answered;
        break;
      case 8:
        answered = this.state.Q1button8Answered;
        break;
      case 9:
        answered = this.state.Q1button9Answered;
        break;
      case 10:
        answered = this.state.Q1button10Answered;
        break;
      case 11:
        answered = this.state.Q1button11Answered;
        break;
      case 12:
        answered = this.state.Q1button12Answered;
        break;
      case 13:
        answered = this.state.Q1button13Answered;
        break;
      case 14:
        answered = this.state.Q1button14Answered;
        break;
      case 15:
        answered = this.state.Q1button15Answered;
        break;
      case 16:
        answered = this.state.Q1button16Answered;
        break;
      case 17:
        answered = this.state.Q1button17Answered;
        break;
      case 18:
        answered = this.state.Q1button18Answered;
        break;
      case 19:
        answered = this.state.Q1otherButtonSelected;
        break;
    }
    return answered;
  }
  /*
   * Helper method to q1NextQuestion. Checks to see if the "Other" question has been answered
   * in order for the survey to move on to Q#2 at the correct time
   * aka when the answerListQ1[] is completely filled out depending on the "Other" question
   */
  q1LengthCheck() {
    if (this.hasThisQuestionBeenAnswered(19)) {
      return q1SymptomList.length + 1;
    } else {
      return q1SymptomList.length;
    }
  }
  /*
   * Q1 updates the selected emoji as the answer
   */
  updateSelectedAnswer(updatedAnswer) {
    this.setState({
      q1CurrentlySelectedAnswer: updatedAnswer,
    });
  }
  /*
   * Purpose: This method sets the next question for Q1 from the q1UnansweredSymptoms array
   * and adds to the q1QuestionCounter
   * Variables Modified: q1QuestionCounter (increasing) and q1CurrentQuestion (the new question)
   */
  q1NextQuestion() {
    if (this.state.q1CurrentlySelectedAnswer !== " ") {
      let i = 0;

      if (q1QuestionCounter === this.q1LengthCheck()) {
        // if (q1QuestionCounter >= q1SymptomList.length) {
        this.updateAnswerListQ1(this.state.q1CurrentlySelectedQuestion);
        this.updatePageController(3);
      } else {
        //There is still questions to be answered
        this.updateAnswerListQ1(this.state.q1CurrentlySelectedQuestion);
        if (this.state.justOneFlag) {
          //if the question is unanswered do this
          if (
            !this.hasThisQuestionBeenAnswered(
              this.state.q1CurrentlySelectedQuestion + 1
            )
          ) {
            //selecting the correct index of the unanswered array to match the q1Symptom array
            while (
              q1UnansweredSymptoms[i] !== this.state.q1CurrentlySelectedQuestion
            ) {
              i++;
            }
            //delete selected index from unanswered array since it is now answered.
            delete q1UnansweredSymptoms[i];
          }
          //reset flag
          this.setState({
            justOneFlag: false,
            //add on here instead? to q1QuestionCounter?
          });
          this.updatePageController(0);
        } else {
          //when cycling through symptoms, just delete first index and shift elements down
          q1UnansweredSymptoms.shift();
        }
        if (
          !this.hasThisQuestionBeenAnswered(
            this.state.q1CurrentlySelectedQuestion + 1
          )
        ) {
          q1QuestionCounter++;
        }
      }
      //When using delete, will make an element undefined. So when they become the first element, shift out
      while (q1UnansweredSymptoms[0] === undefined) {
        q1UnansweredSymptoms.shift();
      }
      this.setState({
        // q1CurrentlySelectedQuestion: q1UnansweredSymptoms[i],
        q1CurrentlySelectedQuestion: q1UnansweredSymptoms[0],
      });
      //console.table(answerListQ1);
      //console.table(q1UnansweredSymptoms);
    } else {
      alert("Please select an answer before proceeding.");
    }
  }
  /*
   * Highlights answered Q1 buttons to red
   */
  toggleHighlightQ1(newSelectedButton) {
    switch (newSelectedButton) {
      case 1:
        this.setState({ Q1button1Answered: true });
        break;
      case 2:
        this.setState({ Q1button2Answered: true });
        break;
      case 3:
        this.setState({ Q1button3Answered: true });
        break;
      case 4:
        this.setState({ Q1button4Answered: true });
        break;
      case 5:
        this.setState({ Q1button5Answered: true });
        break;
      case 6:
        this.setState({ Q1button6Answered: true });
        break;
      case 7:
        this.setState({ Q1button7Answered: true });
        break;
      case 8:
        this.setState({ Q1button8Answered: true });
        break;
      case 9:
        this.setState({ Q1button9Answered: true });
        break;
      case 10:
        this.setState({ Q1button10Answered: true });
        break;
      case 11:
        this.setState({ Q1button11Answered: true });
        break;
      case 12:
        this.setState({ Q1button12Answered: true });
        break;
      case 13:
        this.setState({ Q1button13Answered: true });
        break;
      case 14:
        this.setState({ Q1button14Answered: true });
        break;
      case 15:
        this.setState({ Q1button15Answered: true });
        break;
      case 16:
        this.setState({ Q1button16Answered: true });
        break;
      case 17:
        this.setState({ Q1button17Answered: true });
        break;
      case 18:
        this.setState({ Q1button18Answered: true });
        break;
      case 19:
        this.setState({ Q1otherButtonSelected: true });
        break;
    }
  }
  /*
   * Q1, allows the patient to answer just the one question instead of cycling through them
   */
  handleGoToQuestion(chosenQuestion) {
    //sets justOneFlag to true so it can be handled differently than running through consecutive questions
    this.setState({
      justOneFlag: true,
      q1CurrentlySelectedQuestion: chosenQuestion,
    });
    if (chosenQuestion <= 17) {
      this.updatePageController(1);
    }
    //else is entered when the 'other' button is chosen
    else {
      this.updatePageController(2);
    }
  }
  /*
   * Q1, 'other' symptom entry
   */
  handleSymptom = (textInput) => {
    this.setState({
      symptomInputTemp: textInput,
    });
  };
  /*
   * Q1, 'other' symptom
   * Adds Symptom to q1AnswerList
   */
  handleAddSymptomToSymptomList() {
    if (
      this.state.symptomInputStored === this.state.symptomInputTemp ||
      this.state.q1CurrentlySelectedAnswer === " "
    ) {
      alert(
        "Please enter in a symptom and rate that symptom before continuing"
      );
    } else {
      this.updateAnswerListQ1(this.state.q1CurrentlySelectedQuestion); //????????????????????
      this.setState({
        symptomInputStored: this.state.symptomInputTemp,
        q1CurrentlySelectedAnswer: " ",
      });
      this.updatePageController(0);
      this.setState({
        q1CurrentlySelectedQuestion: q1UnansweredSymptoms[0],
        justOneFlag: false,
      });
    }
    if (!this.hasThisQuestionBeenAnswered(19)) {
      q1QuestionCounter++;
    }
  }
  /*
   * Checks if the optional "Other" symptom was filled in
   * Returns the const q1SymptomList if "Other" was left unchanged
   * If Changed: Returns q1SymptomListWithOtherAnswered after filling it with the values
   * from q1SymptomList and the added/changed "Other" symptom, symptomInputStored
   */
  q1SymptomListWithOtherIfFilledIn() {
    if (this.state.symptomInputStored === "Other") {
      return q1SymptomList;
    } else {
      this.q1SymptomListWithOtherAnswered = JSON.parse(
        JSON.stringify(q1SymptomList)
      );
      this.q1SymptomListWithOtherAnswered.push(this.state.symptomInputStored);
      return this.q1SymptomListWithOtherAnswered;
    }
  }
  /*
   * Deselects emoji
   */
  deselectQ1Buttons() {
    this.setState({
      q1CurrentlySelectedAnswer: " ",
    });
  }
  /*
   * Updates answerListQ1 with selected answer
   */
  updateAnswerListQ1(index) {
    answerListQ1[index] = this.state.q1CurrentlySelectedAnswer;
    this.toggleHighlightQ1(index + 1);
  }

  /* * *
   *
   * The following contains functions for Question#2
   *
   * * */

  /*
   * This function toggles the highlighting the buttons for Q2
   * There will only be one button highlighted at any one time
   * Also will change the answer to unanswered, or "" when there are no buttons selected
   */
  toggleHighlightQ2 = (newSelectedButton) => {
    switch (newSelectedButton) {
      case 1:
        this.setState(
          { Q2button1Selected: !this.state.Q2button1Selected },
          () => {
            if (this.state.Q2button1Selected === false) {
              this.setState({
                q2SelectedAnswer: "",
              });
            } else {
              this.setState({
                q2SelectedAnswer: q2SymptomList[0],
              });
            }
          }
        );
        this.setState({
          Q2button2Selected: false,
          Q2button3Selected: false,
          Q2button4Selected: false,
          Q2button5Selected: false,
          Q2button6Selected: false,
          Q2button7Selected: false,
          Q2button8Selected: false,
          Q2button9Selected: false,
          Q2button10Selected: false,
          Q2button11Selected: false,
          Q2button12Selected: false,
          Q2button13Selected: false,
          Q2button14Selected: false,
          Q2button15Selected: false,
          Q2button16Selected: false,
          Q2button17Selected: false,
          Q2button18Selected: false,
          Q2button19Selected: false,
        });
        break;
      case 2:
        this.setState(
          { Q2button2Selected: !this.state.Q2button2Selected },
          () => {
            if (this.state.Q2button2Selected === false) {
              this.setState({
                q2SelectedAnswer: "",
              });
            } else {
              this.setState({
                q2SelectedAnswer: q2SymptomList[1],
              });
            }
          }
        );
        this.setState({
          Q2button1Selected: false,
          Q2button3Selected: false,
          Q2button4Selected: false,
          Q2button5Selected: false,
          Q2button6Selected: false,
          Q2button7Selected: false,
          Q2button8Selected: false,
          Q2button9Selected: false,
          Q2button10Selected: false,
          Q2button11Selected: false,
          Q2button12Selected: false,
          Q2button13Selected: false,
          Q2button14Selected: false,
          Q2button15Selected: false,
          Q2button16Selected: false,
          Q2button17Selected: false,
          Q2button18Selected: false,
          Q2button19Selected: false,
        });
        break;
      case 3:
        this.setState(
          { Q2button3Selected: !this.state.Q2button3Selected },
          () => {
            if (this.state.Q2button3Selected === false) {
              this.setState({
                q2SelectedAnswer: "",
              });
            } else {
              this.setState({
                q2SelectedAnswer: q2SymptomList[2],
              });
            }
          }
        );
        this.setState({
          Q2button1Selected: false,
          Q2button2Selected: false,
          Q2button4Selected: false,
          Q2button5Selected: false,
          Q2button6Selected: false,
          Q2button7Selected: false,
          Q2button8Selected: false,
          Q2button9Selected: false,
          Q2button10Selected: false,
          Q2button11Selected: false,
          Q2button12Selected: false,
          Q2button13Selected: false,
          Q2button14Selected: false,
          Q2button15Selected: false,
          Q2button16Selected: false,
          Q2button17Selected: false,
          Q2button18Selected: false,
          Q2button19Selected: false,
        });
        break;
      case 4:
        this.setState(
          { Q2button4Selected: !this.state.Q2button4Selected },
          () => {
            if (this.state.Q2button4Selected === false) {
              this.setState({
                q2SelectedAnswer: "",
              });
            } else {
              this.setState({
                q2SelectedAnswer: q2SymptomList[3],
              });
            }
          }
        );
        this.setState({
          Q2button1Selected: false,
          Q2button2Selected: false,
          Q2button3Selected: false,
          Q2button5Selected: false,
          Q2button6Selected: false,
          Q2button7Selected: false,
          Q2button8Selected: false,
          Q2button9Selected: false,
          Q2button10Selected: false,
          Q2button11Selected: false,
          Q2button12Selected: false,
          Q2button13Selected: false,
          Q2button14Selected: false,
          Q2button15Selected: false,
          Q2button16Selected: false,
          Q2button17Selected: false,
          Q2button18Selected: false,
          Q2button19Selected: false,
        });
        break;
      case 5:
        this.setState(
          { Q2button5Selected: !this.state.Q2button5Selected },
          () => {
            if (this.state.Q2button5Selected === false) {
              this.setState({
                q2SelectedAnswer: "",
              });
            } else {
              this.setState({
                q2SelectedAnswer: q2SymptomList[4],
              });
            }
          }
        );
        this.setState({
          Q2button1Selected: false,
          Q2button2Selected: false,
          Q2button3Selected: false,
          Q2button4Selected: false,
          Q2button6Selected: false,
          Q2button7Selected: false,
          Q2button8Selected: false,
          Q2button9Selected: false,
          Q2button10Selected: false,
          Q2button11Selected: false,
          Q2button12Selected: false,
          Q2button13Selected: false,
          Q2button14Selected: false,
          Q2button15Selected: false,
          Q2button16Selected: false,
          Q2button17Selected: false,
          Q2button18Selected: false,
          Q2button19Selected: false,
        });
        break;
      case 6:
        this.setState(
          { Q2button6Selected: !this.state.Q2button6Selected },
          () => {
            if (this.state.Q2button6Selected === false) {
              this.setState({
                q2SelectedAnswer: "",
              });
            } else {
              this.setState({
                q2SelectedAnswer: q2SymptomList[5],
              });
            }
          }
        );
        this.setState({
          Q2button1Selected: false,
          Q2button2Selected: false,
          Q2button3Selected: false,
          Q2button4Selected: false,
          Q2button5Selected: false,
          Q2button7Selected: false,
          Q2button8Selected: false,
          Q2button9Selected: false,
          Q2button10Selected: false,
          Q2button11Selected: false,
          Q2button12Selected: false,
          Q2button13Selected: false,
          Q2button14Selected: false,
          Q2button15Selected: false,
          Q2button16Selected: false,
          Q2button17Selected: false,
          Q2button18Selected: false,
          Q2button19Selected: false,
        });
        break;
      case 7:
        this.setState(
          { Q2button7Selected: !this.state.Q2button7Selected },
          () => {
            if (this.state.Q2button7Selected === false) {
              this.setState({
                q2SelectedAnswer: "",
              });
            } else {
              this.setState({
                q2SelectedAnswer: q2SymptomList[6],
              });
            }
          }
        );
        this.setState({
          Q2button1Selected: false,
          Q2button2Selected: false,
          Q2button3Selected: false,
          Q2button4Selected: false,
          Q2button5Selected: false,
          Q2button6Selected: false,
          Q2button8Selected: false,
          Q2button9Selected: false,
          Q2button10Selected: false,
          Q2button11Selected: false,
          Q2button12Selected: false,
          Q2button13Selected: false,
          Q2button14Selected: false,
          Q2button15Selected: false,
          Q2button16Selected: false,
          Q2button17Selected: false,
          Q2button18Selected: false,
          Q2button19Selected: false,
        });
        break;
      case 8:
        this.setState(
          { Q2button8Selected: !this.state.Q2button8Selected },
          () => {
            if (this.state.Q2button8Selected === false) {
              this.setState({
                q2SelectedAnswer: "",
              });
            } else {
              this.setState({
                q2SelectedAnswer: q2SymptomList[7],
              });
            }
          }
        );
        this.setState({
          Q2button1Selected: false,
          Q2button2Selected: false,
          Q2button3Selected: false,
          Q2button4Selected: false,
          Q2button5Selected: false,
          Q2button6Selected: false,
          Q2button7Selected: false,
          Q2button9Selected: false,
          Q2button10Selected: false,
          Q2button11Selected: false,
          Q2button12Selected: false,
          Q2button13Selected: false,
          Q2button14Selected: false,
          Q2button15Selected: false,
          Q2button16Selected: false,
          Q2button17Selected: false,
          Q2button18Selected: false,
          Q2button19Selected: false,
        });
        break;
      case 9:
        this.setState(
          { Q2button9Selected: !this.state.Q2button9Selected },
          () => {
            if (this.state.Q2button9Selected === false) {
              this.setState({
                q2SelectedAnswer: "",
              });
            } else {
              this.setState({
                q2SelectedAnswer: q2SymptomList[8],
              });
            }
          }
        );
        this.setState({
          Q2button1Selected: false,
          Q2button2Selected: false,
          Q2button3Selected: false,
          Q2button4Selected: false,
          Q2button5Selected: false,
          Q2button6Selected: false,
          Q2button7Selected: false,
          Q2button8Selected: false,
          Q2button10Selected: false,
          Q2button11Selected: false,
          Q2button12Selected: false,
          Q2button13Selected: false,
          Q2button14Selected: false,
          Q2button15Selected: false,
          Q2button16Selected: false,
          Q2button17Selected: false,
          Q2button18Selected: false,
          Q2button19Selected: false,
        });
        break;
      case 10:
        this.setState(
          { Q2button10Selected: !this.state.Q2button10Selected },
          () => {
            if (this.state.Q2button10Selected === false) {
              this.setState({
                q2SelectedAnswer: "",
              });
            } else {
              this.setState({
                q2SelectedAnswer: q2SymptomList[9],
              });
            }
          }
        );
        this.setState({
          Q2button1Selected: false,
          Q2button2Selected: false,
          Q2button3Selected: false,
          Q2button4Selected: false,
          Q2button5Selected: false,
          Q2button6Selected: false,
          Q2button7Selected: false,
          Q2button8Selected: false,
          Q2button9Selected: false,
          Q2button11Selected: false,
          Q2button12Selected: false,
          Q2button13Selected: false,
          Q2button14Selected: false,
          Q2button15Selected: false,
          Q2button16Selected: false,
          Q2button17Selected: false,
          Q2button18Selected: false,
          Q2button19Selected: false,
        });
        break;
      case 11:
        this.setState(
          { Q2button11Selected: !this.state.Q2button11Selected },
          () => {
            if (this.state.Q2button11Selected === false) {
              this.setState({
                q2SelectedAnswer: "",
              });
            } else {
              this.setState({
                q2SelectedAnswer: q2SymptomList[10],
              });
            }
          }
        );
        this.setState({
          Q2button1Selected: false,
          Q2button2Selected: false,
          Q2button3Selected: false,
          Q2button4Selected: false,
          Q2button5Selected: false,
          Q2button6Selected: false,
          Q2button7Selected: false,
          Q2button8Selected: false,
          Q2button9Selected: false,
          Q2button10Selected: false,
          Q2button12Selected: false,
          Q2button13Selected: false,
          Q2button14Selected: false,
          Q2button15Selected: false,
          Q2button16Selected: false,
          Q2button17Selected: false,
          Q2button18Selected: false,
          Q2button19Selected: false,
        });
        break;
      case 12:
        this.setState(
          { Q2button12Selected: !this.state.Q2button12Selected },
          () => {
            if (this.state.Q2button12Selected === false) {
              this.setState({
                q2SelectedAnswer: "",
              });
            } else {
              this.setState({
                q2SelectedAnswer: q2SymptomList[11],
              });
            }
          }
        );
        this.setState({
          Q2button1Selected: false,
          Q2button2Selected: false,
          Q2button3Selected: false,
          Q2button4Selected: false,
          Q2button5Selected: false,
          Q2button6Selected: false,
          Q2button7Selected: false,
          Q2button8Selected: false,
          Q2button9Selected: false,
          Q2button10Selected: false,
          Q2button11Selected: false,
          Q2button13Selected: false,
          Q2button14Selected: false,
          Q2button15Selected: false,
          Q2button16Selected: false,
          Q2button17Selected: false,
          Q2button18Selected: false,
          Q2button19Selected: false,
        });
        break;
      case 13:
        this.setState(
          { Q2button13Selected: !this.state.Q2button13Selected },
          () => {
            if (this.state.Q2button13Selected === false) {
              this.setState({
                q2SelectedAnswer: "",
              });
            } else {
              this.setState({
                q2SelectedAnswer: q2SymptomList[12],
              });
            }
          }
        );
        this.setState({
          Q2button1Selected: false,
          Q2button2Selected: false,
          Q2button3Selected: false,
          Q2button4Selected: false,
          Q2button5Selected: false,
          Q2button6Selected: false,
          Q2button7Selected: false,
          Q2button8Selected: false,
          Q2button9Selected: false,
          Q2button10Selected: false,
          Q2button11Selected: false,
          Q2button12Selected: false,
          Q2button14Selected: false,
          Q2button15Selected: false,
          Q2button16Selected: false,
          Q2button17Selected: false,
          Q2button18Selected: false,
          Q2button19Selected: false,
        });
        break;
      case 14:
        this.setState(
          { Q2button14Selected: !this.state.Q2button14Selected },
          () => {
            if (this.state.Q2button14Selected === false) {
              this.setState({
                q2SelectedAnswer: "",
              });
            } else {
              this.setState({
                q2SelectedAnswer: q2SymptomList[13],
              });
            }
          }
        );
        this.setState({
          Q2button1Selected: false,
          Q2button2Selected: false,
          Q2button3Selected: false,
          Q2button4Selected: false,
          Q2button5Selected: false,
          Q2button6Selected: false,
          Q2button7Selected: false,
          Q2button8Selected: false,
          Q2button9Selected: false,
          Q2button10Selected: false,
          Q2button11Selected: false,
          Q2button12Selected: false,
          Q2button13Selected: false,
          Q2button15Selected: false,
          Q2button16Selected: false,
          Q2button17Selected: false,
          Q2button18Selected: false,
          Q2button19Selected: false,
        });
        break;
      case 15:
        this.setState(
          { Q2button15Selected: !this.state.Q2button15Selected },
          () => {
            if (this.state.Q2button15Selected === false) {
              this.setState({
                q2SelectedAnswer: "",
              });
            } else {
              this.setState({
                q2SelectedAnswer: q2SymptomList[14],
              });
            }
          }
        );
        this.setState({
          Q2button1Selected: false,
          Q2button2Selected: false,
          Q2button3Selected: false,
          Q2button4Selected: false,
          Q2button5Selected: false,
          Q2button6Selected: false,
          Q2button7Selected: false,
          Q2button8Selected: false,
          Q2button9Selected: false,
          Q2button10Selected: false,
          Q2button11Selected: false,
          Q2button12Selected: false,
          Q2button13Selected: false,
          Q2button14Selected: false,
          Q2button16Selected: false,
          Q2button17Selected: false,
          Q2button18Selected: false,
          Q2button19Selected: false,
        });
        break;
      case 16:
        this.setState(
          { Q2button16Selected: !this.state.Q2button16Selected },
          () => {
            if (this.state.Q2button16Selected === false) {
              this.setState({
                q2SelectedAnswer: "",
              });
            } else {
              this.setState({
                q2SelectedAnswer: q2SymptomList[15],
              });
            }
          }
        );
        this.setState({
          Q2button1Selected: false,
          Q2button2Selected: false,
          Q2button3Selected: false,
          Q2button4Selected: false,
          Q2button5Selected: false,
          Q2button6Selected: false,
          Q2button7Selected: false,
          Q2button8Selected: false,
          Q2button9Selected: false,
          Q2button10Selected: false,
          Q2button11Selected: false,
          Q2button12Selected: false,
          Q2button13Selected: false,
          Q2button14Selected: false,
          Q2button15Selected: false,
          Q2button17Selected: false,
          Q2button18Selected: false,
          Q2button19Selected: false,
        });
        break;
      case 17:
        this.setState(
          { Q2button17Selected: !this.state.Q2button17Selected },
          () => {
            if (this.state.Q2button17Selected === false) {
              this.setState({
                q2SelectedAnswer: "",
              });
            } else {
              this.setState({
                q2SelectedAnswer: q2SymptomList[16],
              });
            }
          }
        );
        this.setState({
          Q2button1Selected: false,
          Q2button2Selected: false,
          Q2button3Selected: false,
          Q2button4Selected: false,
          Q2button5Selected: false,
          Q2button6Selected: false,
          Q2button7Selected: false,
          Q2button8Selected: false,
          Q2button9Selected: false,
          Q2button10Selected: false,
          Q2button11Selected: false,
          Q2button12Selected: false,
          Q2button13Selected: false,
          Q2button14Selected: false,
          Q2button15Selected: false,
          Q2button16Selected: false,
          Q2button18Selected: false,
          Q2button19Selected: false,
        });
        break;
      case 18:
        this.setState(
          { Q2button18Selected: !this.state.Q2button18Selected },
          () => {
            if (this.state.Q2button18Selected === false) {
              this.setState({
                q2SelectedAnswer: "",
              });
            } else {
              this.setState({
                q2SelectedAnswer: q2SymptomList[17],
              });
            }
          }
        );
        this.setState({
          Q2button1Selected: false,
          Q2button2Selected: false,
          Q2button3Selected: false,
          Q2button4Selected: false,
          Q2button5Selected: false,
          Q2button6Selected: false,
          Q2button7Selected: false,
          Q2button8Selected: false,
          Q2button9Selected: false,
          Q2button10Selected: false,
          Q2button11Selected: false,
          Q2button12Selected: false,
          Q2button13Selected: false,
          Q2button14Selected: false,
          Q2button15Selected: false,
          Q2button16Selected: false,
          Q2button17Selected: false,
          Q2button19Selected: false,
        });
        break;
      case 19:
        this.setState(
          { Q2button19Selected: !this.state.Q2button19Selected },
          () => {
            if (this.state.Q2button19Selected === false) {
              this.setState({
                q2SelectedAnswer: "",
              });
            } else {
              this.setState({
                q2SelectedAnswer: this.state.symptomInputStored,
              });
            }
          }
        );
        this.setState({
          Q2button1Selected: false,
          Q2button2Selected: false,
          Q2button3Selected: false,
          Q2button4Selected: false,
          Q2button5Selected: false,
          Q2button6Selected: false,
          Q2button7Selected: false,
          Q2button8Selected: false,
          Q2button9Selected: false,
          Q2button10Selected: false,
          Q2button11Selected: false,
          Q2button12Selected: false,
          Q2button13Selected: false,
          Q2button14Selected: false,
          Q2button15Selected: false,
          Q2button16Selected: false,
          Q2button17Selected: false,
          Q2button18Selected: false,
        });
        break;
    }
  };
  /*
   * Updates answerListQ2 with selected answer
   */
  updateAnswerListQ2() {
    answerListQ2[0] = this.state.q2SelectedAnswer;
  }

  /* * *
   *
   * The following contains functions for Question#3
   *
   * * */

  /*
   * Handles the visual state of selecting a radio button
   * as well as storing the selected answer
   */
  onPressRadioQ3 = (radioButtonsArray) => {
    this.setState({ q3RadioButtons: radioButtonsArray });
    if (radioButtonsArray[0].selected) {
      this.setState({ q3SelectedAnswer: radioButtonsArray[0].label });
    }
    if (radioButtonsArray[1].selected) {
      this.setState({ q3SelectedAnswer: radioButtonsArray[1].label });
    }
    if (radioButtonsArray[2].selected) {
      this.setState({ q3SelectedAnswer: radioButtonsArray[2].label });
    }
  };
  /*
   * RadioGroup does not do this on its own
   * So- radio buttons are reset everytime they are navigated away from
   * Within the survey
   */
  resetRadioQ3 = () => {
    for (let i = 0; i < q3RadioButtonsData.length; i++) {
      q3RadioButtonsData[i].selected = false;
    }
    this.setState({ q3SelectedAnswer: "" });
  };
  /*
   * Updates answerListQ3 with selected answer
   */
  updateAnswerListQ3() {
    answerListQ3[0] = this.state.q3SelectedAnswer;
  }

  /* * *
   *
   * The following contains functions for Question#4
   *
   * * */

  /*
   * Handles the visual state of selecting a radio button
   * as well as storing the selected answer
   */
  onPressRadioQ4 = (radioButtonsArray) => {
    this.setState({ q4RadioButtons: radioButtonsArray });
    if (radioButtonsArray[0].selected) {
      this.setState({ q4SelectedAnswer: radioButtonsArray[0].label });
    }
    if (radioButtonsArray[1].selected) {
      this.setState({ q4SelectedAnswer: radioButtonsArray[1].label });
    }
    if (radioButtonsArray[2].selected) {
      this.setState({ q4SelectedAnswer: radioButtonsArray[2].label });
    }
  };
  /*
   * RadioGroup does not do this on its own
   * So- radio buttons are reset everytime they are navigated away from
   * Within the survey
   */
  resetRadioQ4 = () => {
    for (let i = 0; i < q4RadioButtonsData.length; i++) {
      q4RadioButtonsData[i].selected = false;
    }
    this.setState({ q4SelectedAnswer: "" });
  };
  /*
   * Updates answerListQ4 with selected answer
   */
  updateAnswerListQ4() {
    answerListQ4[0] = this.state.q4SelectedAnswer;
  }

  /* * *
   *
   * The following contains functions for Question#5
   *
   * * */

  /*
   * Updates answerListQ5 with selected answer
   */
  updateAnswerListQ5() {
    answerListQ5[0] = this.state.q5SelectedAnswer;
  }

  /* * *
   *
   * The following contains functions for Question#6
   *
   * * */

  /*
   * Q6 updates the selected emoji as the answer
   */
  updateQ6SelectedAnswer(updatedAnswer) {
    this.setState({
      q6CurrentlySelectedAnswer: updatedAnswer,
    });
  }
  /*
   * This method sets the next question for Q6 and adds to the q6QuestionCounter
   * Variables Modified:
   */
  q6NextQuestion() {
    if (this.state.q6CurrentlySelectedAnswer !== " ") {
      if (q6QuestionCounter + 1 === q6List.length) {
        this.updatePageController(8);
      } else {
        q6QuestionCounter++;
        this.setState({
          q6QuestionDisplay: this.state.q6QuestionDisplay + 1,
        });
        this.setState({
          q6CurrentQuestion: q6List[q6QuestionCounter],
        });
        if (this.state.q6QuestionDisplay === numInCategory) {
          this.setState({
            q6QuestionDisplay: 1,
          });
        }
        if (q6QuestionCounter === numCategory2) {
          this.setState({ q6Category: q6ListCategories[1] });
        }
        if (q6QuestionCounter === numCategory3) {
          this.setState({ q6Category: q6ListCategories[2] });
        }
      }
    } else {
      alert("Please select an answer before proceeding.");
    }
  }
  /*
   * This handles displaying the correct text according to which question in Q6 it is
   */
  q6DisplayText() {
    if (
      this.state.q6CurrentQuestion === q6List[7] ||
      this.state.q6CurrentQuestion === q6List[8] ||
      this.state.q6CurrentQuestion === q6List[9] ||
      this.state.q6CurrentQuestion === q6List[10] ||
      this.state.q6CurrentQuestion === q6List[11] ||
      this.state.q6CurrentQuestion === q6List[12] ||
      this.state.q6CurrentQuestion === q6List[13] ||
      this.state.q6CurrentQuestion === q6List[16] ||
      this.state.q6CurrentQuestion === q6List[18] ||
      this.state.q6CurrentQuestion === q6List[20]
    ) {
      return (
        <View style={styles.SmallEmojiContainerI1}>
          <View style={styles.emojiLabels}>
            <TouchableOpacity
              style={styles.EmojiButtons}
              onPress={() => {
                this.updateQ6SelectedAnswer("😀");
              }}
            >
              <Text style={styles.EmojiText}>😀</Text>
              <Text style={styles.TextUnderEmojis}>Very Much</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.emojiLabels}>
            <TouchableOpacity
              style={styles.EmojiButtons}
              onPress={() => {
                this.updateQ6SelectedAnswer("😐");
              }}
            >
              <Text style={styles.EmojiText}>😐</Text>
              <Text style={styles.TextUnderEmojis}>Somewhat</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.emojiLabels}>
            <TouchableOpacity
              style={styles.EmojiButtons}
              onPress={() => {
                this.updateQ6SelectedAnswer("😟");
              }}
            >
              <Text style={styles.EmojiText}>😟</Text>
              <Text style={styles.TextUnderEmojis}>Not at all</Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    } else {
      return (
        <View style={styles.SmallEmojiContainerI1}>
          <View style={styles.emojiLabels}>
            <TouchableOpacity
              style={styles.EmojiButtons}
              onPress={() => {
                this.updateQ6SelectedAnswer("😀");
              }}
            >
              <Text style={styles.EmojiText}>😀</Text>
              <Text style={styles.TextUnderEmojis}>Not at all</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.emojiLabels}>
            <TouchableOpacity
              style={styles.EmojiButtons}
              onPress={() => {
                this.updateQ6SelectedAnswer("😐");
              }}
            >
              <Text style={styles.EmojiText}>😐</Text>
              <Text style={styles.TextUnderEmojis}>Somewhat</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.emojiLabels}>
            <TouchableOpacity
              style={styles.EmojiButtons}
              onPress={() => {
                this.updateQ6SelectedAnswer("😟");
              }}
            >
              <Text style={styles.EmojiText}>😟</Text>
              <Text style={styles.TextUnderEmojis}>Very Much</Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    }
  }
  /*
   * Deselects emoji
   */
  deselectQ6Buttons() {
    this.setState({
      q6CurrentlySelectedAnswer: " ",
    });
  }
  /*
   * Updates answerListQ6 with selected answer
   */
  updateAnswerListQ6(index) {
    answerListQ6[index] = this.state.q6CurrentlySelectedAnswer;
  }

  /* * *
   *
   * The following contains functions for Question#7
   *
   * * */

  /*
   * Function for Q7 Drag & Drop
   */
  handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(this.state.q7DragList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    this.setState({
      q7DragList: items,
    });
  };
  /*
   * Updates answerListQ7 with selected answer
   */
  updateAnswerListQ7() {
    this.answerListQ7 = JSON.parse(JSON.stringify(this.state.q7DragList));
  }
  /*
   * Allows proper display of Q#7 on results page
   */
  getJustTexts = (array) => {
    let arr = [];
    for (let i = 0; i < array.length; i++) {
      arr.push(array[i].text);
    }
    return arr;
  };

  /* * *
   *
   * The following contains functions for Question#8
   *
   * * */

  /*
   * Updates answerListQ8 with selected answer
   */
  updateAnswerListQ8() {
    answerListQ8[0] = this.state.q8SelectedAnswer;
  }

  /* * * *
   *
   * The following contains functions that are used for multiple survey questions
   *
   * * * */

  /*
   * Purpose: This method goes back to the previous question
   * else handles all questions
   * the prior ifs handle questions Q1, Q2, Q6
   * Q1 has many subquestions
   * Q2 previous should go back to Q1 base page
   * Q6 has many subquestions
   */
  previousQuestion(currentQuestion) {
    if (currentQuestion === 1) {
      this.deselectQ1Buttons();
      //previous button goes to starting page for Q1 (page 0)
      this.setState({
        justOneFlag: false,
        currentPageNumber: 0,
        q1CurrentlySelectedQuestion: q1UnansweredSymptoms[0],
      });
    } else if (currentQuestion === 2) {
      this.setState({
        currentPageNumber: 0,
      });
    } else if (currentQuestion === 6) {
      //Q6 previous buttons need to move through each question as well as to the previous question
      if (q6QuestionCounter > 0) {
        q6QuestionCounter--;
        if (this.state.q6QuestionDisplay === 1) {
          this.setState({
            q6QuestionDisplay: numInCategory,
          });
        } else {
          this.setState({
            q6QuestionDisplay: this.state.q6QuestionDisplay - 1,
          });
        }
        this.setState({
          q6CurrentQuestion: q6List[q6QuestionCounter],
        });
        if (q6QuestionCounter + 1 === numCategory2) {
          this.setState({ q6Category: q6ListCategories[0] });
        }
        if (q6QuestionCounter + 1 === numCategory3) {
          this.setState({ q6Category: q6ListCategories[1] });
        }
      } else {
        this.updatePageController(currentQuestion);
        q6QuestionCounter = 0;
        this.setState({
          q6CurrentQuestion: q6List[0],
          q6QuestionDisplay: 1,
        });
      }
    } else {
      this.updatePageController(currentQuestion);
    }
  }
  /*
   * Page Numbers:
   * 0 : Select each possible side effect and display different color if rating has been inputted
   * 1 : After side effect selection, rate it with the three emojis
   * 2 : Optional side effect input page
   * 3 : Most hated side effect page
   * 4 : Would you be willing to tolerate ALL of the side effects of treatment...
   * 5 : To reach an important personal goal...
   * 6 : How would you define "treatment success"?
   * 7 : Quality of life questions (3 parts)
   * 8 : Ranking side effects exercise
   * 9 : Comments. Patient writes in any comments they want to add at the end.
   * 10 : Results Page
   */
  updatePageController(newPageNumber) {
    if (this.state.currentPageNumber < newPageNumber) {
      switch (this.state.currentPageNumber) {
        case 0:
          if (newPageNumber === 3 && q1QuestionCounter < q1SymptomList.length) {
            alert("Please complete each symptom before proceeding");
          } else {
            this.setState({
              currentPageNumber: newPageNumber,
            });
          }
          break;
        case 1:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        case 2:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        case 3:
          if (this.state.q2SelectedAnswer !== "") {
            this.setState({
              currentPageNumber: newPageNumber,
            });
          } else {
            alert("Please select an answer before proceeding");
          }
          break;
        case 4:
          if (this.state.q3SelectedAnswer !== "") {
            this.setState({
              currentPageNumber: newPageNumber,
            });
          } else {
            alert("Please select an answer before proceeding");
          }
          break;
        case 5:
          if (this.state.q4SelectedAnswer !== "") {
            this.setState({
              currentPageNumber: newPageNumber,
            });
          } else {
            alert("Please select an answer before proceeding");
          }
          break;
        case 6:
          if (this.state.q5SelectedAnswer !== "type in response") {
            this.setState({
              currentPageNumber: newPageNumber,
            });
          } else {
            alert("Please type in an answer before proceeding");
          }
          break;
        case 7:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        case 8:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        case 9:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        case 10:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
      }
    } else {
      switch (this.state.currentPageNumber) {
        case 0:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        case 1:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        case 2:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        case 3:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        case 4:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        case 5:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        case 6:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        case 7:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        case 8:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        case 9:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        case 10:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
      }
    }
  }
  /*
   * Purpose: This method submits each questions and answer list to the patientID answer array
   * If submitting the last question, will 'package' the answers.
   * This adds on the patient ID, time stamp, and survey type onto the patient object array
   */
  submit() {
    this.packageAnswers();

    var xhr = new XMLHttpRequest();
    xhr.open("POST", config.BASE_URL + "/document", true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + PatientObject.getToken());

    let promiseResult;
    getData().then((patientID) => {
      promiseResult = patientID;
      if (promiseResult != null) {
        xhr.send(
          JSON.stringify({
            PatientID: patientID,
            Responses: this.patient.getInt1Response(),
            SurveyType: this.patient.getSurveyType(),
          })
        );
      }
    });
    refreshAuthToken();
  }
  /*
   * Helper method for submit()
   * If submitting the last question, will 'package' the answers.
   */
  packageAnswers() {
    this.patient.setTimeStamp();
    this.patient.setSurveyType("Interview1");

    this.patient.addInt1Response(
      1,
      q1,
      this.q1SymptomListWithOtherIfFilledIn(),
      answerListQ1
    );
    this.patient.addInt1Response(2, q2, "", answerListQ2);
    this.patient.addInt1Response(3, q3, "", answerListQ3);
    this.patient.addInt1Response(4, q4, "", answerListQ4);
    this.patient.addInt1Response(5, q5, "", answerListQ5);
    this.patient.addInt1Response(6, q6, q6List, answerListQ6);
    this.patient.addInt1Response(7, q7, "", this.answerListQ7);
    this.patient.addInt1Response(8, q8, "", answerListQ8);
  }

  /* * * * * * * * * * *
   *
   * RENDER VIEWS
   *
   * * * * * * * * * * */
  render() {
    return (
      <SafeAreaView style={styles.RootContainer}>
        {/*Start of Question 1*/}
        {this.state.currentPageNumber === 0 ? (
          <View style={styles.RootSubPageContainerQ1}>
            <View style={styles.QuestionsContainerI1}>
              <Text style={styles.SmallerQuestionsTextI1Q1}>{q1}</Text>
            </View>
            <View style={styles.OptionsContainerI1}>
              <View style={{ flexDirection: "row" }}>
                <View style={styles.SymptomStack}>
                  {this.state.Q1button1Answered ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.handleGoToQuestion(0);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[0]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.handleGoToQuestion(0);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[0]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q1button2Answered ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.handleGoToQuestion(1);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[1]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.handleGoToQuestion(1);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[1]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q1button3Answered ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.handleGoToQuestion(2);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[2]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.handleGoToQuestion(2);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[2]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q1button4Answered ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.handleGoToQuestion(3);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[3]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.handleGoToQuestion(3);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[3]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q1button5Answered ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.handleGoToQuestion(4);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[4]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.handleGoToQuestion(4);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[4]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q1button6Answered ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.handleGoToQuestion(5);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[5]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.handleGoToQuestion(5);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[5]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q1button7Answered ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.handleGoToQuestion(6);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[6]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.handleGoToQuestion(6);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[6]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q1button8Answered ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.handleGoToQuestion(7);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[7]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.handleGoToQuestion(7);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[7]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q1button9Answered ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.handleGoToQuestion(8);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[8]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.handleGoToQuestion(8);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[8]}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>

                {/* Column Break */}

                <View style={styles.SymptomStack}>
                  {this.state.Q1button10Answered ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.handleGoToQuestion(9);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[9]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.handleGoToQuestion(9);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[9]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q1button11Answered ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.handleGoToQuestion(10);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[10]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.handleGoToQuestion(10);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[10]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q1button12Answered ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.handleGoToQuestion(11);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[11]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.handleGoToQuestion(11);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[11]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q1button13Answered ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.handleGoToQuestion(12);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[12]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.handleGoToQuestion(12);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[12]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q1button14Answered ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.handleGoToQuestion(13);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[13]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.handleGoToQuestion(13);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[13]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q1button15Answered ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.handleGoToQuestion(14);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[14]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.handleGoToQuestion(14);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[14]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q1button16Answered ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.handleGoToQuestion(15);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[15]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.handleGoToQuestion(15);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[15]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q1button17Answered ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.handleGoToQuestion(16);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[16]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.handleGoToQuestion(16);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[16]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q1button18Answered ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.handleGoToQuestion(17);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[17]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.handleGoToQuestion(17);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q1SymptomList[17]}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              </View>
              <View style={styles.SymptomStackOther}>
                {this.state.Q1otherButtonSelected ? (
                  <TouchableOpacity
                    style={styles.ButtonInMultiColumnSelected}
                    onPress={() => {
                      this.handleGoToQuestion(18);
                    }}
                  >
                    <Text style={styles.ButtonTextInColumnStack}>
                      {this.state.symptomInputStored}
                    </Text>
                  </TouchableOpacity>
                ) : (
                  <TouchableOpacity
                    style={styles.ButtonInMultiColumnUnselected}
                    onPress={() => {
                      this.handleGoToQuestion(18);
                    }}
                  >
                    <Text style={styles.ButtonTextInColumnStack}>Other</Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
            {/*<View style={styles.OptionsContainerI1}>*/}
            <View style={styles.NavigationButtonContainerI1}>
              <View style={styles.ButtonWithLabelUnder}>
                <TouchableOpacity
                  onPress={() => {
                    this.updatePageController(1);
                  }}
                >
                  <Image
                    source={require("../assets/icons/box-arrow-in-right.svg")}
                    resizeMode="contain"
                    style={styles.NextButton}
                  />
                  <Text style={styles.TextUnderEmojis}>Complete Full List</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.ButtonWithLabelUnder}>
                <TouchableOpacity
                  onPress={() => {
                    this.updatePageController(3);
                  }}
                >
                  <Image
                    source={require("../assets/icons/RightArrow.png")}
                    resizeMode="contain"
                    style={styles.NextButton}
                  />
                  <Text style={styles.TextUnderEmojis}>Next Question</Text>
                </TouchableOpacity>
              </View>
            </View>
            {/*</View>*/}
          </View>
        ) : null}
        {/*
         *
         *
         * This is a divider between page 1 and page 2 of this survey.
         * Question 1
         *
         *
         *
         *
         */}
        {this.state.currentPageNumber === 1 ? (
          <View style={styles.RootSubPageContainer}>
            <View style={styles.QuestionsContainerI1Q1}>
              <View
                style={{ flex: 1, marginHorizontal: widthPercentageToDP("2%") }}
              >
                <Text style={styles.SmallerQuestionsText}>
                  Pick the face from the Distress Rating Scale that represents
                  your level of tolerance. Select 'next' when desired response
                  is chosen.
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.ProgressCounter}>
                  {"["}
                  {q1QuestionCounter}/
                  {this.hasThisQuestionBeenAnswered(19)
                    ? q1SymptomList.length + 1
                    : q1SymptomList.length}
                  {"]"}
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.QuestionsText}>
                  {q1SymptomList[this.state.q1CurrentlySelectedQuestion]}
                </Text>
              </View>
            </View>
            <View style={styles.SmallEmojiContainerI1}>
              <View style={styles.emojiLabels}>
                <TouchableOpacity
                  style={styles.EmojiButtons}
                  onPress={() => {
                    this.updateSelectedAnswer("😀");
                  }}
                >
                  <Text style={styles.EmojiText}>😀</Text>
                  <Text style={styles.TextUnderEmojis}>Not at all</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.emojiLabels}>
                <TouchableOpacity
                  style={styles.EmojiButtons}
                  onPress={() => {
                    this.updateSelectedAnswer("😐");
                  }}
                >
                  <Text style={styles.EmojiText}>😐</Text>
                  <Text style={styles.TextUnderEmojis}>Somewhat</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.emojiLabels}>
                <TouchableOpacity
                  style={styles.EmojiButtons}
                  onPress={() => {
                    this.updateSelectedAnswer("😟");
                  }}
                >
                  <Text style={styles.EmojiText}>😟</Text>
                  <Text style={styles.TextUnderEmojis}>Very Much</Text>
                </TouchableOpacity>
              </View>
            </View>
            <View style={styles.BigEmojiContainerI1}>
              <Text style={styles.BigSelectedEmoji}>
                {this.state.q1CurrentlySelectedAnswer}
              </Text>
            </View>
            {/*Q1 Navigation for rating page*/}
            <View style={styles.NavigationButtonContainerI1}>
              <View style={styles.ButtonWithLabelUnderQ1}>
                <TouchableOpacity
                  onPress={() => {
                    this.previousQuestion(1);
                  }}
                >
                  <Image
                    source={require("../assets/icons/BackArrow.png")}
                    resizeMode="contain"
                    style={styles.ReturnButtonQ1}
                  />
                  <Text style={styles.TextUnderEmojis}>Previous</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.ButtonWithLabelUnderQ1}>
                <TouchableOpacity
                  onPress={() => {
                    this.q1NextQuestion();
                    this.deselectQ1Buttons();
                  }}
                >
                  <Image
                    source={require("../assets/icons/RightArrow.png")}
                    resizeMode="contain"
                    style={styles.NextButtonQ1}
                  />
                  <Text style={styles.TextUnderEmojis}>Next</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        ) : null}
        {/*
         *
         *
         * This is a divider between the symptom rating page of this survey and the other symptom page.
         * Question 1
         *
         *
         *
         *
         */}
        {this.state.currentPageNumber === 2 ? (
          <View style={styles.RootSubPageContainerQ1}>
            <View style={styles.QuestionsContainerI1Q1Other}>
              <View style={{ flex: 1, justifyContent: "center" }}>
                <Text style={styles.SmallerQuestionsTextI1Q1}>
                  Please enter one additional symptom in the space below and
                  rate your tolerance of it.
                </Text>
              </View>
              <View style={{ flex: 1, justifyContent: "center" }}>
                <Text style={styles.SmallerQuestionsTextI1Q1}>
                  (Press submit when finished)
                </Text>
              </View>
              <View style={{ flex: 1, justifyContent: "center" }}>
                <TextInput
                  style={styles.inputI1}
                  underlineColorAndroid="transparent"
                  placeholder="Enter Symptom Here"
                  placeholderTextColor="#989EA4"
                  autoCapitalize="words"
                  clearTextOnFocus="true"
                  onChangeText={this.handleSymptom}
                  maxLength={20}
                />
              </View>
            </View>
            <View style={styles.SmallEmojiContainerI1}>
              <View style={styles.emojiLabelsI1}>
                <TouchableOpacity
                  style={styles.EmojiButtons}
                  onPress={() => {
                    this.updateSelectedAnswer("😀");
                  }}
                >
                  <Text style={styles.EmojiText}>😀</Text>
                  <Text style={styles.TextUnderEmojis}>Not at all</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.emojiLabelsI1}>
                <TouchableOpacity
                  style={styles.EmojiButtons}
                  onPress={() => {
                    this.updateSelectedAnswer("😐");
                  }}
                >
                  <Text style={styles.EmojiText}>😐</Text>
                  <Text style={styles.TextUnderEmojis}>Somewhat</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.emojiLabelsI1}>
                <TouchableOpacity
                  style={styles.EmojiButtons}
                  onPress={() => {
                    this.updateSelectedAnswer("😟");
                  }}
                >
                  <Text style={styles.EmojiText}>😟</Text>

                  <Text style={styles.TextUnderEmojis}>Very Much</Text>
                </TouchableOpacity>
              </View>
            </View>
            <View style={styles.BigEmojiContainer}>
              <Text style={styles.BigSelectedEmoji}>
                {this.state.q1CurrentlySelectedAnswer}
              </Text>
            </View>
            {/* Other page = Q1 Navigation*/}
            <View style={styles.NavigationButtonContainerI1}>
              <View style={styles.ButtonWithLabelUnderQ1}>
                <TouchableOpacity
                  onPress={() => {
                    this.previousQuestion(1);
                  }}
                >
                  <Image
                    source={require("../assets/icons/BackArrow.png")}
                    resizeMode="contain"
                    style={styles.ReturnButton}
                  />
                  <Text style={styles.TextUnderEmojis}>Previous</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.ButtonWithLabelUnderQ1}>
                <TouchableOpacity
                  onPress={() => {
                    this.handleAddSymptomToSymptomList();
                  }}
                >
                  <Image
                    source={require("../assets/icons/RightArrow.png")}
                    resizeMode="contain"
                    style={styles.NextButton}
                  />
                  <Text style={styles.TextUnderEmojis}>Submit</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        ) : null}
        {/* End Q1 */}

        {/*
         *
         *
         * Divider for Question 2
         * Page 3
         *
         */}
        {this.state.currentPageNumber === 3 ? (
          <View style={styles.RootSubPageContainerQ1}>
            <View style={styles.QuestionsContainerI1}>
              <Text style={styles.SmallerQuestionsText}>{q2}</Text>
            </View>
            <View style={styles.OptionsContainerI1}>
              <View style={{ flexDirection: "row" }}>
                <View style={styles.SymptomStack}>
                  {this.state.Q2button1Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlightQ2(1);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[0]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlightQ2(1);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[0]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q2button2Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlightQ2(2);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[1]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlightQ2(2);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[1]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q2button3Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlightQ2(3);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[2]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlightQ2(3);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[2]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q2button4Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlightQ2(4);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[3]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlightQ2(4);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[3]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q2button5Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlightQ2(5);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[4]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlightQ2(5);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[4]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q2button6Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlightQ2(6);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[5]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlightQ2(6);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[5]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q2button7Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlightQ2(7);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[6]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlightQ2(7);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[6]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q2button8Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlightQ2(8);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[7]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlightQ2(8);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[7]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q2button9Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlightQ2(9);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[8]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlightQ2(9);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[8]}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>

                {/* Column Break */}

                <View style={styles.Options}>
                  {this.state.Q2button10Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlightQ2(10);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[9]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlightQ2(10);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[9]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q2button11Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlightQ2(11);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[10]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlightQ2(11);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[10]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q2button12Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlightQ2(12);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[11]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlightQ2(12);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[11]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q2button13Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlightQ2(13);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[12]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlightQ2(13);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[12]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q2button14Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlightQ2(14);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[13]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlightQ2(14);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[13]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q2button15Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlightQ2(15);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[14]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlightQ2(15);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[14]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q2button16Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlightQ2(16);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[15]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlightQ2(16);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[15]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q2button17Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlightQ2(17);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[16]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlightQ2(17);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[16]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.Q2button18Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlightQ2(18);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[17]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlightQ2(18);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {q2SymptomList[17]}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              </View>
              {/* The following only appears when the Other button was
                   selected and filled out in Q1 */}
              {this.state.Q1otherButtonSelected ? (
                <View>
                  {this.state.Q2button19Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlightQ2(19);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {this.state.symptomInputStored}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlightQ2(19);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {this.state.symptomInputStored}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              ) : null}
            </View>
            <View style={styles.NavigationButtonContainerI1}>
              <View style={styles.ButtonWithLabelUnderQ1}>
                <TouchableOpacity
                  onPress={() => {
                    this.previousQuestion(2);
                  }}
                >
                  <Image
                    source={require("../assets/icons/BackArrow.png")}
                    resizeMode="contain"
                    style={styles.ReturnButtonQ1}
                  />
                  <Text style={styles.TextUnderEmojis}>Previous</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.ButtonWithLabelUnderQ1}>
                <TouchableOpacity
                  onPress={() => {
                    this.updateAnswerListQ2();
                    this.updatePageController(4);
                  }}
                >
                  <Image
                    source={require("../assets/icons/RightArrow.png")}
                    resizeMode="contain"
                    style={styles.NextButton}
                  />
                  <Text style={styles.TextUnderEmojis}>Submit</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        ) : null}

        {/*
         *
         *
         * Divider for Question 3
         * Page 4
         *
         *
         */}
        {this.state.currentPageNumber === 4 ? (
          <View style={styles.RootSubPageContainer}>
            <View style={styles.QuestionsContainerI1Q3Q4Q5}>
              <Text style={styles.SmallerQuestionsTextQ3}>{q3}</Text>
            </View>
            <View style={styles.RadioContainer}>
              <RadioGroup
                radioButtons={this.state.q3RadioButtons}
                onPress={this.onPressRadioQ3}
                layout="column"
              />
            </View>
            <View style={styles.NavigationButtonContainerI1Q3Q4Q5}>
              <View style={styles.ButtonWithLabelUnderQ1}>
                <TouchableOpacity
                  onPress={() => {
                    this.previousQuestion(3);
                    this.resetRadioQ3();
                  }}
                >
                  <Image
                    source={require("../assets/icons/BackArrow.png")}
                    resizeMode="contain"
                    style={styles.ReturnButtonQ1}
                  />
                  <Text style={styles.TextUnderEmojis}>Previous</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.ButtonWithLabelUnderQ1}>
                <TouchableOpacity
                  onPress={() => {
                    this.updateAnswerListQ3();
                    this.updatePageController(5);
                    this.resetRadioQ3();
                  }}
                >
                  <Image
                    source={require("../assets/icons/RightArrow.png")}
                    resizeMode="contain"
                    style={styles.NextButton}
                  />
                  <Text style={styles.TextUnderEmojis}>Submit</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        ) : null}

        {/*
         *
         *
         * Divider for Question 4
         * Page 5
         *
         *
         */}
        {this.state.currentPageNumber === 5 ? (
          <View style={styles.RootSubPageContainer}>
            <View style={styles.QuestionsContainerI1Q3Q4Q5}>
              <Text style={styles.SmallerQuestionsTextQ3}>{q4}</Text>
            </View>
            <View style={styles.RadioContainer}>
              <RadioGroup
                radioButtons={this.state.q4RadioButtons}
                onPress={this.onPressRadioQ4}
                layout="column"
              />
            </View>
            <View style={styles.NavigationButtonContainerI1Q3Q4Q5}>
              <View style={styles.ButtonWithLabelUnderQ1}>
                <TouchableOpacity
                  onPress={() => {
                    this.previousQuestion(4);
                    this.resetRadioQ4();
                  }}
                >
                  <Image
                    source={require("../assets/icons/BackArrow.png")}
                    resizeMode="contain"
                    style={styles.ReturnButtonQ1}
                  />
                  <Text style={styles.TextUnderEmojis}>Previous</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.ButtonWithLabelUnderQ1}>
                <TouchableOpacity
                  onPress={() => {
                    this.updateAnswerListQ4();
                    this.updatePageController(6);
                    this.resetRadioQ4();
                  }}
                >
                  <Image
                    source={require("../assets/icons/RightArrow.png")}
                    resizeMode="contain"
                    style={styles.NextButton}
                  />
                  <Text style={styles.TextUnderEmojis}>Submit</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        ) : null}

        {/*
         *
         *
         * Divider for Question 5
         * Page 6
         *
         *
         */}
        {this.state.currentPageNumber === 6 ? (
          <View style={styles.RootSubPageContainerQ5}>
            <View style={styles.QuestionsContainerI1Q3Q4Q5}>
              <Text style={styles.SmallerQuestionsTextI1Q5}>{q5}</Text>
            </View>
            <View style={styles.inputQuestionIDContainerQ5}>
              <TextInput
                multiline={true}
                numberOfLines={4}
                style={styles.I1Input}
                maxLength={250}
                placeholder={this.state.q5SelectedAnswer}
                onChangeText={(q5SelectedAnswer) =>
                  this.setState({ q5SelectedAnswer })
                }
              />
            </View>
            <View style={styles.NavigationButtonContainerI1Q3Q4Q5}>
              <View style={styles.ButtonWithLabelUnderQ1}>
                <TouchableOpacity
                  onPress={() => {
                    this.previousQuestion(5);
                  }}
                >
                  <Image
                    source={require("../assets/icons/BackArrow.png")}
                    resizeMode="contain"
                    style={styles.ReturnButtonQ1}
                  />
                  <Text style={styles.TextUnderEmojis}>Previous</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.ButtonWithLabelUnderQ1}>
                <TouchableOpacity
                  onPress={() => {
                    this.updateAnswerListQ5();
                    this.updatePageController(7);
                  }}
                >
                  <Image
                    source={require("../assets/icons/RightArrow.png")}
                    resizeMode="contain"
                    style={styles.NextButton}
                  />
                  <Text style={styles.TextUnderEmojis}>Submit</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        ) : null}

        {/*
         *
         *
         * Divider for Question 6
         * Page 7
         *
         *
         */}
        {this.state.currentPageNumber === 7 ? (
          <View style={styles.RootSubPageContainer}>
            <View style={styles.QuestionsContainerI1Q6}>
              <Text style={styles.SmallerQuestionsTextI1Q6}>
                Pick the face from the Distress Rating Scale that represents
                your level of tolerance.{"\n"}
                Select 'next' when desired response is chosen.
              </Text>
              <Text style={styles.ProgressCounterI1Q6}>
                [{this.state.q6QuestionDisplay} of 7 of {this.state.q6Category}{" "}
                Questions]
              </Text>
              <Text style={styles.QuestionsTextI1Q6}>
                {this.state.q6CurrentQuestion}
              </Text>
            </View>
            {this.q6DisplayText()}
            <View style={styles.BigEmojiContainerI1}>
              <Text style={styles.BigSelectedEmoji}>
                {this.state.q6CurrentlySelectedAnswer}
              </Text>
            </View>
            <View style={styles.NavigationButtonContainerI1}>
              <View style={styles.ButtonWithLabelUnderQ1}>
                <TouchableOpacity
                  onPress={() => {
                    this.previousQuestion(6);
                  }}
                >
                  <Image
                    source={require("../assets/icons/BackArrow.png")}
                    resizeMode="contain"
                    style={styles.ReturnButtonQ1}
                  />
                  <Text style={styles.TextUnderEmojis}>Previous</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.ButtonWithLabelUnderQ1}>
                <TouchableOpacity
                  onPress={() => {
                    this.updateAnswerListQ6(q6QuestionCounter);
                    this.q6NextQuestion();
                    this.deselectQ6Buttons();
                  }}
                >
                  <Image
                    source={require("../assets/icons/RightArrow.png")}
                    resizeMode="contain"
                    style={styles.NextButton}
                  />
                  <Text style={styles.TextUnderEmojis}>Next</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        ) : null}

        {/*
         *
         *
         * Divider for Question 7 (drag n drop/sortable list)
         * Page 8
         *
         *
         */}
        {this.state.currentPageNumber === 8 ? (
          <View style={styles.RootSubPageContainerQ5}>
            <SafeAreaView style={{ flex: 5 }}>
              <View style={styles.QuestionsContainerI1Q7}>
                <Text style={styles.QuestionsTextI1Q7}>{q7}</Text>
              </View>
              <View style={styles.DragDrop}>
                <DragDropContext onDragEnd={this.handleOnDragEnd}>
                  <Droppable droppableId="options">
                    {(provided) => (
                      <ul
                        style={{ flex: 1 }}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {this.state.q7DragList.map((item, index) => (
                          <View
                            style={styles.Question7ListContainer}
                            key={item.id}
                          >
                            <Text style={styles.Question7Numbers}>
                              {index + 1}
                            </Text>
                            <Draggable draggableId={item.id} index={index}>
                              {(provided) => (
                                <ul
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  <View style={{ flex: 1 }}>
                                    <Text style={styles.Question7ListItems}>
                                      {item.text}
                                    </Text>
                                  </View>
                                </ul>
                              )}
                            </Draggable>
                          </View>
                        ))}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </View>
            </SafeAreaView>
            {/*</>*/}
            <View style={styles.NavigationButtonContainerI1Q7}>
              <View style={styles.ButtonWithLabelUnderQ1}>
                <TouchableOpacity
                  onPress={() => {
                    this.previousQuestion(7);
                  }}
                >
                  <Image
                    source={require("../assets/icons/BackArrow.png")}
                    resizeMode="contain"
                    style={styles.ReturnButtonQ1}
                  />
                  <Text style={styles.TextUnderEmojis}>Previous</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.ButtonWithLabelUnderQ1}>
                <TouchableOpacity
                  onPress={() => {
                    this.updateAnswerListQ7();
                    this.updatePageController(9);
                  }}
                >
                  <Image
                    source={require("../assets/icons/RightArrow.png")}
                    resizeMode="contain"
                    style={styles.NextButton}
                  />
                  <Text style={styles.TextUnderEmojis}>Next</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        ) : null}

        {/*
         *
         *
         * Divider for Question 8 (comments)
         * Page 9
         *
         *
         */}
        {this.state.currentPageNumber === 9 ? (
          <View style={styles.RootSubPageContainerQ8}>
            <View style={styles.QuestionsContainerI1Q8}>
              <Text style={styles.SmallerQuestionsTextI1Q8}>{q8}</Text>
            </View>
            <View style={styles.inputQuestionIDContainerQ8}>
              <TextInput
                multiline={true}
                numberOfLines={5}
                style={styles.I1Input}
                maxLength={350}
                placeholder={this.state.q8SelectedAnswer}
                onChangeText={(q8SelectedAnswer) =>
                  this.setState({ q8SelectedAnswer })
                }
              />
            </View>
            <View style={styles.NavigationButtonContainerI1}>
              <View style={styles.ButtonWithLabelUnderQ1}>
                <TouchableOpacity
                  onPress={() => {
                    this.previousQuestion(8);
                  }}
                >
                  <Image
                    source={require("../assets/icons/BackArrow.png")}
                    resizeMode="contain"
                    style={styles.ReturnButtonQ1}
                  />
                  <Text style={styles.TextUnderEmojis}>Previous</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.ButtonWithLabelUnderQ1}>
                <TouchableOpacity
                  onPress={() => {
                    this.updatePageController(10);
                    this.updateAnswerListQ8();
                    console.table(this.patient);
                    this.submit();
                  }}
                >
                  <Image
                    source={require("../assets/icons/RightArrow.png")}
                    resizeMode="contain"
                    style={styles.NextButton}
                  />
                  <Text style={styles.TextUnderEmojis}>Submit</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        ) : null}

        {/*
         *
         *
         * This is a divider for the results page of this survey.
         *
         *
         *
         *
         *
         */}
        {this.state.currentPageNumber === 10 ? (
          <View>
            <View style={styles.RootSubPageContainer}>
              <View style={styles.QuestionsContainer}>
                <Text style={styles.QuestionsText}>Overview</Text>
              </View>
              <ScrollView
                contentContainerStyle={{
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View style={styles.I1ResultsQuestionSeparator}>
                  {/* Results for Q1 */}
                  <Text style={styles.SmallerQuestionsText}>
                    Q1: The face chosen that represents your level of tolerance
                    of each side effect
                  </Text>
                  <View style={{ flexDirection: "row" }}>
                    <View style={styles.I1ResultsQ1}>
                      {this.q1SymptomListWithOtherIfFilledIn().map((entry) => (
                        <View key={entry}>
                          <Text style={styles.resultStyle}>{entry}</Text>
                        </View>
                      ))}
                    </View>
                    <View style={styles.I1ResultsQ1}>
                      {int1AnswerArray[q1Index].map((entry, index) => (
                        <View key={index}>
                          <Text style={styles.resultStyle}>{entry}</Text>
                        </View>
                      ))}
                    </View>
                  </View>
                  <View style={styles.I1ResultsQuestionSpacer}>{}</View>
                </View>
                <View style={styles.I1ResultsQuestionSeparator}>
                  {/* Results for Q2 */}
                  <Text style={styles.SmallerQuestionsText}>
                    Q2: Which one of the unwanted side effects of treatment from
                    the previous questions would you hate the most?
                  </Text>
                  <View style={{ flexDirection: "row" }}>
                    <View style={styles.I1ResultsQ2}>
                      <Text style={styles.resultStyle}>
                        {int1AnswerArray[q2Index]}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.I1ResultsQuestionSpacer}>{}</View>
                </View>
                <View style={styles.I1ResultsQuestionSeparator}>
                  {/* Results for Q3 */}
                  <Text style={styles.SmallerQuestionsText}>
                    Q3: Would you be willing to tolerate ALL of the side effects
                    of treatment listed previously if it meant you might live
                    longer?
                  </Text>
                  <View style={{ flexDirection: "row" }}>
                    <Text style={styles.resultStyle}>
                      {int1AnswerArray[q3Index]}
                    </Text>
                  </View>
                  <View style={styles.I1ResultsQuestionSpacer}>{}</View>
                </View>
                <View style={styles.I1ResultsQuestionSeparator}>
                  {/* Results for Q4 */}
                  <Text style={styles.SmallerQuestionsText}>
                    Q4: To reach an important personal goal, how long would you
                    be willing to tolerate a higher chance of irritating or
                    uncomfortable side effects of chemotherapy?
                  </Text>
                  <View style={{ flexDirection: "row" }}>
                    <Text style={styles.resultStyle}>
                      {int1AnswerArray[q4Index]}
                    </Text>
                  </View>
                  <View style={styles.I1ResultsQuestionSpacer}>{}</View>
                </View>
                <View style={styles.I1ResultsQuestionSeparator}>
                  {/* Results for Q5 */}
                  <Text style={styles.SmallerQuestionsText}>
                    Q5: How would you define 'treatment success' (of your
                    chemotherapy?)
                  </Text>
                  <View style={{ flexDirection: "row" }}>
                    <Text style={styles.resultStyle}>
                      {int1AnswerArray[q5Index]}
                    </Text>
                  </View>
                  <View style={styles.I1ResultsQuestionSpacer}>{}</View>
                </View>
                <View style={styles.I1ResultsQuestionSeparator}>
                  {/* Results for Q6 */}
                  <Text style={styles.SmallerQuestionsText}>
                    Q6: How important this list of statements is to you.
                  </Text>
                  <View style={{ flexDirection: "row" }}>
                    <View style={styles.I1ResultsQ6}>
                      {q6List.map((entry) => (
                        <View key={entry}>
                          <Text style={styles.resultStyleI1Q6Question}>
                            {entry}
                          </Text>
                        </View>
                      ))}
                    </View>
                    <View style={styles.I1ResultsQ6Answers}>
                      {int1AnswerArray[q6Index].map((entry, index) => (
                        <View key={index}>
                          <Text style={styles.resultStyleI1Q6Answer}>
                            {entry}
                          </Text>
                        </View>
                      ))}
                    </View>
                  </View>
                  <View style={styles.I1ResultsQuestionSpacer}>{}</View>
                </View>
                <View style={styles.I1ResultsQuestionSeparator}>
                  {/* Results for Q7 */}
                  <Text style={styles.SmallerQuestionsText}>
                    Q7: Ranking Exercise. Ordered from worst (1) to least bad
                    (9).
                  </Text>
                  <Text>{this.textSpacer}</Text>
                  <View style={{ flexDirection: "row" }}>
                    <View style={styles.I1ResultsQ7}>
                      {this.getJustTexts(this.answerListQ7).map((answer) => (
                        <View key={answer}>
                          <Text style={styles.resultStyleI1Q7}>
                            {this.count++}. {answer}
                          </Text>
                        </View>
                      ))}
                    </View>
                  </View>

                  <View style={styles.I1ResultsQuestionSpacer}>{}</View>
                </View>
                <View style={styles.I1ResultsQuestionSeparator}>
                  {/* Results for Q8 */}
                  <Text style={styles.SmallerQuestionsText}>Q8: Comments</Text>
                  <View style={{ flexDirection: "row" }}>
                    <Text style={styles.resultStyle}>
                      {int1AnswerArray[q8Index]}
                    </Text>
                  </View>
                  <View style={styles.I1ResultsQuestionSpacer}>{}</View>
                </View>
              </ScrollView>
            </View>
          </View>
        ) : null}
      </SafeAreaView>
    );
  }
}
