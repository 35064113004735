import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Button,
  Pressable,
  SafeAreaView,
} from "react-native";
import React, { useState } from "react";
import { styles } from "../Style/styles.js";
import PatientObject from "../HelperClasses/patientObject.js";
import config from "../config.json";
import { refreshAuthToken } from "../Functions/RefreshAuthToken";
import AsyncStorage from "@react-native-async-storage/async-storage";

const questionList = [
  "How much effort was made to help you understand your health issues?",
  "How much effort was made to listen to the things that matter most to you about your health issues?",
  "How much effort was made to include what matters most to you in choosing what to do next?",
];
const getData = async () => {
  try {
    const jsonValue = await AsyncStorage.getItem("@patient_ID");
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
  }
};
export default class AfterClinicScreen extends React.Component {
  constructor() {
    super();
    //this.patient = new PatientObject(patientIDStorage.getPatientID());
    this.patient = new PatientObject();
    global.questionCounter = 0;
    global.answerList = [questionList.length];
    this.state = {
      currentQuestion: questionList[0],
      showReturn: false,
      showSubmitButton: false,
      button1Selected: false,
      button2Selected: false,
      button3Selected: false,
      button4Selected: false,
      button5Selected: false,
      currentlySelectedAnswer: null,
    };
  }

  /*
   * Purpose: This method sets the next question for the survey and adds to the questionCounter
   * Variables Modified: questionCounter (increasing) and currentQuestion (the new  question)
   *
   */
  nextQuestion() {
    //checking to see if they have answered the question yet
    if (this.state.currentlySelectedAnswer != null) {
      //checking to see if we are on the last question
      if (this.state.showSubmitButton) {
        this.updateAnswerList(questionList.length - 1);
        this.submit();
      } else {
        questionCounter++;
        this.setState({
          currentQuestion: questionList[questionCounter],
        });
        this.updateAnswerList(questionCounter - 1);
      }
    }
    //throwing an alert if they have not answered yet
    else {
      alert("Please select an answer before proceeding.");
    }
  }

  /*
   * Purpose: This method goes back to the previous question for the survey and subtracts the questionCounter
   * Variables Modified: questionCounter (decreasing) and currentQuestion (the previous question)
   *
   *
   * If the question counter is at 0 the previous button will not show
   */
  previousQuestion() {
    questionCounter--;
    this.setState({
      currentQuestion: questionList[questionCounter],
    });
  }

  /*
   * Purpose: This method updates the answer list so that the answers can be stored and then sent to the DB
   * Variables Modified: answerList (changing the value in the array)
   *
   * //TODO
   * Right now this inserts the answers into an array. Eventually they will need to be sent to the DB
   */
  updateAnswerList(index) {
    answerList[index] = this.state.currentlySelectedAnswer;
  }

  /*
   * Purpose: This method tracks the state of the return button and toggles it off and on depending on where the
   * user is at in the survey.
   *
   */
  toggleReturnButton() {
    if (questionCounter > 0) {
      this.setState({
        showReturn: true,
      });
    } else {
      this.setState({
        showReturn: false,
      });
    }
  }

  /*
   * Purpose: This method tracks the state of the submit button and toggles it off and on depending on where the
   * user is at in the survey.
   *
   */
  toggleSubmitButton() {
    if (questionCounter == questionList.length - 1) {
      this.setState({
        showSubmitButton: true,
      });
    } else {
      this.setState({
        showSubmitButton: false,
      });
    }
  }

  /*
   * Purpose: This method selects buttons by turning a boolean value to true. In the render function, depending on whether or not
   * a variable is true will determine what the color of the corresponding button is.
   */
  selectButton(newSelectedButton, answer) {
    this.setState({ currentlySelectedAnswer: answer });
    switch (newSelectedButton) {
      case 1:
        this.setState({ button1Selected: true });
        break;
      case 2:
        this.setState({ button2Selected: true });
        break;
      case 3:
        this.setState({ button3Selected: true });
        break;
      case 4:
        this.setState({ button4Selected: true });
        break;
      case 5:
        this.setState({ button5Selected: true });
        break;
    }
  }

  /*
   * Purpose: This method resets all button boolean values to false as well as resets the currently selected answer
   *
   */
  deselectButton() {
    this.setState({
      button1Selected: false,
      button2Selected: false,
      button3Selected: false,
      button4Selected: false,
      button5Selected: false,
      showResults: false,
      currentlySelectedAnswer: null,
    });
  }

  /*
   * Purpose: This method tracks the state of the return button and toggles it off and on depending on where the
   * user is at in the survey.
   *
   * //TODO: make this a submit function that works
   */
  submit() {
    this.setState({
      showResults: true,
    });
    this.packageAnswers();
    var responses = [];
    for (let i = 0; i < this.patient.getSurveyResponse().length; i++) {
      responses.push({
        Question: this.patient.getSurveyResponse()[i].Question,
        Answer: this.patient.getSurveyResponse()[i].Answer,
      });
    }

    var xhr = new XMLHttpRequest();
    xhr.open("POST", config.BASE_URL + "/document", true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + PatientObject.getToken());
    let promiseResult;
    getData().then((patientID) => {
      promiseResult = patientID;
      if (promiseResult != null) {
        xhr.send(
          JSON.stringify({
            PatientID: patientID,
            Responses: responses,
            SurveyType: this.patient.getSurveyType(),
          })
        );
      }
    });

    refreshAuthToken();
  }

  /*
   * Purpose: This method takes the two current lists that we have (answerlist and selectedsymptoms) and compiles them into one patient object
   * the patient object traks timestamps, the survey type, the patient id, a list of questions and answers and could have more in the future.
   */
  packageAnswers() {
    for (let x = 0; x < answerList.length; x++) {
      this.patient.addSurveyResponse(questionList[x], answerList[x]);
    }
    this.patient.setTimeStamp();
    this.patient.setSurveyType("After-Clinic");
  }

  render() {
    return (
      //Root Container
      <SafeAreaView style={styles.RootContainer}>
        {!this.state.showResults ? (
          <View style={styles.RootSubPageContainerAC}>
            <View style={styles.QuestionsContainerAC}>
              <View style={{ flex: 2, justifyContent: "center" }}>
                <Text style={styles.SmallerQuestionsTextAC}>
                  Thinking about the appointment that you just had ...
                </Text>
              </View>
              <View style={{ flex: 1, justifyContent: "center" }}>
                <Text style={styles.ProgressCounter}>
                  {questionCounter + 1}/{questionList.length}
                </Text>
              </View>
              <View style={{ flex: 2, justifyContent: "center" }}>
                <Text style={styles.QuestionsTextAC}>
                  {this.state.currentQuestion}
                </Text>
              </View>
            </View>
            <View style={styles.OptionsContainerAC}>
              {this.state.button1Selected ? (
                <TouchableOpacity
                  style={styles.buttonSelected}
                  onPress={() => {
                    this.deselectButton();
                    this.selectButton(1, "no effort was made");
                  }}
                >
                  <Text style={styles.ButtonTextAC}>0 No effort was made</Text>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  style={styles.buttonUnselected}
                  onPress={() => {
                    this.deselectButton();
                    this.selectButton(1, "no effort was made");
                  }}
                >
                  <Text style={styles.ButtonTextAC}>0 No effort was made</Text>
                </TouchableOpacity>
              )}

              {this.state.button2Selected ? (
                <TouchableOpacity
                  style={styles.buttonSelected}
                  onPress={() => {
                    this.deselectButton();
                    this.selectButton(2, "A little effort was made");
                  }}
                >
                  <Text style={styles.ButtonTextAC}>
                    1 A little effort was made
                  </Text>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  style={styles.buttonUnselected}
                  onPress={() => {
                    this.deselectButton();
                    this.selectButton(2, "A little effort was made");
                  }}
                >
                  <Text style={styles.ButtonTextAC}>
                    1 A little effort was made
                  </Text>
                </TouchableOpacity>
              )}

              {this.state.button3Selected ? (
                <TouchableOpacity
                  style={styles.buttonSelected}
                  onPress={() => {
                    this.deselectButton();
                    this.selectButton(3, "Some effort was made");
                  }}
                >
                  <Text style={styles.ButtonTextAC}>
                    2 Some effort was made
                  </Text>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  style={styles.buttonUnselected}
                  onPress={() => {
                    this.deselectButton();
                    this.selectButton(3, "Some effort was made");
                  }}
                >
                  <Text style={styles.ButtonTextAC}>
                    2 Some effort was made
                  </Text>
                </TouchableOpacity>
              )}

              {this.state.button4Selected ? (
                <TouchableOpacity
                  style={styles.buttonSelected}
                  onPress={() => {
                    this.deselectButton();
                    this.selectButton(4, "A lot of effort was made");
                  }}
                >
                  <Text style={styles.ButtonTextAC}>
                    3 A lot of effort was made
                  </Text>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  style={styles.buttonUnselected}
                  onPress={() => {
                    this.deselectButton();
                    this.selectButton(4, "A lot of effort was made");
                  }}
                >
                  <Text style={styles.ButtonTextAC}>
                    3 A lot of effort was made
                  </Text>
                </TouchableOpacity>
              )}

              {this.state.button5Selected ? (
                <TouchableOpacity
                  style={styles.buttonSelected}
                  onPress={() => {
                    this.deselectButton();
                    this.selectButton(5, "Every effort was made");
                  }}
                >
                  <Text style={styles.ButtonTextAC}>
                    4 Every effort was made
                  </Text>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  style={styles.buttonUnselected}
                  onPress={() => {
                    this.deselectButton();
                    this.selectButton(5, "Every effort was made");
                  }}
                >
                  <Text style={styles.ButtonTextAC}>
                    4 Every effort was made
                  </Text>
                </TouchableOpacity>
              )}
            </View>
            <View style={styles.NavigationButtonContainerAC}>
              {this.state.showReturn ? (
                <View style={styles.ButtonWithLabelUnderAC}>
                  <TouchableOpacity
                    onPress={() => {
                      this.previousQuestion();
                      this.toggleReturnButton();
                      this.toggleSubmitButton();
                      this.deselectButton();
                    }}
                  >
                    <Image
                      source={require("../assets/icons/BackArrow.png")}
                      resizeMode="contain"
                      style={styles.ReturnButtonAC}
                    />
                    <Text style={styles.TextUnderEmojisAC}>Previous</Text>
                  </TouchableOpacity>
                </View>
              ) : null}
              {!this.state.showSubmitButton ? (
                <View style={styles.ButtonWithLabelUnderAC}>
                  <TouchableOpacity
                    onPress={() => {
                      this.nextQuestion();
                      this.toggleReturnButton();
                      this.toggleSubmitButton();
                      this.deselectButton();
                    }}
                  >
                    <Image
                      source={require("../assets/icons/RightArrow.png")}
                      resizeMode="contain"
                      style={styles.NextButtonAC}
                    />
                    <Text style={styles.TextUnderEmojisAC}>Next</Text>
                  </TouchableOpacity>
                </View>
              ) : (
                <View style={styles.ButtonWithLabelUnderAC}>
                  <TouchableOpacity
                    onPress={() => {
                      this.nextQuestion();
                    }}
                  >
                    <Image
                      source={require("../assets/icons/CheckMark.png")}
                      resizeMode="contain"
                      style={styles.NextButtonAC}
                    />
                    <Text style={styles.TextUnderEmojisAC}>Submit</Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>
          </View>
        ) : null}
        {this.state.showResults ? (
          <View style={styles.RootSubPageContainer}>
            <View style={styles.QuestionsContainer}>
              <Text style={styles.ResultsTitle}>Overview</Text>
            </View>
            <View style={{ flexDirection: "column" }}>
              {this.patient.getSurveyResponse().map((entry) => (
                <View
                  key={entry.Question}
                  style={styles.AfterClinicIndividualResponseContainer}
                >
                  <View>
                    <Text style={styles.AfterClinicResultText}>
                      <Text style={{ fontWeight: "bold" }}>Q: </Text>
                      <Text>{entry.Question}</Text>
                    </Text>
                  </View>
                  <View>
                    <Text style={styles.AfterClinicResultText}>
                      <Text style={{ fontWeight: "bold" }}>A: </Text>
                      <Text>{entry.Answer}</Text>
                    </Text>
                  </View>
                  <View style={styles.AfterClinicResultSeparatorLine}></View>
                </View>
              ))}
            </View>
          </View>
        ) : null}
      </SafeAreaView>
    );
  }
}
