import * as React from "react";
import { SafeAreaView, View, Text, ScrollView } from "react-native";
import { styles } from "../Style/styles.js";
import PatientObject from "../HelperClasses/patientObject.js";
import WeeklyResultStorage from "../HelperClasses/WeeklyResults";

const symptomList = [
  "Fatigue",
  "Nausea",
  "Vomiting",
  "Diarrhea",
  "Constipation",
  "Loss of appetite",
  "Cough",
  "Shortness of breath",
  "Skin problems",
  "Pain",
];

export default class WeeklyResults extends React.Component {
  constructor() {
    super();
    this.answerList = WeeklyResultStorage.getResponses();
    this.patientID = WeeklyResultStorage.getPatientID();
    this.timeStamp = WeeklyResultStorage.getTimeStamp();
  }

  render() {
    return (
      <SafeAreaView style={styles.RootContainerWS}>
        <View style={styles.RootSubPageContainer}>
          <View style={styles.QuestionsContainerWSResults}>
            <Text style={styles.ResultsTitle}>Results for</Text>
            <Text style={styles.SmallerQuestionsText}>{this.timeStamp}</Text>
          </View>
          <View style={{ flex: 4 }}>
            <ScrollView
              contentContainerStyle={{
                flexGrow: 1,
                justifyContent: "flex-start",
                alignItems: "space-evenly",
              }}
            >
              {this.answerList.map((entry) => (
                <View key={entry.Question} style={{ flexDirection: "row" }}>
                  <View style={styles.WeeklyResultsQuestionContainer}>
                    <Text
                      numberOfLines={1}
                      style={styles.WeeklyResultTextQuestion}
                    >
                      {entry.Question}
                    </Text>
                  </View>
                  <View style={styles.WeeklyResultsAnswerContainer}>
                    <Text style={styles.WeeklyResultTextAnswer}>
                      {entry.Answer}
                    </Text>
                  </View>
                </View>
              ))}
            </ScrollView>
          </View>
        </View>
      </SafeAreaView>
    );
  }
}
