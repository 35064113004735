/**
 * UNMC Research App navigation
 *
 **/

import * as React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import Weekly from "./surveys/Weekly.js";
import AfterClinic from "./surveys/AfterClinic.js";
import FirstSymptom from "./surveys/FirstSymptom.js";
import Interview1Screen from "./surveys/Interview1.js";
import WeeklyResults from "./results/WeeklyResults.js";
import Interview1Results from "./results/Interview1Results.js";
import { HomeScreen } from "./screens/Home.js";
import { PatientIDScreen } from "./screens/PatientID";
import AdminPage from "./screens/Admin.js";
import { AboutPage } from "./screens/AboutPage.js";
import { AdminLoginScreen } from "./screens/AdminLogin.js";

const Stack = createStackNavigator();

export default function App() {
  return (
    <NavigationContainer>
      <Stack.Navigator>
        <Stack.Screen
          name="PatientID"
          component={PatientIDScreen}
          options={{ title: "Patient ID" }}
        />
        <Stack.Screen
          name="AdminLogin"
          component={AdminLoginScreen}
          options={{ title: "Admin Login" }}
        />
        <Stack.Screen
          name="Admin"
          component={AdminPage}
          options={{ title: "Admin Page" }}
        />
        <Stack.Screen
          name="AboutPage"
          component={AboutPage}
          options={{ title: "About" }}
        />
        <Stack.Screen
          name="HomeScreen"
          component={HomeScreen}
          options={{ title: "Home Page" }}
        />
        <Stack.Screen
          name="Weekly"
          component={Weekly}
          options={{ title: "Weekly Survey" }}
        />
        <Stack.Screen
          name="AfterClinic"
          component={AfterClinic}
          options={{ title: "After Clinic Survey" }}
        />
        <Stack.Screen
          name="FirstSymptom"
          component={FirstSymptom}
          options={{ title: "Initial Symptom Survey" }}
        />
        <Stack.Screen
          name="Interview1"
          component={Interview1Screen}
          options={{ title: "Interview #1" }}
        />
        <Stack.Screen
          name="WeeklyResults"
          component={WeeklyResults}
          options={{ title: "Weekly Survey Results" }}
        />
        <Stack.Screen
          name="Interview1Results"
          component={Interview1Results}
          options={{ title: "Interview #1 Survey Results" }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
