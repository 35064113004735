import {
  Text,
  View,
  Image,
  TouchableOpacity,
  SafeAreaView,
  TextInput,
  ScrollView,
} from "react-native";
import React, { useState } from "react";
import { styles } from "../Style/styles.js";
import PatientObject from "../HelperClasses/patientObject.js";
import WeeklyResultStorage from "../HelperClasses/WeeklyResults";
import logo from "../assets/UNMClogo.png";
import StartButton from "../Buttons/StartButton.js";
import config from "../config.json";
import { refreshAuthToken } from "../Functions/RefreshAuthToken.js";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { heightPercentageToDP } from "react-native-responsive-screen";
import ViewabilityHelper from "react-native-web/dist/vendor/react-native/ViewabilityHelper";

const getData = async () => {
  try {
    const jsonValue = await AsyncStorage.getItem("@patient_ID");
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
  }
};

const rechoosingSymptoms = [
  "Fatigue",
  "Nausea",
  "Vomiting",
  "Diarrhea",
  "Constipation",
  "Loss of appetite",
  "Cough",
  "Shortness of breath",
  "Skin problems",
  "Pain",
];

export default class Weekly extends React.Component {
  constructor() {
    super();
    this.symptomList = [];
    global.questionCounter = 0;
    global.answerList = [];
    this.patient = new PatientObject();
    this.WeeklySurveyCompletedBefore();
    this.state = {
      newSymptoms: false,
      currentQuestion: this.symptomList[0],
      showReturn: true,
      showSubmitButton: false,
      currentlySelectedAnswer: " ",
      currentPageNumber: 0,
      button1Selected: false,
      button2Selected: false,
      button3Selected: false,
      button4Selected: false,
      button5Selected: false,
      button6Selected: false,
      button7Selected: false,
      button8Selected: false,
      button9Selected: false,
      button10Selected: false,
      otherButtonSelected: false,
      haveSymptomsBeenSelected: false,
      symptomInput: "",
    };
  }

  WeeklySurveyCompletedBefore = () => {
    let promiseResult;
    getData().then((patientID) => {
      promiseResult = patientID;
      if (promiseResult != null) {
        var resultsStringParsed;
        var fscompleted;
        var thisWeekly = this;

        var xhr = new XMLHttpRequest();
        var url =
          config.BASE_URL + "/weekly-survey-completed/patient/" + patientID;

        //id = PatientObject.getPatientID();
        xhr.onreadystatechange = function () {
          if (xhr.readyState == XMLHttpRequest.DONE) {
            resultsStringParsed = JSON.parse(this.responseText); //is this incorrect? should be true after completing first symptom survey
            fscompleted = resultsStringParsed.result == true; //THIS IS ACTUALLY STORING IF THE WEEKLY HAS BEEN TAKEN (NOT FS OR FIRST SYMPTOM)
            thisWeekly.constructSymptomList(fscompleted);
          }
        };
        xhr.open("GET", url, true);
        xhr.setRequestHeader(
          "Authorization",
          "Bearer " + PatientObject.getToken()
        );
        xhr.send(null);
      }
    });
  };
  constructSymptomList = (fscompleted) => {
    let promiseResult;
    getData().then((patientID) => {
      if (fscompleted) {
        promiseResult = patientID;
        if (promiseResult != null) {
          var resultsStringParsed;
          var weeklyResults;
          var thisWeekly = this;

          var xhr = new XMLHttpRequest();
          var url = config.BASE_URL + "/previous_week/patient/" + patientID;

          //id = PatientObject.getPatientID();
          xhr.onreadystatechange = function () {
            if (xhr.readyState == XMLHttpRequest.DONE) {
              resultsStringParsed = JSON.parse(this.responseText);
              weeklyResults = resultsStringParsed.result[0].Responses;
              for (let x = 0; x < weeklyResults.length; x++) {
                thisWeekly.symptomList.push(weeklyResults[x].Question);
              }
              thisWeekly.setState({
                currentQuestion: thisWeekly.symptomList[0],
              });
            }
          };
          xhr.open("GET", url, true);
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + PatientObject.getToken()
          );
          xhr.send(null);
        }
      } else {
        var resultsStringParsed;
        var firstSymptomResults;
        var thisWeekly = this;

        var xhr = new XMLHttpRequest();
        var url = config.BASE_URL + "/first-symptom-results/" + patientID;

        //id = PatientObject.getPatientID();
        xhr.onreadystatechange = function () {
          if (xhr.readyState == XMLHttpRequest.DONE) {
            resultsStringParsed = JSON.parse(this.responseText);
            firstSymptomResults = resultsStringParsed.result[0].Responses;
            for (let x = 0; x < firstSymptomResults.length; x++) {
              thisWeekly.symptomList.push(firstSymptomResults[x].Question);
            }
            thisWeekly.setState({
              currentQuestion: thisWeekly.symptomList[0],
            });
          }
        };
        xhr.open("GET", url, true);
        xhr.setRequestHeader(
          "Authorization",
          "Bearer " + PatientObject.getToken()
        );
        xhr.send(null);
        refreshAuthToken();
      }
    });
    //checking to see if the first survey was completed
  };

  /*
   * Purpose: This method sets the next question for the survey and adds to the questionCounter
   * Variables Modified: questionCounter (increasing) and currentQuestion (the new  question)
   *
   */
  nextQuestion() {
    //checking to see if they have selected an answer
    if (this.state.currentlySelectedAnswer != " ") {
      //checking to see if it is the final question
      if (this.state.showSubmitButton) {
        this.updateAnswerList(this.symptomList.length - 1);
        this.updatePageController(4);
        this.submit();
      } else {
        questionCounter++;
        this.setState({
          currentQuestion: this.symptomList[questionCounter],
        });
        this.updateAnswerList(questionCounter - 1);
      }
    }
    //throwing an alert
    else {
      alert("Please select an answer before proceeding.");
    }
  }

  /*
   * Purpose: This method goes back to the previous question for the survey and subtracts the questionCounter
   * Variables Modified: questionCounter (decreasing) and currentQuestion (the previous question)
   *
   *
   * If the question counter is at 0 the previous button will not show
   */
  previousQuestion() {
    if (questionCounter == 0) {
      //If the patient selected to choose new symptoms we go back to the selection or other page
      if (this.state.newSymptoms) {
        if (this.state.otherButtonSelected) {
          this.updatePageController(2);
        } else {
          this.updatePageController(1);
        }
      } else {
        this.updatePageController(0);
      }
    } else {
      questionCounter--;
      this.setState({
        currentQuestion: this.symptomList[questionCounter],
      });
    }
  }

  /*
   * Purpose: This method updates the answer list so that the answers can be stored and then sent to the DB
   * Variables Modified: answerList (changing the value in the array)
   *
   * //TODO
   * Right now this inserts the answers into an array. Eventually they will need to be sent to the DB
   */
  updateAnswerList(index) {
    answerList[index] = this.state.currentlySelectedAnswer;
  }

  /*
   * Purpose: This method tracks the state of the submit button and toggles it off and on depending on where the
   * user is at in the survey.
   *
   */
  toggleSubmitButton() {
    if (questionCounter === this.symptomList.length - 1) {
      this.setState({
        showSubmitButton: true,
      });
    } else {
      this.setState({
        showSubmitButton: false,
      });
    }
  }

  /*
   * Purpose: This method resets all button boolean values to false as well as resets the currently selected answer
   *
   */
  deselectButtons() {
    this.setState({
      currentlySelectedAnswer: " ",
    });
  }

  /*
   * Purpose: This method updates the selected answer variable in the current state in order to display the big emojis that
   * show on the screen when a user selects a face.
   */
  updateSelectedAnswer(updatedAnswer) {
    this.setState({
      currentlySelectedAnswer: updatedAnswer,
    });
  }

  /*
   * Purpose: This method tracks the state of the return button and toggles it off and on depending on where the
   * user is at in the survey.
   *
   * //TODO: make this a submit function that works
   */
  submit() {
    this.setState({
      showResults: true,
    });
    this.packageAnswers();
    var responses = [];
    for (let i = 0; i < this.patient.getSurveyResponse().length; i++) {
      responses.push({
        Question: this.patient.getSurveyResponse()[i].Question,
        Answer: this.patient.getSurveyResponse()[i].Answer,
      });
    }
    var xhr = new XMLHttpRequest();
    xhr.open("POST", config.BASE_URL + "/document", true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + PatientObject.getToken());
    let promiseResult;
    getData().then((patientID) => {
      promiseResult = patientID;
      if (promiseResult != null) {
        xhr.send(
          JSON.stringify({
            PatientID: patientID,
            Responses: responses,
            SurveyType: this.patient.getSurveyType(),
          })
        );
      }
    });
  }

  repopulateResults(enteredPatientID) {
    var resultsStringParsed;

    var xhr = new XMLHttpRequest();
    var url = config.BASE_URL + "/previous_week/patient/" + enteredPatientID;

    //id = PatientObject.getPatientID();
    xhr.onreadystatechange = function () {
      if (xhr.readyState == XMLHttpRequest.DONE) {
        resultsStringParsed = JSON.parse(this.responseText);
        WeeklyResultStorage.updatePatientID(
          resultsStringParsed.result[0].PatientID
        );
        WeeklyResultStorage.updateResponses(
          resultsStringParsed.result[0].Responses
        );
        WeeklyResultStorage.updateTimeStamp(
          resultsStringParsed.result[0].TimeStamp
        );
      }
    };
    xhr.open("GET", url, true);
    xhr.setRequestHeader("Authorization", "Bearer " + PatientObject.getToken());
    xhr.send(null);
  }

  /*
   * Purpose: This method takes the two current lists that we have (answerlist and selectedsymptoms) and compiles them into one patient object
   * the patient object traks timestamps, the survey type, the patient id, a list of questions and answers and could have more in the future.
   */
  packageAnswers() {
    for (let x = 0; x < answerList.length; x++) {
      this.patient.addSurveyResponse(this.symptomList[x], answerList[x]);
    }
    this.patient.setTimeStamp();
    this.patient.setSurveyType("Weekly");
  }

  /*
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * This is the spacer between methods created for the original weekly survey
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   */
  updatePageController(newPageNumber) {
    //checking to see if we are increasing or decreasing pages
    if (this.state.currentPageNumber < newPageNumber) {
      //This switch statement helps decide what the new page numbers will be.
      switch (this.state.currentPageNumber) {
        //we are currently on select a symptom page
        case 0:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        //we are currently on the rate a symptom page
        case 1:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        // we are currently on the add a symptom page
        case 2:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        case 3:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        case 4:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
      }
    } else {
      //This switch statement is for pages going backwards.
      //TODO right now all of these are the same. If we need to add some functionality and make each case unique great but if not we need to remove the redundant code
      switch (this.state.currentPageNumber) {
        case 0:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        case 1:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        case 2:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        case 3:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        case 4:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
      }
    }
  }

  /*
   * Purpose: This method toggles button highlighting on and off. Each button can either be true or false.
   *
   */
  toggleHighlight(newSelectedButton) {
    switch (newSelectedButton) {
      case 1:
        this.setState({ button1Selected: !this.state.button1Selected });
        break;
      case 2:
        this.setState({ button2Selected: !this.state.button2Selected });
        break;
      case 3:
        this.setState({ button3Selected: !this.state.button3Selected });
        break;
      case 4:
        this.setState({ button4Selected: !this.state.button4Selected });
        break;
      case 5:
        this.setState({ button5Selected: !this.state.button5Selected });
        break;
      case 6:
        this.setState({ button6Selected: !this.state.button6Selected });
        break;
      case 7:
        this.setState({ button7Selected: !this.state.button7Selected });
        break;
      case 8:
        this.setState({ button8Selected: !this.state.button8Selected });
        break;
      case 9:
        this.setState({ button9Selected: !this.state.button9Selected });
        break;
      case 10:
        this.setState({ button10Selected: !this.state.button10Selected });
        break;
      case 11:
        this.setState({ otherButtonSelected: !this.state.otherButtonSelected });
        break;
    }
  }

  handleShowSubmitOtherNotSelected() {
    //checking to see if any answers are currently selected
    if (this.symptomList.length == 0) {
      //If no symptoms are selected, we look to see if the other button has been selected
      if (this.state.otherButtonSelected) {
        //if it has we move to the other symptom page
        this.updatePageController(2);
      } else {
        //if it hasn't then we let the user know that they need to select something
        alert("Please select a symptom before continuing");
      }
    }
    //if there are other symptoms selected, we change the page based on whether or not the other button was selected.
    else {
      if (this.state.otherButtonSelected) {
        this.updatePageController(2);
      } else {
        this.updatePageController(3);
      }
    }
  }

  /*
   * Purpose: This method takes in the symptom to be added from a text input. The text input calls this method when it is being changed.
   *
   */
  handleSymptom = (textInput) => {
    this.setState({
      symptomInput: textInput,
    });
  };

  createSymptomList() {
    //takes in buttons and creates a list of symptoms
    this.symptomList = [];
    var buttons = [
      this.state.button1Selected,
      this.state.button2Selected,
      this.state.button3Selected,
      this.state.button4Selected,
      this.state.button5Selected,
      this.state.button6Selected,
      this.state.button7Selected,
      this.state.button8Selected,
      this.state.button9Selected,
      this.state.button10Selected,
    ];

    for (let x = 0, y = 0; x < buttons.length; x++) {
      if (buttons[x]) {
        this.symptomList[y] = rechoosingSymptoms[x];
        y++;
      }
    }
    this.setState({
      currentQuestion: this.symptomList[0],
    });
  }

  /*
   * Purpose: This method takes in a symptom and a response to that symptom and adds it to the answer list and the selected symptom lists.
   */
  handleAddSymptomToSymptomList() {
    if (this.state.symptomInput == null) {
      alert("Please enter in a symptom");
    } else {
      this.symptomList[this.symptomList.length] = this.state.symptomInput;
      this.setState({
        symptomInput: null,
        currentQuestion: this.symptomList[0],
      });
    }
  }

  /*
   * Purpose: prevents users from continuing if they have not added a symptom
   */
  handleOtherPageNext(page) {
    if (this.symptomList.length === 0) {
      alert("Please enter in a symptom");
    } else {
      this.updatePageController(page);
      this.toggleSubmitButton();
    }
  }

  render() {
    return (
      //Root Container
      <SafeAreaView style={styles.RootContainerWS}>
        {this.state.currentPageNumber === 0 ? (
          <View style={styles.RootSubPageContainerWS}>
            <View style={{ flex: 3 }}>
              <Image style={styles.imageWS} source={logo} />
            </View>
            <View style={styles.OpeningContainerWS}>
              <View style={{ flex: 1 }}>
                <Text style={styles.OpeningTextWS}>
                  Since last week, have you experienced any different or new
                  symptoms?
                </Text>
              </View>
              <View style={styles.SmallerQuestionsTextWeekly}>
                <Text style={styles.SmallerTextWS}>
                  If yes, please reselect the symptoms that you are
                  experiencing. If no, please rate how those same symptoms have
                  been affecting you.
                </Text>
              </View>
            </View>
            <View style={styles.WeeklyYesAndNo}>
              <View style={styles.WeeklyYN}>
                <View
                  style={{
                    flex: 1,
                    justifyContent: "flex-end",
                    marginBottom: heightPercentageToDP("1%"),
                  }}
                >
                  <StartButton
                    title="Yes"
                    onPress={() => {
                      this.updatePageController(1);
                      this.setState({
                        newSymptoms: true,
                      });
                    }}
                  />
                </View>
              </View>
              <View style={styles.WeeklyYN}>
                <StartButton
                  title="No"
                  onPress={() => {
                    this.updatePageController(3);
                    this.toggleSubmitButton();
                    this.setState({
                      newSymptoms: false,
                    });
                  }}
                />
              </View>
            </View>
          </View>
        ) : null}
        {this.state.currentPageNumber === 1 ? (
          <View style={styles.RootSubPageContainerWS}>
            <View style={styles.SmallerQuestionsTextWeekly}>
              <View>
                <Text style={styles.QuestionsTextWS}>
                  Please select your symptoms.
                </Text>
              </View>
            </View>
            <View style={styles.OptionsContainerWS}>
              <View style={{ flexDirection: "row" }}>
                <View style={styles.SymptomStackWS}>
                  {this.state.button1Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlight(1);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {rechoosingSymptoms[0]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlight(1);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {rechoosingSymptoms[0]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.button2Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlight(2);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {rechoosingSymptoms[1]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlight(2);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {rechoosingSymptoms[1]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.button3Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlight(3);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {rechoosingSymptoms[2]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlight(3);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {rechoosingSymptoms[2]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.button4Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlight(4);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {rechoosingSymptoms[3]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlight(4);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {rechoosingSymptoms[3]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.button5Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlight(5);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {rechoosingSymptoms[4]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlight(5);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {rechoosingSymptoms[4]}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
                <View style={styles.Options}>
                  {this.state.button6Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlight(6);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {rechoosingSymptoms[5]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlight(6);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {rechoosingSymptoms[5]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.button7Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlight(7);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {rechoosingSymptoms[6]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlight(7);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {rechoosingSymptoms[6]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.button8Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlight(8);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {rechoosingSymptoms[7]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlight(8);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {rechoosingSymptoms[7]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.button9Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlight(9);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {rechoosingSymptoms[8]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlight(9);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {rechoosingSymptoms[8]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.button10Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlight(10);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {rechoosingSymptoms[9]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlight(10);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {rechoosingSymptoms[9]}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              </View>
              {this.state.otherButtonSelected ? (
                <TouchableOpacity
                  style={styles.ButtonInMultiColumnSelected}
                  onPress={() => {
                    this.toggleHighlight(11);
                  }}
                >
                  <Text style={styles.ButtonTextInColumnStack}>Other</Text>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  style={styles.ButtonInMultiColumnUnselected}
                  onPress={() => {
                    this.toggleHighlight(11);
                  }}
                >
                  <Text style={styles.ButtonTextInColumnStack}>Other</Text>
                </TouchableOpacity>
              )}
            </View>
            <View style={styles.NavigationButtonContainerWeekly}>
              <View style={styles.ButtonWithLabelUnderWeekly}>
                <TouchableOpacity
                  onPress={() => {
                    this.updatePageController(0);
                  }}
                >
                  <Image
                    source={require("../assets/icons/BackArrow.png")}
                    resizeMode="contain"
                    style={styles.ReturnButton}
                  />
                  <Text style={styles.TextUnderEmojis}>Previous</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.ButtonWithLabelUnderWeekly}>
                <TouchableOpacity
                  onPress={() => {
                    this.createSymptomList();
                    this.handleShowSubmitOtherNotSelected();
                    this.toggleSubmitButton();
                  }}
                >
                  <Image
                    source={require("../assets/icons/RightArrow.png")}
                    resizeMode="contain"
                    style={styles.NextButton}
                  />
                  <Text style={styles.TextUnderEmojis}>Next</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        ) : null}
        {this.state.currentPageNumber === 2 ? (
          <View style={styles.RootSubPageContainerWS}>
            <View style={styles.SmallerQuestionsTextWeekly}>
              <Text style={styles.QuestionsTextWS}>
                Please enter another symptom in the space below
              </Text>
            </View>
            <View style={styles.SmallerQuestionsTextWeekly}>
              <Text style={styles.SmallerTextWS}>
                (Press the "Add" button to add your symptom. You can add more
                than one if you have more to enter. Hit submit once you are
                finished adding symptoms.)
              </Text>
            </View>
            <View style={{ flex: 2 }}>
              <TextInput
                style={styles.input}
                underlineColorAndroid="transparent"
                placeholder="Enter Symptom Here"
                placeholderTextColor="#989EA4"
                autoCapitalize="words"
                clearTextOnFocus="true"
                onChangeText={this.handleSymptom}
                ref={(input) => {
                  this.textInput = input;
                }}
              />
            </View>
            <View style={styles.NavigationButtonContainerWeekly}>
              <View style={styles.ButtonWithLabelUnderWeekly}>
                <TouchableOpacity
                  onPress={() => {
                    this.updatePageController(1);
                    this.textInput.clear();
                  }}
                >
                  <Image
                    source={require("../assets/icons/BackArrow.png")}
                    resizeMode="contain"
                    style={styles.ReturnButton}
                  />
                  <Text style={styles.TextUnderEmojis}>Previous</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.ButtonWithLabelUnderWeekly}>
                <TouchableOpacity
                  onPress={() => {
                    this.handleAddSymptomToSymptomList();
                    this.textInput.clear();
                  }}
                >
                  <Image
                    source={require("../assets/icons/addIcon.png")}
                    resizeMode="contain"
                    style={styles.AddSymptomButton}
                  />
                  <Text style={styles.TextUnderEmojis}>Add</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.ButtonWithLabelUnderWeekly}>
                <TouchableOpacity
                  onPress={() => {
                    this.handleOtherPageNext(3);
                  }}
                >
                  <Image
                    source={require("../assets/icons/RightArrow.png")}
                    resizeMode="contain"
                    style={styles.NextButton}
                  />
                  <Text style={styles.TextUnderEmojis}>Next</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        ) : null}
        {this.state.currentPageNumber === 3 ? (
          <View style={styles.RootSubPageContainerWS}>
            <View style={styles.QuestionsContainer}>
              <View style={{ flex: 3, justifyContent: "center" }}>
                <Text style={styles.QuestionsTextWS}>
                  How much does this symptom interfere with your daily
                  activities.
                </Text>
              </View>
              <View style={{ flex: 2, justifyContent: "center" }}>
                <Text style={styles.ProgressCounter}>
                  {questionCounter + 1}/{this.symptomList.length}
                </Text>
              </View>
              <View style={{ flex: 2, justifyContent: "center" }}>
                <Text style={styles.QuestionsTextWS}>
                  {this.state.currentQuestion}
                </Text>
              </View>
            </View>
            <View style={styles.SmallEmojiContainer}>
              <View style={styles.emojiLabels}>
                <TouchableOpacity
                  style={styles.EmojiButtons}
                  onPress={() => {
                    this.updateSelectedAnswer("😀");
                  }}
                >
                  <Text style={styles.EmojiText}>😀</Text>
                  <Text style={styles.TextUnderEmojis}>Not at all</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.emojiLabels}>
                <TouchableOpacity
                  style={styles.EmojiButtons}
                  onPress={() => {
                    this.updateSelectedAnswer("😐");
                  }}
                >
                  <Text style={styles.EmojiText}>😐</Text>
                  <Text style={styles.TextUnderEmojis}>Mild</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.emojiLabels}>
                <TouchableOpacity
                  style={styles.EmojiButtons}
                  onPress={() => {
                    this.updateSelectedAnswer("😟");
                  }}
                >
                  <Text style={styles.EmojiText}>😟</Text>
                  <Text style={styles.TextUnderEmojis}>Severe</Text>
                </TouchableOpacity>
              </View>
            </View>
            <View style={styles.BigEmojiContainerWS}>
              <Text style={styles.BigSelectedEmoji}>
                {this.state.currentlySelectedAnswer}
              </Text>
            </View>
            <View style={styles.NavigationButtonContainerWeekly}>
              {this.state.showReturn ? (
                <View style={styles.ButtonWithLabelUnderWeekly}>
                  <TouchableOpacity
                    onPress={() => {
                      this.previousQuestion();
                      this.toggleSubmitButton();
                      this.deselectButtons();
                    }}
                  >
                    <Image
                      source={require("../assets/icons/BackArrow.png")}
                      resizeMode="contain"
                      style={styles.ReturnButton}
                    />
                    <Text style={styles.TextUnderEmojis}>Previous</Text>
                  </TouchableOpacity>
                </View>
              ) : null}
              {!this.state.showSubmitButton ? (
                <View style={styles.ButtonWithLabelUnderWeekly}>
                  <TouchableOpacity
                    onPress={() => {
                      this.nextQuestion();
                      this.toggleSubmitButton();
                      this.deselectButtons();
                    }}
                  >
                    <Image
                      source={require("../assets/icons/RightArrow.png")}
                      resizeMode="contain"
                      style={styles.NextButton}
                    />
                    <Text style={styles.TextUnderEmojis}>Next</Text>
                  </TouchableOpacity>
                </View>
              ) : (
                <View style={styles.ButtonWithLabelUnderWeekly}>
                  <TouchableOpacity
                    onPress={() => {
                      this.nextQuestion();
                    }}
                  >
                    <Image
                      source={require("../assets/icons/CheckMark.png")}
                      resizeMode="contain"
                      style={styles.NextButton}
                    />
                    <Text style={styles.TextUnderEmojis}>Submit</Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>
          </View>
        ) : null}
        {this.state.currentPageNumber === 4 ? (
          <View style={styles.RootSubPageContainer}>
            <View style={styles.QuestionsContainerWSResults}>
              <Text style={styles.ResultsTitle}>Overview</Text>
            </View>
            <View style={{ flex: 4 }}>
              <ScrollView
                contentContainerStyle={{
                  flexGrow: 1,
                  justifyContent: "flex-start",
                  alignItems: "space-evenly",
                }}
              >
                {this.patient.getSurveyResponse().map((entry) => (
                  <View key={entry.Question} style={{ flexDirection: "row" }}>
                    <View style={styles.WeeklyResultsQuestionContainer}>
                      <Text
                        numberOfLines={1}
                        style={styles.WeeklyResultTextQuestion}
                      >
                        {entry.Question}
                      </Text>
                    </View>
                    <View style={styles.WeeklyResultsAnswerContainer}>
                      <Text style={styles.WeeklyResultTextAnswer}>
                        {entry.Answer}
                      </Text>
                    </View>
                  </View>
                ))}
              </ScrollView>
            </View>
          </View>
        ) : null}
      </SafeAreaView>
    );
  }
}
