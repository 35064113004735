import * as React from 'react';
import {TouchableOpacity, Text} from 'react-native';
import {styles} from '../Style/styles.js';

TouchableOpacity.defaultProps = { activeOpacity: 0.5 };

const AboutButton = ({ onPress, title }) => (
    <TouchableOpacity onPress={onPress} style={styles.aboutButton}>
        <Text style={styles.appAboutButtonText}>{title}</Text>
    </TouchableOpacity>
);

export default AboutButton;