import * as React from "react";
import { SafeAreaView, View, Image, TextInput, Text } from "react-native";
import { styles } from "../Style/styles.js";
import StartButton from "../Buttons/StartButton.js";
import logo from "../assets/UNMClogo.png";
import { useState } from "react";
import PatientObject from "../HelperClasses/patientObject.js";
import WeeklyResultStorage from "../HelperClasses/WeeklyResults";
import Interview1ResultsStorage from "../HelperClasses/Interview1Results";
import config from "../config.json";
//import CheckID from '../Functions/CheckID';

// var patientID = '';

// function setToGlobalID(i) {
//   patientID = i;
// }

export function AdminLoginScreen({ route, navigation }) {
  const [adminPassword, setAdminPassword] = useState("");
  //const { itemId } = route;

  return (
    // <SafeAreaView style={styles.start}>
    <SafeAreaView style={{flex: 1,}}>
      <View style={{flex: 2,}}>
        <Image style={styles.imageAdmin} source={logo} />
      </View>
      <View style={styles.AdminTextContainer}>
        <Text style={styles.AdminPageTitle}>Admin Login</Text>
      </View>
      <View style={styles.startAdmin}>
        <View style={styles.adminIDContainer}>
          <TextInput
            style={styles.inputPatID}
            maxLength={10}
            autoFocus={true}
            autoCorrect="false"
            placeholder="admin password here"
            onChangeText={setAdminPassword}
            secureTextEntry="true"
            onSubmitEditing={() => {
              credentialValidation(adminPassword, { route, navigation });
            }}
          />
        </View>
        {/* {setToGlobalID(id)} */}
        <View style={styles.adminBottomButton}>
          <StartButton
            style={styles.startButton}
            title="Login"
            onPress={() => {
              credentialValidation(adminPassword, { route, navigation });
            }}
          />
        </View>
      </View>
    </SafeAreaView>
  );
}

function credentialValidation(adminPassword, { route, navigation }) {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", config.BASE_URL + "/adminlogin", true);
  xhr.setRequestHeader("Content-Type", "application/json");
  let accessTokenStringParsed;
  let accessToken;
  let refreshToken;
  xhr.onreadystatechange = function () {
    if (this.readyState == 4 && this.status == 201) {
      accessTokenStringParsed = JSON.parse(this.responseText);
      accessToken = accessTokenStringParsed.access_token;
      refreshToken = accessTokenStringParsed.refresh_token;
      PatientObject.createToken(accessToken);
      PatientObject.createRefreshToken(refreshToken);

      if (this.readyState == 4 && this.status == 201) {
        navigation.push("Admin", {});
      }
    }
  };
  xhr.send(
    JSON.stringify({
      AdminUserName: "Admin",
      Password: adminPassword,
    })
  );
}
