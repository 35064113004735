import * as React from "react";
import { useState } from "react";
import { TouchableOpacity, Text } from "react-native";
import { styles } from "../Style/styles.js";

TouchableOpacity.defaultProps = { activeOpacity: 0.5 };
const StartButton = ({ onPress, title }) => (
  <TouchableOpacity onPress={onPress} style={styles.startButton}>
    <Text style={styles.appStartButtonText}>{title}</Text>
  </TouchableOpacity>
);

export default StartButton;
