import { StyleSheet, Platform, Dimensions } from "react-native";
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from "react-native-responsive-screen";
import { compareResponsiveSizes as crs } from "../Functions/ResponsiveAdjustment";

const window = Dimensions.get("window");

export const styles = StyleSheet.create({
  AboutBodyContainer: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  aboutButton: {
    elevation: 8,
    backgroundColor: "#d9d9d9",
    borderColor: "#0d0d0d",
    borderRadius: 20,
    borderWidth: 2,
    paddingHorizontal: 60,
  },
  AboutLogoContainer: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  aboutViewButton: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: hp("5%"),
    paddingHorizontal: wp("10%"),
  },
  AboutPageHeaders: {
    fontSize: crs(wp("4.5%"), hp("4.5%")),
    fontWeight: "bold",
    alignSelf: "center",
    color: "#000",
  },
  AboutPageWords: {
    fontSize: crs(wp("3.5%"), hp("3.5%")),
    alignSelf: "center",
    color: "#000",
  },
  AboutTitleContainer: {
    flex: 1,
    marginVertical: hp("2%"),
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  AddSymptomButton: {
    width: crs(wp("7%"), hp("7%")),
    height: crs(wp("7%"), hp("7%")),
    textAlign: "center",
    tintColor: "white",
    borderRadius: crs(wp("7.5%"), hp("7.5%")),
    overflow: "hidden",
    backgroundColor: "#AD122A",
    alignSelf: "center",
  },
  adminBottomButton: {
    alignItems: "center",
    justifyContent: "center",
  },
  adminIDContainer: {
    margin: hp("5%"),
    padding: hp("2%"),
    borderRadius: 40,
    borderWidth: 2,
    alignItems: "center",
    justifyContent: "space-around",
  },
  adminImage: {
    flex: 1,
    width: undefined,
    height: undefined,
    resizeMode: "center",
    paddingVertical: hp("2%"),
    marginHorizontal: wp("4%"),
  },
  AdminPageTitle: {
    fontSize: crs(wp("6.5%"), hp("6.5%")),
    fontWeight: "bold",
    color: "#000",
  },
  AdminTextContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  AdminRowContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  AdminVerticalContainer: {
    flex: 1,
    flexDirection: "column",
    alignContent: "center",
  },
  AdminRowSubContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignContent: "space-between",
  },
  AdminColumnSubContainer: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  AdminExportStatusText: {
    fontSize: crs(wp("2.5%"), hp("2.5%")),
    fontWeight: "bold",
    alignSelf: "center",
    color: "#000",
  },
  AdminExportStatusTextNote: {
    fontSize: crs(wp("2%"), hp("2%")),
    alignSelf: "center",
    color: "#000",
  },
  AdminExportStatusTextContainer: {
    alignSelf: "center",
    marginVertical: hp("1%"),
  },
  AdminExportStatusTextContainerNote: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  AdminPageButtons: {
    fontSize: crs(wp("6%"), hp("6%")),
    fontWeight: "600",
  },
  AdminExportButton: {
    flex: 1,
    justifyContent: "center",
    alignSelf: "center",
  },
  AdminTextInput: {
    height: hp("4%"),
    outline: "none",
    width: wp("40%") < 300 ? wp("40%") : 300,
    textAlign: "center",
    textAlignVertical: "center",
    alignSelf: "center",
    fontSize: crs(wp("4%"), hp("4%")),
  },
  AdminTextInputContainer: {
    flex: 1,
    marginVertical: "2%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 50,
    borderWidth: 2,
  },
  test: {
    width: wp("35%") < 400 ? wp("35%") : 400,
    borderWidth: 2,
    borderRadius: 50,
  },
  AfterClinicIndividualResponseContainer: {
    flexDirection: "column",
    flex: 1,
    paddingHorizontal: wp("6%"),
  },
  AfterClinicResultText: {
    fontSize: crs(wp("4%"), hp("4%")),
    textAlign: "left",
  },
  AfterClinicResultSeparatorLine: {
    flex: 1,
    borderBottomWidth: 0.5,
    borderBottomColor: "black",
    marginVertical: hp("5%"),
  },
  alphaItem: {
    backgroundColor: "#aaaaff",
    borderRadius: 8,
    margin: 4,
    padding: 4,
    justifyContent: "center",
    alignItems: "center",
  },
  alphaText: {
    fontSize: 28,
    AdminPageTitle: {
      fontSize: crs(wp("8%"), hp("8%")),
      fontWeight: "bold",
      alignSelf: "center",
      color: "#000",
    },
  },
  appAboutButtonText: {
    fontSize: 18,
    color: "#0d0d0d",
    fontWeight: "bold",
    alignSelf: "center",
  },
  appStartButtonText: {
    fontSize: crs(wp("5%"), hp("5%")) < 22 ? crs(wp("5%"), hp("5%")) : 22,
    color: "#FFFFFF",
    fontWeight: "bold",
    textAlign: "center",
    textAlignVertical: "center",
  },
  appResultButtonText: {
    fontSize: crs(wp("5%"), hp("5%")) < 22 ? crs(wp("5%"), hp("5%")) : 22,
    color: "#FFFFFF",
    fontWeight: "bold",
    alignSelf: "center",
  },
  appButtonText_SmallButton: {
    fontSize: crs(wp("3%"), hp("3%")),
    color: "#FFFFFF",
    fontWeight: "bold",
    alignSelf: "center",
  },
  BigEmojiContainer: {
    alignItems: "center",
    flex: 3,
    flexDirection: "column",
    justifyContent: "center",
  },
  BigEmojiContainerFS: {
    alignItems: "center",
    flex: 3,
    justifyContent: "center",
  },
  BigEmojiContainerWS: {
    alignItems: "center",
    flex: 3,
    justifyContent: "center",
  },
  BigEmojiContainerI1: {
    flex: 3,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  BigSelectedEmoji: {
    fontSize:
      wp("50%") < 300 ? crs(wp("35%"), hp("35%")) : crs(wp("18%"), hp("18%")),
  },
  bottomNav: {
    alignItems: "center",
    flexDirection: "row-reverse",
    justifyContent: "space-around",
    paddingVertical: 100,
  },
  buttonBottomNav: {
    justifyContent: "space-around",
    alignItems: "center",
  },
  buttonSelected: {
    marginVertical: hp("1%"),
    height: hp("5%"),
    width: wp("70%") < 600 ? wp("70%") : wp("40%"),
    justifyContent: "center",
    alignItems: "center",
    borderRadius: hp("5%"),
    backgroundColor: "#AD122A",
  },
  ButtonInMultiColumnSelected: {
    marginVertical: hp("1%"),
    height: hp("5%"),
    width: wp("40%") < 400 ? wp("40%") : 400,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: hp("5%"),
    borderColor: "4D4D4D",
    borderWidth: 1,
    backgroundColor: "#AD122A",
  },
  ButtonText: {
    fontSize: crs(wp("3%"), hp("3%")),
    color: "white",
  },
  ButtonTextAC: {
    fontSize: crs(wp("5%"), hp("5%")),
    color: "white",
  },
  ButtonTextInColumnStack: {
    fontSize: crs(wp("3%"), hp("3%")),
    color: "white",
  },
  buttonUnselected: {
    marginVertical: hp("1%"),
    height: hp("5%"),
    width: wp("70%") < 600 ? wp("70%") : wp("40%"),
    justifyContent: "center",
    alignItems: "center",
    borderRadius: hp("5%"),
    backgroundColor: "#303B41",
  },
  ButtonInMultiColumnUnselected: {
    marginVertical: hp("1%"),
    height: hp("5%"),
    width: wp("40%") < 400 ? wp("40%") : 400,
    justifyContent: "center",
    alignItems: "center",
    borderColor: "4D4D4D",
    borderRadius: hp("5%"),
    borderWidth: 1,
    backgroundColor: "#303B41",
  },
  ButtonWithLabelUnder: {
    marginHorizontal: wp("6%"),
    flexDirection: "column",
    alignContent: "center",
    textAlign: "center",
    flex: 1,
  },
  ButtonWithLabelUnderAC: {
    justifyContent: "center",
    flex: 1,
  },
  ButtonWithLabelUnderFS: {
    flexDirection: "column",
    alignContent: "center",
    textAlign: "center",
    flex: 1,
  },
  ButtonWithLabelUnderQ1: {
    flexDirection: "column",
    alignContent: "center",
    textAlign: "center",
    flex: 1,
  },
  ButtonWithLabelUnderWeekly: {
    flexDirection: "column",
    alignContent: "center",
    textAlign: "center",
    flex: 1,
  },
  checkedCircle: {
    width: 14,
    height: 14,
    borderRadius: 7,
    backgroundColor: "#131313",
  },
  circle: {
    height: 20,
    width: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#ACACAC",
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 10,
  },
  Container_App: {
    flex: 2,
    justifyContent: "space-between",
    marginBottom: hp("10%"),
  },
  Container_AppAdmin: {
    flex: 2,
    justifyContent: "space-evenly",
  },
  containerQ6: {
    flex: 1,
    padding: 12,
    paddingTop: 40,
  },
  DragDrop: {
    alignItems: "center",
    justifyContent: "center",
    flex: 4,
  },
  EmojiButtons: {
    textAlign: "center",
  },
  emojiLabels: {
    textAlign: "center",
    flex: 1,
  },
  emojiLabelsI1: {
    justifyContent: "center",
    textAlign: "center",
    flex: 1,
  },
  EmojiText: {
    fontSize: crs(wp("10%"), hp("10%")),
  },
  exportAdminContainer: {
    flex: 2,
  },
  image: {
    flex: 1,
    width: undefined,
    height: undefined,
    resizeMode: "center",
    paddingVertical: hp("10%"),
    margin: wp("6%"),
  },
  imageAdmin: {
    flex: 1,
    width: undefined,
    height: undefined,
    resizeMode: "center",
    paddingTop: hp("10%"),
    margin: wp("6%"),
  },
  imageWS: {
    flex: 1,
    width: undefined,
    height: undefined,
    resizeMode: "center",
    paddingVertical: hp("3%"),
    margin: wp("6%"),
  },
  input: {
    height: hp("5%"),
    borderRadius: hp("3%"),
    borderWidth: 1,
    marginTop: hp("2%"),
    width: wp("50%"),
    alignSelf: "center",
    textAlign: "center",
  },
  inputFS: {
    height: hp("5%"),
    borderRadius: hp("3%"),
    borderWidth: 1,
    marginTop: hp("2%"),
    width: wp("60%"),
    alignSelf: "center",
    textAlign: "center",
    textAlignVertical: "center",
  },
  inputI1: {
    height: hp("5%"),
    borderRadius: hp("3%"),
    borderWidth: 1,
    fontSize: crs(wp("3%"), hp("3%")),
    marginTop: hp("2%"),
    width: wp("40%"),
    alignSelf: "center",
    textAlign: "center",
    textAlignVertical: "center",
  },
  inputQuestionIDContainer: {
    flex: 1,
    borderRadius: 40,
    borderWidth: 2,
  },
  inputQuestionIDContainerQ5: {
    flex: 2,
    padding: wp("2%"),
  },
  inputQuestionIDContainerQ8: {
    flex: 4,
    padding: wp("2%"),
  },
  inputPatIDContainer: {
    margin: hp("5%"),
    padding: hp("2%"),
    borderRadius: 40,
    borderWidth: 2,
    alignItems: "center",
    justifyContent: "space-around",
  },
  inputPatID: {
    outline: "none",
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: crs(wp("5%"), hp("5%")) < 22 ? crs(wp("5%"), hp("5%")) : 22,
  },
  inputInRow: {
    height: hp("4%"),
    outline: "none",
    width: wp("40%") < 300 ? wp("40%") : 300,
    textAlign: "center",
    textAlignVertical: "center",
    alignSelf: "center",
    fontSize: crs(wp("3%"), hp("3%")),
  },
  inputNewAdminPassword: {
    height: hp("2%"),
    outline: "none",
    width: wp("60%") < 200 ? wp("60%") : 200,
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: crs(wp("1%"), hp("1%")),
  },
  I1Input: {
    borderRadius: wp("3%"),
    borderWidth: 2,
    outline: "none",
    textAlign: "center",
    textAlignVertical: "top",
    fontSize: crs(wp("3%"), hp("3%")),
  },
  I1ResultsQuestionSeparator: {
    borderBottomWidth: 4,
    borderBottomColor: "black",
    marginVertical: hp("3%"),
    marginHorizontal: "4%",
    alignItems: "center",
  },
  I1ResultsQuestionSpacer: {
    marginVertical: hp("1.5%"),
  },
  I1ResultsQ1: {
    flexDirection: "column",
    marginHorizontal: wp("5%"),
    textAlign: "center",
  },
  I1ResultsQ2: {
    flexDirection: "column",
    marginHorizontal: wp("5%"),
    textAlign: "center",
  },
  I1ResultsQ6: {
    flexDirection: "column",
    textAlign: "left",
  },
  I1ResultsQ6Answers: {
    flexDirection: "column",
    marginHorizontal: wp("2%"),
    textAlign: "left",
  },
  I1ResultsQ7: {
    flexDirection: "column",
    marginHorizontal: wp("5%"),
    textAlign: "center",
  },
  I1ResultsQ8: {
    flexDirection: "column",
    marginHorizontal: wp("5%"),
  },
  InitialResults: {
    flex: 1,
  },
  LabelUnder: {
    alignItems: "center",
    alignContent: "center",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  navButton: {
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  NavigationButtonContainerI1: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    flex: 1,
  },
  NavigationButtonContainerI1Q3Q4Q5: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    alignSelf: "stretch",
    flex: 1,
  },
  NavigationButtonContainerI1Q7: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    alignSelf: "stretch",
    flex: 1,
  },
  NavigationButtonContainer: {
    flexDirection: "row",
    marginTop: hp("8%"),
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 1,
  },
  NavigationButtonContainerAC: {
    flexDirection: "row",
    justifyContent: "center",
    flex: 1,
  },
  NavigationButtonContainerFS: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    alignSelf: "stretch",
    flex: 1,
  },
  NavigationButtonContainerWeekly: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    alignSelf: "stretch",
    flex: 1,
  },
  NextButton: {
    alignSelf: "center",
    width: crs(wp("7%"), hp("7%")),
    height: crs(wp("7%"), hp("7%")),
    textAlign: "center",
  },
  NextButtonAC: {
    alignSelf: "center",
    width: crs(wp("10%"), hp("10%")),
    height: crs(wp("10%"), hp("10%")),
  },
  NextButtonQ1: {
    alignSelf: "center",
    width: crs(wp("7%"), hp("7%")),
    height: crs(wp("7%"), hp("7%")),
    textAlign: "center",
    flex: 1,
  },
  OpeningContainerWS: {
    flex: 2,
  },
  OpeningTextWS: {
    fontSize: crs(wp("4.5%"), hp("4.5%")),
    textAlign: "center",
    fontWeight: "bold",
  },
  OptionsContainer: {
    flex: 2,
    alignItems: "center",
    justifyContent: "space-around",
  },
  OptionsContainerAC: {
    flex: 2,
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  OptionsContainerFS: {
    flex: 2,
    justifyContent: "center",
    alignItems: "center",
  },
  OptionsContainerWS: {
    flex: 3,
    alignItems: "center",
  },
  OptionsContainerI1: {
    flex: 7,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  ProgressCounter: {
    fontSize: crs(wp("4%"), hp("4%")),
    textAlign: "center",
  },
  ProgressCounterI1Q6: {
    fontSize: crs(wp("3%"), hp("3%")),
  },
  QuestionsContainerWSResults: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  QuestionsContainerAC: {
    paddingHorizontal: "4%",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  QuestionsContainerFS: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  QuestionsContainerWS: {
    paddingTop: hp("1.5%"),
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1,
  },
  QuestionsContainerFSAnswers: {
    alignItems: "center",
    flex: 2,
  },
  QuestionsContainerWeekly: {
    alignItems: "center",
    justifyContent: "center",
    flex: 3,
  },
  QuestionsContainerI1: {
    marginHorizontal: hp("2%"),
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  QuestionsContainerI1Q3Q4Q5: {
    marginHorizontal: hp("4%"),
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  QuestionsContainerI1Q6: {
    alignItems: "center",
    justifyContent: "center",
    flex: 2,
  },
  QuestionsContainerI1Q7: {
    marginHorizontal: hp("4%"),
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  QuestionsContainerI1Q8: {
    marginHorizontal: hp("4%"),
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  QuestionsContainerI1Q1: {
    marginVertical: hp("5%"),
    justifyContent: "space-evenly",
    flex: 2,
  },
  QuestionsContainerI1Q1Other: {
    marginHorizontal: hp("2%"),
    flex: 2,
  },
  QuestionsText: {
    fontSize: crs(wp("3.5%"), hp("3.5%")),
    justifyContent: "center",
    textAlign: "center",
    fontWeight: "bold",
    flex: 1,
  },
  QuestionsTextAC: {
    fontSize: crs(wp("5%"), hp("5%")),
    justifyContent: "center",
    textAlign: "center",
    fontWeight: "bold",
    flex: 1,
  },
  QuestionsTextFS: {
    fontSize: crs(wp("4.5%"), hp("4.5%")),
    textAlign: "center",
    fontWeight: "bold",
  },
  QuestionsTextWS: {
    fontSize: crs(wp("4.5%"), hp("4.5%")),
    textAlign: "center",
    fontWeight: "bold",
  },
  QuestionsTextI1Q6: {
    fontSize: crs(wp("4%"), hp("4%")),
    justifyContent: "center",
    textAlign: "center",
    fontWeight: "bold",
  },
  QuestionsTextI1Q7: {
    fontSize: crs(wp("2.75%"), hp("2.75%")),
    textAlign: "center",
    fontWeight: "bold",
  },
  Question7ListItems: {
    padding: hp(".5%"),
    // marginVertical: hp(".25%"),
    backgroundColor: "#303B41",
    borderColor: "#4D4D4D",
    borderRadius: 20,
    borderWidth: 2,
    width: wp("60%"),
    textAlign: "center",
    textAlignVertical: "center",
    color: "white",
    fontSize: crs(wp("3%"), hp("3%")),
  },
  Question7Numbers: {
    padding: hp("1%"),
    marginVertical: hp(".25%"),
    textAlign: "left",
    textAlignVertical: "center",
    color: "black",
    fontSize: crs(wp("3%"), hp("3%")),
  },
  Question7ListContainer: {
    flexDirection: "row",
  },
  radioButtonLabels: {
    fontSize: crs(wp("4.5%"), hp("4.5%")),
  },
  radioButtonContainer: {
    alignSelf: "flex-start",
  },
  radioCircle: {
    size: hp(".2%") < wp(".2%") ? hp(".2%") : wp(".2%"),
  },
  RadioContainer: {
    alignItems: "center",
    flex: 2,
    flexDirection: "row",
    justifyContent: "flex-end",
    textAlign: "left",
  },
  radioText: {
    marginRight: 35,
    fontSize: 20,
    color: "#000",
    fontWeight: "700",
  },
  receiver: {
    width: 100,
    height: 100,
    backgroundColor: "green",
  },
  ReturnButton: {
    alignSelf: "center",
    flex: 1,
    width: crs(wp("7%"), hp("7%")),
    height: crs(wp("7%"), hp("7%")),
    textAlign: "center",
  },
  ReturnButtonAC: {
    alignSelf: "center",
    flex: 1,
    width: crs(wp("10%"), hp("10%")),
    height: crs(wp("10%"), hp("10%")),
    textAlign: "center",
  },
  ReturnButtonQ1: {
    alignSelf: "center",
    width: crs(wp("7%"), hp("7%")),
    height: crs(wp("7%"), hp("7%")),
    textAlign: "center",
    flex: 1,
  },
  RadioResult: {
    marginTop: 20,
    color: "white",
    fontWeight: "600",
    backgroundColor: "#F3FBFE",
  },
  resultButton: {
    elevation: 8,
    backgroundColor: "#AD122A",
    borderColor: "#AD122A",
    borderRadius: 20,
    borderWidth: 2,
    paddingHorizontal: 12,
    paddingVertical: 10,
    width: wp("70%") < 280 ? wp("70%") : 280,
    alignSelf: "center",
  },

  resultStyle: {
    fontSize: crs(wp("3%"), hp("3%")),
    textAlign: "left",
  },
  resultStyleI1Q6Question: {
    fontSize: crs(wp("2.15%"), hp("2.15%")),
    textAlign: "left",
  },
  resultStyleI1Q7: {
    fontSize: crs(wp("3%"), hp("3%")),
    textAlign: "left",
  },
  resultStyleI1Q6Answer: {
    fontSize: crs(wp("2.15%"), hp("2.15%")),
    textAlign: "left",
  },
  ResultsTitle: {
    fontSize: crs(wp("6%"), hp("6%")),
    color: "#000",
    fontWeight: "bold",
    alignSelf: "center",
  },
  Results_Text_I1_Responses: {
    fontSize: wp("3%") < hp("3%") ? wp("3%") : hp("3%"),
    fontWeight: "bold",
    textAlign: "justify",
    width: wp("75%"),
    lineHeight: hp("5%"),
  },
  RootSubPageContainer: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  RootSubPageContainerAC: {
    flex: 1,
  },
  RootSubPageContainerFS: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  RootSubPageContainerWS: {
    flex: 1,
  },
  RootSubPageContainerWSResults: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  RootSubPageContainerQ1: {
    flex: 1,
  },
  RootSubPageContainerQ5: {
    flex: 1,
  },
  RootSubPageContainerQ8: {
    flex: 1,
  },
  RootContainer: {
    flex: 1,
  },
  RootContainerFS: {
    flex: 1,
  },
  RootContainerWS: {
    flex: 1,
    justifyContent: "center",
  },
  screenContainer: {
    flex: 1,
    justifyContent: "center",
    padding: 16,
  },
  selectedRb: {
    width: 15,
    height: 15,
    borderRadius: 50,
    backgroundColor: "#3740ff",
  },
  SmallEmojiContainer: {
    flex: 2,
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center",
  },
  SmallEmojiContainerFS: {
    flex: 2,
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center",
  },
  SmallEmojiContainerI1: {
    flex: 2,
    flexDirection: "row",
    alignSelf: "stretch",
  },
  SmallerQuestionsText: {
    fontSize: crs(wp("3%"), hp("3%")),
    textAlign: "center",
    fontWeight: "bold",
  },
  SmallerQuestionsTextAC: {
    fontSize: crs(wp("5%"), hp("5%")),
    textAlign: "center",
    fontWeight: "bold",
  },
  SmallerQuestionsTextFSHome: {
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: hp("2%"),
    textAlign: "center",
    fontSize: crs(wp("2%"), hp("2%")),
    flex: 1,
  },
  SmallerQuestionsTextFS: {
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: hp("2%"),
    textAlign: "center",
    fontSize: crs(wp("2%"), hp("2%")),
    flex: 2,
  },
  SmallerQuestionsTextI1Q1: {
    fontSize: crs(wp("3%"), hp("3%")),
    fontWeight: "bold",
    textAlign: "center",
  },
  SmallerQuestionsTextI1Q5: {
    fontSize: crs(wp("4.5%"), hp("4.5%")),
    textAlign: "center",
    fontWeight: "bold",
  },
  SmallerQuestionsTextI1Q6: {
    fontSize: crs(wp("3.5%"), hp("3.5%")),
    textAlign: "center",
    marginHorizontal: wp("3%"),
    fontWeight: "bold",
  },
  SmallerQuestionsTextI1Q8: {
    fontSize: crs(wp("4.5%"), hp("4.5%")),
    textAlign: "center",
    fontWeight: "bold",
  },
  SmallerQuestionsTextPatient: {
    fontSize: crs(wp("3.5%"), hp("3.5%")),
    textAlign: "center",
    fontWeight: "bold",
    flex: 1,
  },
  SmallerQuestionsTextQ3: {
    alignItems: "center",
    justifyContent: "center",
    fontSize: crs(wp("4.5%"), hp("4.5%")),
    textAlign: "center",
    fontWeight: "bold",
  },
  SmallerQuestionsTextWeekly: {
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: hp("2%"),
    flex: 1,
  },
  SmallerTextWS: {
    textAlign: "center",
    fontSize: crs(wp("3%"), hp("3%")),
  },
  start: {
    alignItems: "center",
    justifyContent: "flex-start",
  },
  startAdmin: {
    alignItems: "center",
    flex: 2,
  },
  startButton: {
    elevation: 8,
    backgroundColor: "#303B41",
    borderColor: "#4D4D4D",
    borderRadius: 40,
    borderWidth: 2,
    width: wp("75%") < 300 ? wp("75%") : 300,
    height: hp("8%") < 100 ? hp("8%") : 100,
    alignItems: "center",
    justifyContent: "space-around",
  },
  startButton_Small: {
    elevation: 8,
    backgroundColor: "#303B41",
    borderColor: "#4D4D4D",
    borderRadius: 40,
    borderWidth: 2,
    width: wp("40%") < 300 ? wp("40%") : 300,
    height: hp("6%"),
    alignSelf: "center",
    justifyContent: "center",
  },
  survey: {
    fontSize: crs(wp("6%"), hp("6%")),
    fontWeight: "600",
    paddingHorizontal: 35,
    marginTop: 20,
  },
  TextUnderEmojis: {
    fontSize: crs(wp("4.5%"), hp("4.5%")),
  },
  TextUnderEmojisAC: {
    alignSelf: "center",
    fontSize: crs(wp("4.5%"), hp("4.5%")),
    flex: 1,
  },
  timeStamp: {
    textAlign: "center",
  },
  SymptomStack: {
    flexDirection: "column",
    marginHorizontal: wp("2%"),
    flex: 1,
  },
  SymptomStackFS: {
    flexDirection: "column",
    marginHorizontal: wp("2%"),
    flex: 1,
  },
  SymptomStackWS: {
    flexDirection: "column",
    marginHorizontal: wp("2%"),
    flex: 1,
  },
  SymptomStackWeekly: {
    flexDirection: "row",
    marginVertical: wp("2%"),
    flex: 1,
  },
  SymptomStackOther: {
    flexDirection: "column",
    marginHorizontal: wp("2%"),
    flex: 1,
  },
  WeeklyResultsQuestionContainer: {
    flex: 2,
    alignContent: "space-between",
    marginHorizontal: wp("7%"),
  },
  WeeklyResultsAnswerContainer: {
    flex: 1,
    alignContent: "space-between",
    marginHorizontal: wp("7%"),
  },
  WeeklyResultTextQuestion: {
    fontSize: crs(wp("4%"), hp("4%")),
    textAlign: "left",
    overflow: "visible",
  },
  WeeklyResultTextAnswer: {
    fontSize: crs(wp("4%"), hp("4%")),
    textAlign: "right",
  },
  WeeklyYesAndNo: {
    flex: 3,
    alignItems: "center",
  },
  WeeklyYN: {
    flex: 1,
    fontSize: crs(wp("6%"), hp("6%")),
    fontWeight: "600",
  },
});
