import * as React from "react";
import { Text, View, Image, SafeAreaView } from "react-native";
import logo from "../assets/UNMClogo.png";
import SetDate from "../Functions/SetDate";
import StartButton from "../Buttons/StartButton.js";
import ResultButton from "../Buttons/ResultButton.js";
import AboutButton from "../Buttons/AboutButton.js";
import { styles } from "../Style/styles.js";
import { useState, useEffect } from "react";
import PatientObject from "../HelperClasses/patientObject.js";
import config from "../config.json";
import { refreshAuthToken } from "../Functions/RefreshAuthToken";
import WeeklyResultStorage from "../HelperClasses/WeeklyResults";
import Interview1ResultsStorage from "../HelperClasses/Interview1Results";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { FetchRequest } from "../Functions/FetchRequest";

const storeData = async (value) => {
  try {
    const jsonValue = JSON.stringify(value);
    await AsyncStorage.setItem("@patient_ID", jsonValue);
  } catch (e) {
    // saving error
  }
};
const getData = async () => {
  try {
    const jsonValue = await AsyncStorage.getItem("@patient_ID");
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
  }
};

let refreshScreenTimeout = null;
export function cancelTimeout() {
  if (refreshScreenTimeout != null) {
    clearTimeout(refreshScreenTimeout);
    return 1;
  } else {
    return 0;
  }
}

export function HomeScreen({ route, navigation }) {
  const [itemId, setItemId] = useState("");
  let patientID;
  const [loadWeeklyResults, setLoadWeeklyResults] = useState(false); //comment these 4 out to bypass survey requirements
  const [loadWeeklySurvey, setLoadWeeklySurvey] = useState(false);
  const [takenInterview1, setTakenInterview1] = useState(false);
  const [loadFirstSymptomCheck, setLoadFirstSymptomCheck] = useState(false);
  const [interview1Timestamp, setInterview1TimeStamp] = useState(null);
  const [weeklyTimestamp, setWeeklyTimeStamp] = useState(null);
  const [afterClinicTimestamp, setAfterClinicTimestamp] = useState("Not Taken");
  // const [loadWeeklyResults, setLoadWeeklyResults] = useState(true); //uncomment these 4 to bypass survey requirements
  // const [loadWeeklySurvey, setLoadWeeklySurvey] = useState(true);
  // const [takenInterview1, setTakenInterview1] = useState(false);
  // const [loadFirstSymptomCheck, setLoadFirstSymptomCheck] = useState(true);

  const onScreenLoad = async () => {
    let resultsStringParsed;
    populateInterview1Results(patientID);
    populateWeeklyResults(patientID);

    let url = config.BASE_URL + "/HomePageLoad/" + patientID;
    const response = await FetchRequest(url, "GET");

    updateLoadValues(response);
    refreshScreenTimeout = setTimeout(onScreenLoad, 5000);
  };
  const updateLoadValues = (parsedString) => {
    //This will be true if we have taken the first symptom survey
    let firstSymptomParsed = parsedString[2]["First-Symptom"];

    let surveyWeek = parseInt(parsedString[0]["Weekly-Survey-Count"]);

    if (isNaN(surveyWeek)) {
      surveyWeek = 0;
    }

    //this will be true if we have taken interview 1
    let interview1Parsed = parsedString[1].Interview1;

    //this will be true if we have taken interview 1
    let afterClinicParsed = parsedString[3]["After-Clinic"];

    //this will be true if there are weekly results that we can display
    let weeklyResultsParsed = parsedString[0].Weekly;
    let interview1Value = interview1Parsed == true;
    let firstSymptomValue = firstSymptomParsed == true;
    let weeklyResultsValue = weeklyResultsParsed == true;
    let afterClinicValue = afterClinicParsed == true;

    //if we have not taken interview 1 we show it, if we have then we hide it
    setTakenInterview1(interview1Value);
    if (interview1Value) {
      setInterview1TimeStamp(parsedString[1]["Interview1-TimeStamp"]);
    }

    //If we have weekly results then we show it, if we do not then we hide it
    setLoadWeeklyResults(weeklyResultsValue);
    if (weeklyResultsValue) {
      setWeeklyTimeStamp(parsedString[0]["Weekly-TimeStamp"]);
    }

    //If we have not taken the first symptom check and we have already taken the first interview then we show it
    //If we haven't taken interview one yet, then we hide this value
    setLoadFirstSymptomCheck(!firstSymptomValue && interview1Value);

    //We only see the weekly survey if we have taken the first symptom check
    setLoadWeeklySurvey(
      firstSymptomValue && interview1Value && surveyWeek < 10
    );

    if (afterClinicValue) {
      setAfterClinicTimestamp(parsedString[3]["AfterClinic-TimeStamp"]);
    }
  };
  const populateInterview1Results = async (enteredPatientID) => {
    var resultsStringParsed;

    var url = config.BASE_URL + "/interview_one/patient/" + enteredPatientID;

    const response = await FetchRequest(url, "GET");
    Interview1ResultsStorage.updatePatientID(response.result[0].PatientID);
    Interview1ResultsStorage.updateResponses(response.result[0].Responses);
    Interview1ResultsStorage.updateTimeStamp(response.result[0].TimeStamp);
  };
  const populateWeeklyResults = async (enteredPatientID) => {
    var resultsStringParsed;
    var url = config.BASE_URL + "/previous_week/patient/" + enteredPatientID;

    const response = await FetchRequest(url, "GET");

    WeeklyResultStorage.updatePatientID(response.result[0].PatientID);
    WeeklyResultStorage.updateResponses(response.result[0].Responses);
    WeeklyResultStorage.updateTimeStamp(response.result[0].TimeStamp);
  };
  useEffect(() => {
    getData().then((id) => {
      setItemId(id);
      patientID = id;
      if (patientID != null) {
        onScreenLoad();
      }
    });
    //comment this out to bypass survey requirements
  }, []);
  return (
    <SafeAreaView>
      <Image style={styles.image} source={logo} />
      <View style={styles.start}>
        <Text style={styles.SmallerQuestionsTextPatient}>
          Patient ID: {itemId}
        </Text>
        <View style={styles.survey}>
          {loadWeeklySurvey ? (
            <StartButton
              title="Weekly Symptom Check"
              onPress={() => {
                navigation.navigate("Weekly", {
                  itemId: itemId,
                  otherParam: "anything you want here",
                });
              }}
            />
          ) : null}
          {loadWeeklyResults ? (
            <ResultButton
              title="Weekly: Results"
              onPress={() => {
                navigation.navigate("WeeklyResults", {
                  itemId: itemId,
                  otherParam: "whatevs",
                });
              }}
            />
          ) : null}
          {loadWeeklyResults ? (
            <Text style={styles.timeStamp}>
              Last completed : {weeklyTimestamp}
            </Text>
          ) : null}
        </View>
        {loadFirstSymptomCheck ? (
          <View style={styles.survey}>
            <StartButton
              title="Initial Symptom Survey"
              onPress={() => {
                navigation.navigate("FirstSymptom", {
                  itemId: itemId,
                  otherParam: "anything you want here",
                });
              }}
            />
          </View>
        ) : null}

        <View style={styles.survey}>
          {!takenInterview1 ? (
            <StartButton
              title="Interview #1"
              onPress={() => {
                navigation.navigate("Interview1", {
                  itemId: itemId,
                  otherParam: "anything you want here",
                });
              }}
            />
          ) : null}
          {takenInterview1 ? (
            <ResultButton
              title="Interview #1: Results"
              onPress={() => {
                navigation.navigate("Interview1Results", {
                  itemId: itemId,
                  otherParam: "whatevs",
                });
              }}
            />
          ) : null}
          {takenInterview1 ? (
            <Text style={styles.timeStamp}>
              Date completed : {interview1Timestamp}
            </Text>
          ) : null}
        </View>
        <View style={styles.survey}>
          <StartButton
            title="After Clinic Appt"
            onPress={() => {
              navigation.navigate("AfterClinic", {
                itemId: itemId,
                otherParam: "anything you want here",
              });
            }}
          />
          <Text style={styles.timeStamp}>
            Last completed : {afterClinicTimestamp}
          </Text>
        </View>
        <View style={styles.aboutViewButton}>
          <AboutButton
            title="About"
            onPress={() => {
              navigation.navigate("AboutPage", {
                itemId: itemId,
                otherParam: "anything you want here",
              });
            }}
          />
        </View>
      </View>
    </SafeAreaView>
  );
}
