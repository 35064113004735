import * as React from "react";
import { SafeAreaView, View, Text, ScrollView } from "react-native";
import { styles } from "../Style/styles.js";
import StartButton from "../Buttons/StartButton.js";
import Interview1ResultsStorage from "../HelperClasses/Interview1Results";

// export function Interview1ResultsScreen({ route, navigation }) {
//     const { itemId } = route.params;
//     const { otherParam } = route.params;
//     return (
//       <SafeAreaView style={styles.surveyPage}>
//         <Text style= {styles.timeStamp}>patientId: {JSON.stringify(itemId)}</Text>
//         <View style={styles.bottomNav}>
//           <StartButton
//             style={styles.startButton}
//             title="Next"
//             onPress={() =>
//               navigation.push('Interview1Results', {
//                 itemId: itemId,
//               })
//             }
//           />
//           <StartButton style={styles.startButton} title="Home" onPress={() => navigation.navigate('HomeScreen')} />
//           <StartButton style={styles.startButton} title="Back" onPress={() => navigation.goBack()} />
//         </View>
//       </SafeAreaView>
//     );
//   }

export default class Interview1Results extends React.Component {
  constructor() {
    super();
    this.answerList = Interview1ResultsStorage.getResponses();
    this.patientID = Interview1ResultsStorage.getPatientID();
    this.timeStamp = Interview1ResultsStorage.getTimeStamp();
    this.q1Answers = this.answerList[0].Answers;
    this.q2Answer = this.answerList[1].Answer;
    this.q3Answer = this.answerList[2].Answer;
    this.q4Answer = this.answerList[3].Answer;
    this.q5Answer = this.answerList[4].Answer;
    this.q6Answers = this.answerList[5].Answers;
    this.q7Answers = this.answerList[6].Answers;
    this.q8Answer = this.answerList[7].Answer;
    this.count = 1;
    this.textSpacer =
      "                                            " +
      "                                            " +
      "                                            " +
      "                                            " +
      "                                            " +
      "                                            ";
  }

  render() {
    return (
      <SafeAreaView style={styles.surveyPage}>
        <View style={{ flex: 1 }}>
          <View style={styles.RootSubPageContainer}>
            <View style={styles.QuestionsContainer}>
              <Text style={styles.QuestionsText}>Overview</Text>
            </View>
            <ScrollView
              contentContainerStyle={{
                flexGrow: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View style={styles.I1ResultsQuestionSeparator}>
                {/* Results for Q1 */}
                <Text style={styles.SmallerQuestionsText}>
                  Q1: The face chosen that represents your level of tolerance of
                  each side effect
                </Text>
                <View style={{ flexDirection: "row" }}>
                  <View style={styles.I1ResultsQ1}>
                    {this.q1Answers.map((entry) => (
                      <View key={entry["Sub-Question"]}>
                        <Text style={styles.resultStyle}>
                          {entry["Sub-Question"]}
                        </Text>
                      </View>
                    ))}
                  </View>
                  <View style={styles.I1ResultsQ1}>
                    {this.q1Answers.map((entry) => (
                      <View key={entry.Answer + entry["Sub-Question"]}>
                        <Text style={styles.resultStyle}>{entry.Answer}</Text>
                      </View>
                    ))}
                  </View>
                </View>
                <View style={styles.I1ResultsQuestionSpacer}></View>
              </View>
              <View style={styles.I1ResultsQuestionSeparator}>
                {/* Results for Q2 */}
                <Text style={styles.SmallerQuestionsText}>
                  Q2: Which one of the unwanted side effects of treatment from
                  the previous questions would you hate the most?
                </Text>
                <View style={{ flexDirection: "row" }}>
                  <View style={styles.I1ResultsQ2}>
                    <Text style={styles.resultStyle}>{this.q2Answer}</Text>
                  </View>
                </View>
                <View style={styles.I1ResultsQuestionSpacer}></View>
              </View>
              <View style={styles.I1ResultsQuestionSeparator}>
                {/* Results for Q3 */}
                <Text style={styles.SmallerQuestionsText}>
                  Q3: Would you be willing to tolerate ALL of the side effects
                  of treatment listed previously if it meant you might live
                  longer?
                </Text>
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.resultStyle}>{this.q3Answer}</Text>
                </View>
                <View style={styles.I1ResultsQuestionSpacer}></View>
              </View>
              <View style={styles.I1ResultsQuestionSeparator}>
                {/* Results for Q4 */}
                <Text style={styles.SmallerQuestionsText}>
                  Q4: To reach an important personal goal, how long would you be
                  willing to tolerate a higher chance of irritating or
                  uncomfortable side effects of chemotherapy?
                </Text>
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.resultStyle}>{this.q4Answer}</Text>
                </View>
                <View style={styles.I1ResultsQuestionSpacer}></View>
              </View>
              <View style={styles.I1ResultsQuestionSeparator}>
                {/* Results for Q5 */}
                <Text style={styles.SmallerQuestionsText}>
                  Q5: How would you define 'treatment success' (of your
                  chemotherapy?)
                </Text>
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.resultStyle}>{this.q5Answer}</Text>
                </View>
                <View style={styles.I1ResultsQuestionSpacer}></View>
              </View>
              <View style={styles.I1ResultsQuestionSeparator}>
                {/* Results for Q6 */}
                <Text style={styles.SmallerQuestionsText}>
                  Q6: How important this list of statements is to you.
                </Text>
                <View style={{ flexDirection: "row" }}>
                  <View style={styles.I1ResultsQ6}>
                    {this.q6Answers.map((entry) => (
                      <View key={entry["Sub-Question"]}>
                        <Text style={styles.resultStyleI1Q6Question}>
                          {entry["Sub-Question"]}
                        </Text>
                      </View>
                    ))}
                  </View>
                  <View style={styles.I1ResultsQ6}>
                    {this.q6Answers.map((entry) => (
                      <View key={entry.Answer + entry["Sub-Question"]}>
                        <Text style={styles.resultStyleI1Q6Answer}>
                          {entry.Answer}
                        </Text>
                      </View>
                    ))}
                  </View>
                </View>
                <View style={styles.I1ResultsQuestionSpacer}></View>
              </View>
              <View style={styles.I1ResultsQuestionSeparator}>
                {/* Results for Q7 */}
                <Text style={styles.SmallerQuestionsText}>
                  Q7: Ranking Exercise. Ordered from worst (1) to least bad (9).
                </Text>
                <Text>{this.textSpacer}</Text>
                <View style={{ flexDirection: "row" }}>
                  <View style={styles.I1ResultsQ7}>
                    {this.q7Answers.map((entry) => (
                      <View key={this.count}>
                        <Text style={styles.resultStyleI1Q7}>
                          {this.count++}. {entry.Answer.text}
                        </Text>
                      </View>
                    ))}
                  </View>
                </View>

                <View style={styles.I1ResultsQuestionSpacer}></View>
              </View>
              <View style={styles.I1ResultsQuestionSeparator}>
                {/* Results for Q8 */}
                <Text style={styles.SmallerQuestionsText}>Q8: Comments</Text>
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.resultStyle}>{this.q8Answer}</Text>
                </View>
                <View style={styles.I1ResultsQuestionSpacer}></View>
              </View>
            </ScrollView>
          </View>
        </View>
      </SafeAreaView>
    );
  }
}
