export default class PatientObject {
  static __token__;
  static __refreshtoken__;
  static PID;
  constructor() {
    //this.patientID = PatientObject.getPatientID();
    this.surveyResponses = [];
    this.weekOfSurvey = 1;
    this.currentSurveyTimeStamp;
    this.surveyType;

    // this.int1SurveyResponses = [];

    //TEMPORARY TIL INTEGRATED WITH BACKEND

    // for (let i = 0; i < int1Responses.length; i++) {
    //   int1Responses[i] = [q1SymptomList];
    // }
  }

  static updatePatientID(newPID) {
    this.PID = newPID;
  }

  static createToken(__newToken__) {
    this.__token__ = __newToken__;
  }
  static createRefreshToken(__newToken__) {
    this.__refreshtoken__ = __newToken__;
  }

  setPatientID(idFromInt1) {
    this.PID = idFromInt1;
  }

  //getPatientID() {
  //  return this.PID;
  //}

  static getToken() {
    return this.__token__;
  }
  static getRefreshToken() {
    return this.__refreshtoken__;
  }

  //TODO: Just temporary until link backend to front.
  //Then the patient ID can be pulled from there.
  static getPatientID() {
    return this.PID;
  }

  setWeek(week) {
    this.weekOfSurvey = week;
  }

  getWeek() {
    return this.weekOfSurvey;
  }
  setSurveyType(surveyTypeInput) {
    this.surveyType = surveyTypeInput;
  }

  getSurveyType() {
    return this.surveyType;
  }

  setTimeStamp() {
    var currentdate = new Date();
    this.currentSurveyTimeStamp =
      currentdate.getMonth() +
      1 +
      "/" +
      currentdate.getDate() +
      "/" +
      currentdate.getFullYear() +
      " @ " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds();
  }

  getTimeStamp() {
    return this.currentSurveyTimeStamp;
  }

  addSurveyResponse(question, answer) {
    this.surveyResponses.push({
      Question: question,
      Answer: answer,
    });
  }

  getSurveyResponse() {
    return this.surveyResponses;
  }

  //TODO: shorten this up because there are several one answer qs?
  //todo: OR just call addSurveyResponse() instead for these questions?
  addInt1Response(num, question, subQuestions, answers) {
    switch (num) {
      case 1:
        let responses = [];
        for (let i = 0; i < subQuestions.length; i++) {
          responses.push({
            "Sub-Question": subQuestions[i],
            Answer: answers[i],
          });
        }
        this.surveyResponses.push({
          Question: question,
          Answers: responses,
        });
        break;
      case 2:
        this.surveyResponses.push({
          Question: question,
          Answer: answers[0],
        });
        break;
      case 3:
        this.surveyResponses.push({
          Question: question,
          Answer: answers[0],
        });
        break;
      case 4:
        this.surveyResponses.push({
          Question: question,
          Answer: answers[0],
        });
        break;
      case 5:
        this.surveyResponses.push({
          Question: question,
          Answer: answers[0],
        });
        break;
      case 6: //long ass question, will need arrays
        let responses6 = [];
        for (let i = 0; i < subQuestions.length; i++) {
          responses6.push({
            "Sub-Question": subQuestions[i],
            Answer: answers[i],
          });
        }
        this.surveyResponses.push({
          Question: question,
          Answers: responses6,
        });
        break;
      case 7: //ranking question
        let responses7 = [];
        for (let i = 0; i < answers.length; i++) {
          responses7.push({
            Answer: answers[i],
          });
        }
        this.surveyResponses.push({
          Question: question,
          Answers: responses7,
        });
        break;
      case 8: //comments
        this.surveyResponses.push({
          Question: question,
          Answer: answers[0],
        });
        break;
    }
  }

  getInt1Response() {
    return this.surveyResponses;
  }
}
