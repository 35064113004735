import * as React from "react";
import {Text, View, Image, SafeAreaView, TextInput} from "react-native";
import logo from "../assets/UNMClogo.png";
import {styles} from "../Style/styles.js";
import StartButton from "../Buttons/StartButton.js";
import SmallStartButton from "../Buttons/SmallStartButton";
import PatientObject from "../HelperClasses/patientObject.js";
import config from "../config.json";
import {refreshAuthToken} from "../Functions/RefreshAuthToken";

export default class AdminPage extends React.Component {
  constructor() {
    super();
    this.spacer = "        ";
    this.state = {
      patientIdShown: "000",
      newPassword: "",
      patientIDEntered: "",
      exportStatus: "Not Exporting",
      email: "",
    };
  }

  handlePasswordChange = (textInput) => {
    this.setState({
      newPassword: textInput,
    });
  };
  handlePatientIDExportChange = (textInput) => {
    this.setState({
      patientIDEntered: textInput,
    });
  };
  handleEmailChange = (textInput) => {
    this.setState({
      email: textInput,
    });
  };

  render() {
    return (
      <View style={{flex: 1}}>
        <View style={{flex: 1}}>
          <Image style={styles.adminImage} source={logo}/>
          <View style={styles.AdminTextContainer}>
            <Text style={styles.AdminPageTitle}>Admin Only</Text>
          </View>
        </View>
        <View style={styles.Container_AppAdmin}>
          <View style={styles.AdminRowContainer}>
            <View style={styles.AdminColumnSubContainer}>
              <View style={styles.AdminPageButtons}>
                <SmallStartButton
                  title="Generate ID"
                  onPress={() => {
                    this.submitPatientID();
                  }}
                />
              </View>
              <View style={styles.AdminTextInputContainer}>
                <TextInput
                  style={styles.inputInRow}
                  maxLength={3}
                  value={this.state.patientIdShown}
                />
              </View>
            </View>
            <View style={styles.AdminColumnSubContainer}>
              <View style={styles.AdminPageButtons}>
                <SmallStartButton
                  title="Change Password"
                  onPress={() => {
                    this.changePassword(this.state.newPassword);
                  }}
                />
              </View>
              <View style={styles.AdminTextInputContainer}>
                <TextInput
                  style={styles.inputInRow}
                  maxLength={10}
                  placeholder="New Password"
                  onChangeText={this.handlePasswordChange}
                />
              </View>
            </View>
          </View>
          <View style={styles.exportAdminContainer}>
            <View style={styles.AdminRowContainer}>
              <View style={styles.AdminColumnSubContainer}>
                <View style={styles.AdminTextInputContainer}>
                  <TextInput
                    style={styles.inputInRow}
                    maxLength={3}
                    placeholder="Enter ID"
                    onChangeText={this.handlePatientIDExportChange}
                  />
                </View>
                <View style={styles.AdminExportStatusTextContainerNote}>
                  <Text style={styles.AdminExportStatusTextNote}>
                    Enter patient ID or type
                  </Text>
                  <Text style={styles.AdminExportStatusTextNote}>
                    'all' to export ALL patient data.
                  </Text>
                </View>
              </View>
              <View style={styles.AdminColumnSubContainer}>
                <View style={styles.AdminTextInputContainer}>
                  <TextInput
                    style={styles.inputInRow}
                    placeholder="Email"
                    onChangeText={this.handleEmailChange}
                  />
                </View>
                <View style={styles.AdminExportStatusTextContainerNote}>
                  <Text style={styles.AdminExportStatusTextNote}>
                    Will export an excel file to this email
                  </Text>
                  <Text style={styles.AdminExportStatusTextNote}>
                    {this.spacer}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.AdminVerticalContainer}>
              {/*<View style={styles.AdminExportButton}>*/}
              <View style={styles.AdminPageButtons}>
                {/*<StartButton*/}
                <SmallStartButton
                  title="Export Results"
                  onPress={() => {
                    this.export(this.state.patientIDEntered, this.state.email);
                  }}
                />
              </View>
              <View style={styles.AdminExportStatusTextContainer}>
                <Text style={styles.AdminExportStatusText}>
                  {this.state.exportStatus}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }

  submitPatientID() {
    this.setState({
      patientIdShown: "---",
    });
    var xhr = new XMLHttpRequest();
    xhr.open("POST", config.BASE_URL + "/addID", true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + PatientObject.getToken());
    let instance = this;
    let resultsStringParsed;
    let newPatientID;
    xhr.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        resultsStringParsed = JSON.parse(this.responseText);
        newPatientID = resultsStringParsed["result"]["PatientID"];
        instance.setState({
          patientIdShown: newPatientID,
        });
      }
    };
    xhr.send();
    refreshAuthToken();
  }

  changePassword(newPassword) {
    if (newPassword == "") {
      alert("Please enter in a new password.");
      return;
    }
    var xhr = new XMLHttpRequest();
    xhr.open("POST", config.BASE_URL + "/changeAdminPassword", true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + PatientObject.getToken());
    xhr.send(
      JSON.stringify({
        Password: newPassword,
      })
    );
    refreshAuthToken();
  }

  export(searchEntry, email) {
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // if (email != "") {
    if (email.match(emailRegex)) {
      let route;
      if (searchEntry == "" || searchEntry.toLowerCase() == "all") {
        route = "all";
      } else {
        route = searchEntry;
      }
      var xhr = new XMLHttpRequest();
      xhr.open("POST", config.BASE_URL + "/export/" + route, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.setRequestHeader(
        "Authorization",
        "Bearer " + PatientObject.getToken()
      );
      let instance = this;
      xhr.onreadystatechange = function () {
        if (
          this.readyState == 4 &&
          this.responseText.match("No such patientID")
        ) {
          instance.changeExportStatus("Patient ID Not Found");
        } else if (this.readyState == 4 && this.status == 200) {
          instance.changeExportStatus("Export Complete");
        } else {
          instance.changeExportStatus("Export Failed");
        }
      };
      xhr.send(
        JSON.stringify({
          email: email,
        })
      );
      this.changeExportStatus("Exporting " + route.toUpperCase());

      refreshAuthToken();
    } else {
      alert("Please enter in a valid email.");
    }
  }

  changeExportStatus(status) {
    this.setState({exportStatus: status});
  }
}
