import PatientObject from "../HelperClasses/patientObject";
export async function FetchRequest(url, method) {
  const response = await fetch(url, {
    method: method,
    headers: new Headers({
      Authorization: "Bearer " + PatientObject.getToken(),
    }),
    mode: "cors",
    cache: "default",
  });
  const json = await response.json();
  return json;
}
