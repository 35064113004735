export default class WeeklyResultStorage {
  static patientID;
  static responses;
  static timestamp;

  static updatePatientID(newPID) {
    this.patientID = newPID;
  }

  static updateResponses(newResponses) {
    this.responses = newResponses;
  }

  static updateTimeStamp(newTS) {
    this.timestamp = newTS;
  }

  static getResponses() {
    return this.responses;
  }

  static getTimeStamp() {
    return this.timestamp;
  }

  static getPatientID() {
    return this.patientID;
  }
}
