import PatientObject from "../HelperClasses/patientObject";
import config from "../config.json";

export function refreshAuthToken() {
  let accessTokenStringParsed;
  let accessToken;
  var xhr = new XMLHttpRequest();
  xhr.open("POST", config.BASE_URL + "/refresh", true);
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.setRequestHeader(
    "Authorization",
    "Bearer " + PatientObject.getRefreshToken()
  );
  xhr.onreadystatechange = function () {
    if (this.readyState == 4 && this.status == 202) {
      accessTokenStringParsed = JSON.parse(this.responseText);
      accessToken = accessTokenStringParsed.access_token;
      PatientObject.createToken(accessToken);
    }
  };
  xhr.send();
}
