import * as React from "react";
import { SafeAreaView, View, Image, TextInput } from "react-native";
import { styles } from "../Style/styles.js";
import StartButton from "../Buttons/StartButton.js";
import logo from "../assets/UNMClogo.png";
import { useState, useEffect } from "react";
import PatientObject from "../HelperClasses/patientObject.js";
import { cancelTimeout } from "./Home.js";
import AsyncStorage from "@react-native-async-storage/async-storage";
import config from "../config.json";
import { useFocusEffect } from "@react-navigation/native";

const storeData = async (value) => {
  try {
    const jsonValue = JSON.stringify(value);
    await AsyncStorage.setItem("@patient_ID", jsonValue);
  } catch (e) {
    // saving error
  }
};
const getData = async () => {
  try {
    const jsonValue = await AsyncStorage.getItem("@patient_ID");
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
  }
};

export function PatientIDScreen({ route, navigation }) {
  //canceling any timeout functions that have been made
  useFocusEffect(
    React.useCallback(() => {
      cancelTimeout();
    }, [])
  );

  const [patientID, setPatientID] = useState("");
  const onScreenLoad = () => {
    let promiseResult;
    getData().then((patientID) => {
      promiseResult = patientID;
      if (promiseResult != null) {
        credentialValidation(patientID, { route, navigation });
      }
    });
  };
  useEffect(() => {
    // write your code here, it's like componentWillMount
    onScreenLoad(); //comment this out to bypass survey requirements
  }, []);

  return (
    // <SafeAreaView style={styles.start}>
    <SafeAreaView>
      <Image style={styles.image} source={logo} />

      {/* <View style={styles.start}> */}
      <View style={{ alignItems: "center", justifyContent: "space-around" }}>
        <View style={styles.inputPatIDContainer}>
          <TextInput
            style={styles.inputPatID}
            maxLength={10}
            autoCorrect="false"
            placeholder="Enter Patient ID here"
            onChangeText={setPatientID}
            onSubmitEditing={() => {
              credentialValidation(patientID, { route, navigation });
              PatientObject.updatePatientID(patientID);
            }}
          />
        </View>
        {/* {setToGlobalID(id)} */}
        <View style={styles.buttonBottomNav}>
          <StartButton
            // style={styles.startButton}
            title="Submit"
            onPress={() => {
              credentialValidation(patientID, { route, navigation });
              PatientObject.updatePatientID(patientID);
            }}
          />
        </View>
      </View>
    </SafeAreaView>
  );
}

function credentialValidation(enteredPatientID, { route, navigation }) {
  if (enteredPatientID != "") {
    let xhr = new XMLHttpRequest();
    xhr.open("POST", config.BASE_URL + "/login", true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");

    let accessTokenStringParsed;
    let accessToken;
    let refreshToken;
    let instance = this;
    xhr.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 201) {
        accessTokenStringParsed = JSON.parse(this.responseText);
        accessToken = accessTokenStringParsed.access_token;
        refreshToken = accessTokenStringParsed.refresh_token;

        if (enteredPatientID == "Admin") {
          navigation.push("AdminLogin", {
            itemId: enteredPatientID,
          });
        } else {
          PatientObject.createToken(accessToken);
          PatientObject.createRefreshToken(refreshToken);
          navigation.push("HomeScreen", {
            itemId: enteredPatientID,
          });
          storeData(enteredPatientID);
        }
      }
    };
    xhr.send(
      JSON.stringify({
        PatientID: enteredPatientID,
      })
    );
  } else {
    alert("Please enter a valid Patient ID");
  }
}
