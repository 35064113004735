import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Button,
  Pressable,
  TextInput,
  TouchableHighlightBase,
  SafeAreaView,
} from "react-native";
import React, {useState} from "react";
import {styles} from "../Style/styles.js";
import PatientObject from "../HelperClasses/patientObject.js";
import config from "../config.json";
import {refreshAuthToken} from "../Functions/RefreshAuthToken";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {ScrollView} from "react-native-gesture-handler";

const getData = async () => {
  try {
    const jsonValue = await AsyncStorage.getItem("@patient_ID");
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
  }
};

const symptomList = [
  "Fatigue",
  "Nausea",
  "Vomiting",
  "Diarrhea",
  "Constipation",
  "Loss of appetite",
  "Cough",
  "Shortness of breath",
  "Skin problems",
  "Pain",
];

export default class FirstSymptom extends React.Component {
  constructor() {
    super();
    global.questionCounter = 0;
    global.answerList = [];
    global.selectedSymptoms = [];
    this.patient = new PatientObject();
    this.state = {
      currentQuestion: selectedSymptoms[0],
      showReturn: false,
      showSubmitButton: false,
      button1Selected: false,
      button2Selected: false,
      button3Selected: false,
      button4Selected: false,
      button5Selected: false,
      button6Selected: false,
      button7Selected: false,
      button8Selected: false,
      button9Selected: false,
      button10Selected: false,
      otherButtonSelected: false,
      onlyOtherButtonSelected: false,
      currentlySelectedAnswer: " ",
      haveSymptomsBeenSelected: false,
      currentPageNumber: 0,
      symptomInput: null,
      otherFlag: false,
    };
  }

  /*
   * Purpose: This method sets the next question for the survey and adds to the questionCounter
   * Variables Modified: questionCounter (increasing) and currentQuestion (the new  question)
   *
   */
  nextQuestion() {
    //checking to see if they have answered the question
    if (this.state.currentlySelectedAnswer != " ") {
      // checking to see if it is the last question
      if (questionCounter == selectedSymptoms.length - 1) {
        this.updateAnswerList(selectedSymptoms.length - 1);

        //checking to see which page to go to. If the other button was selected we go to the addNewSymptom section
        //if the other button is false then we go to the results page
        if (this.state.otherButtonSelected) {
          this.updatePageController(2);
        } else {
          this.submit();
          this.updatePageController(3);
        }
      } else {
        questionCounter++;
        this.setState({
          currentQuestion: selectedSymptoms[questionCounter],
        });
        this.updateAnswerList(questionCounter - 1);
      }
    } else {
      alert("Please select an answer before proceeding.");
    }
  }

  /*
   * Purpose: This method goes back to the previous question for the survey and subtracts the questionCounter
   * Variables Modified: questionCounter (decreasing) and currentQuestion (the previous question) currentPage(decreasing)
   *
   *
   * If the question counter is at 0 the previous button will not show
   */
  previousQuestion() {
    //checking to see if we are on page 1(rating symptoms page) and also deciding if we need to go back a page or just a question
    if (questionCounter == 0 && this.state.currentPageNumber == 1) {
      this.updatePageController(0);
      selectedSymptoms = [];
    } else {
      questionCounter--;
      this.setState({
        currentQuestion: selectedSymptoms[questionCounter],
      });
    }
  }

  /*
   * Purpose: This method updates the answer list so that the answers can be stored and then sent to the DB
   * Variables Modified: answerList (changing the value in the array)
   *
   *
   */
  updateAnswerList(index) {
    answerList[index] = this.state.currentlySelectedAnswer;
  }

  /*
   * Purpose: This method tracks the state of the submit button and toggles it off and on depending on where the
   * user is at in the survey.
   *
   */
  toggleSubmitButton() {
    if (questionCounter == symptomList.length - 1) {
      this.setState({
        showSubmitButton: true,
      });
    } else {
      this.setState({
        showSubmitButton: false,
      });
    }
  }

  /*
   * Purpose: This method toggles button highlighting on and off. Each button can either be true or false.
   *
   */
  toggleHighlight(newSelectedButton) {
    switch (newSelectedButton) {
      case 1:
        this.setState({button1Selected: !this.state.button1Selected});
        break;
      case 2:
        this.setState({button2Selected: !this.state.button2Selected});
        break;
      case 3:
        this.setState({button3Selected: !this.state.button3Selected});
        break;
      case 4:
        this.setState({button4Selected: !this.state.button4Selected});
        break;
      case 5:
        this.setState({button5Selected: !this.state.button5Selected});
        break;
      case 6:
        this.setState({button6Selected: !this.state.button6Selected});
        break;
      case 7:
        this.setState({button7Selected: !this.state.button7Selected});
        break;
      case 8:
        this.setState({button8Selected: !this.state.button8Selected});
        break;
      case 9:
        this.setState({button9Selected: !this.state.button9Selected});
        break;
      case 10:
        this.setState({button10Selected: !this.state.button10Selected});
        break;
      case 11:
        this.setState({otherButtonSelected: !this.state.otherButtonSelected});
        break;
    }
  }

  /*
   * Purpose: This method resets the currently selected answer in the event that a new page has been selected.
   *
   */
  deselectButtons() {
    this.setState({
      currentlySelectedAnswer: " ",
    });
  }

  /*
   * Purpose: This method takes the buttons that have been selected and puts their corresponding symptoms into
   * a list that can be used later for the results.
   *
   */
  createSymptomList() {
    //takes in buttons and creates a list of symptoms
    var buttons = [
      this.state.button1Selected,
      this.state.button2Selected,
      this.state.button3Selected,
      this.state.button4Selected,
      this.state.button5Selected,
      this.state.button6Selected,
      this.state.button7Selected,
      this.state.button8Selected,
      this.state.button9Selected,
      this.state.button10Selected,
    ];

    for (let x = 0, y = 0; x < buttons.length; x++) {
      if (buttons[x]) {
        selectedSymptoms[y] = symptomList[x];
        y++;
      }
    }
    if (selectedSymptoms.length == 0) {
      this.setState({
        onlyOtherButtonSelected: true,
      });
    }
  }

  /*
   * Purpose: This method controls which page the user is currently on. Due to limits in navigational techniques we simulate
   * having different pages by having a number that tells which section of the file to be showing.
   *
   */
  updatePageController(newPageNumber) {
    //checking to see if we are increasing or decreasing pages
    if (this.state.currentPageNumber < newPageNumber) {
      //This switch statement helps decide what the new page numbers will be.
      switch (this.state.currentPageNumber) {
        //we are currently on select a symptom page
        case 0:
          if (selectedSymptoms.length === 0) {
            if (this.state.otherButtonSelected) {
              this.setState({
                currentPageNumber: 2,
              });
            } else {
              alert("Please select a symptom before proceeding");
            }
          } else {
            this.setState({
              currentQuestion: selectedSymptoms[0],
            });
            this.setState({
              currentPageNumber: newPageNumber,
            });
          }
          break;
        //we are currently on the rate a symptom page
        case 1:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        // we are currently on the add a symptom page
        case 2:
          // if (this.state.symptomInput.match(/\s/) || answerList.length == 0) {
          if (this.state.otherFlag === false) {
            alert(
              'Please type in a symptom, rate it, and hit "Add" before proceeding'
            );
          } else {
            this.setState({
              currentPageNumber: newPageNumber,
            });
            this.submit();
          }
          break;
      }
    } else {
      //This switch statement is for pages going backwards.
      //TODO right now all of these are the same. If we need to add some functionality and make each case unique great but if not we need to remove the redundant code
      switch (this.state.currentPageNumber) {
        case 0:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        case 1:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
        case 2:
          this.setState({
            currentPageNumber: newPageNumber,
          });
          break;
      }
    }
  }

  /*
   * Purpose: This method updates the selected answer variable in the current state in order to display the big emojis that
   * show on the screen when a user selects a face.
   */
  updateSelectedAnswer(updatedAnswer) {
    this.setState({
      currentlySelectedAnswer: updatedAnswer,
    });
  }

  /*
   * Purpose: This method takes in the symptom to be added from a text input. The text input calls this method when it is being changed.
   *
   */
  handleSymptom = (textInput) => {
    this.setState({
      symptomInput: textInput,
    });
  };

  /*
   * Purpose: This method takes in a symptom and a response to that symptom and adds it to the answer list and the selected symptom lists.
   */
  handleAddSymptomToSymptomList() {
    if (
      this.state.symptomInput == null ||
      this.state.currentlySelectedAnswer == " "
    ) {
      alert(
        "Please enter in a symptom and rate that symptom before continuing"
      );
    } else {
      selectedSymptoms[selectedSymptoms.length] = this.state.symptomInput;
      answerList[selectedSymptoms.length - 1] =
        this.state.currentlySelectedAnswer;
      this.setState({
        symptomInput: null,
        currentlySelectedAnswer: " ",
        otherFlag: true,
      });
    }
  }

  /*
   * Purpose: This method checks to see if any symptoms have been selected other than "other".
   * If they have then we go back to page 1, otherwise
   * we go all of the way back to page 0
   */
  handleBackButtonWithAddingSymptoms() {
    if (selectedSymptoms.length === 0) {
      this.updatePageController(0);
    } else if (this.state.onlyOtherButtonSelected) {
      this.updatePageController(0);
    } else {
      this.updatePageController(1);
      questionCounter = selectedSymptoms.length - 1;
      this.setState({
        currentQuestion: selectedSymptoms[questionCounter],
      });
    }
  }

  /*
   * Purpose: This method tracks the state of the submit button and toggles it off and on depending on where the
   * user is at in the survey with some additional constraints.
   * If the other button has not been selected then we need this method
   * to toggle the submit button for the other symptoms.
   */
  handleShowSubmitOtherNotSelected() {
    if (this.state.otherButtonSelected) {
      this.setState({
        showSubmitButton: false,
      });
    } else if (questionCounter == selectedSymptoms.length - 1) {
      this.setState({
        showSubmitButton: true,
      });
    } else {
      this.setState({
        showSubmitButton: false,
      });
    }
  }

  /*
   * Purpose: This method tracks the state of the return button and toggles it off and on depending on where the
   * user is at in the survey.
   *
   * //TODO: make this a submit function that works
   */
  submit() {
    this.packageAnswers();

    var responses = [];
    for (let i = 0; i < this.patient.getSurveyResponse().length; i++) {
      responses.push({
        Question: this.patient.getSurveyResponse()[i].Question,
        Answer: this.patient.getSurveyResponse()[i].Answer,
      });
    }
    var xhr = new XMLHttpRequest();
    xhr.open("POST", config.BASE_URL + "/document", true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + PatientObject.getToken());

    let promiseResult;
    getData().then((patientID) => {
      promiseResult = patientID;
      if (promiseResult != null) {
        xhr.send(
          JSON.stringify({
            PatientID: patientID,
            Responses: responses,
            SurveyType: this.patient.getSurveyType(),
          })
        );
      }
    });
    refreshAuthToken();
  }

  /*
   * Purpose: This method takes the two current lists that we have (answerlist and selectedsymptoms) and compiles them into one patient object
   * the patient object traks timestamps, the survey type, the patient id, a list of questions and answers and could have more in the future.
   */
  packageAnswers() {
    for (let x = 0; x < selectedSymptoms.length; x++) {
      this.patient.addSurveyResponse(selectedSymptoms[x], answerList[x]);
    }
    this.patient.setTimeStamp();
    this.patient.setSurveyType("First-Symptom");
  }

  render() {
    return (
      //Root Container
      <SafeAreaView style={styles.RootContainerFS}>
        {this.state.currentPageNumber === 0 ? (
          <View style={styles.RootSubPageContainerFS}>
            <View style={styles.SmallerQuestionsTextFSHome}>
              <Text style={styles.QuestionsTextFS}>
                Please select your symptoms.
              </Text>
            </View>
            <View style={styles.OptionsContainerFS}>
              <View style={{flexDirection: "row"}}>
                <View style={styles.SymptomStackFS}>
                  {this.state.button1Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlight(1);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {symptomList[0]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlight(1);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {symptomList[0]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.button2Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlight(2);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {symptomList[1]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlight(2);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {symptomList[1]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.button3Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlight(3);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {symptomList[2]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlight(3);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {symptomList[2]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.button4Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlight(4);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {symptomList[3]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlight(4);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {symptomList[3]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.button5Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlight(5);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {symptomList[4]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlight(5);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {symptomList[4]}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
                <View style={styles.Options}>
                  {this.state.button6Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlight(6);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {symptomList[5]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlight(6);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {symptomList[5]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.button7Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlight(7);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {symptomList[6]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlight(7);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {symptomList[6]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.button8Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlight(8);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {symptomList[7]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlight(8);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {symptomList[7]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.button9Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlight(9);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {symptomList[8]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlight(9);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {symptomList[8]}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {this.state.button10Selected ? (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnSelected}
                      onPress={() => {
                        this.toggleHighlight(10);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {symptomList[9]}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.ButtonInMultiColumnUnselected}
                      onPress={() => {
                        this.toggleHighlight(10);
                      }}
                    >
                      <Text style={styles.ButtonTextInColumnStack}>
                        {symptomList[9]}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              </View>
              {this.state.otherButtonSelected ? (
                <TouchableOpacity
                  style={styles.ButtonInMultiColumnSelected}
                  onPress={() => {
                    this.toggleHighlight(11);
                  }}
                >
                  <Text style={styles.ButtonTextInColumnStack}>Other</Text>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  style={styles.ButtonInMultiColumnUnselected}
                  onPress={() => {
                    this.toggleHighlight(11);
                  }}
                >
                  <Text style={styles.ButtonTextInColumnStack}>Other</Text>
                </TouchableOpacity>
              )}
            </View>
            <View style={styles.NavigationButtonContainerFS}>
              <View style={styles.ButtonWithLabelUnder}>
                <TouchableOpacity
                  onPress={() => {
                    this.createSymptomList();
                    this.updatePageController(1);
                    this.handleShowSubmitOtherNotSelected();
                  }}
                >
                  <Image
                    source={require("../assets/icons/RightArrow.png")}
                    resizeMode="contain"
                    style={styles.NextButton}
                  />
                  <Text style={styles.TextUnderEmojis}>Next</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        ) : null}
        {/*
         *
         *
         * This is a divider between page 1 and page 2 of this survey.
         *
         *
         *
         *
         *
         */}
        {this.state.currentPageNumber === 1 ? (
          <View style={styles.RootSubPageContainerFS}>
            <View style={styles.QuestionsContainerFSAnswers}>
              <View style={styles.SmallerQuestionsTextFS}>
                <Text style={styles.QuestionsTextFS}>
                  Please rate how much the following symptom affects your daily
                  activities.
                </Text>
              </View>
              {this.state.otherButtonSelected ? (
                <View style={{flex: 1}}>
                  <Text style={styles.ProgressCounter}>
                    {questionCounter + 1}/{selectedSymptoms.length + 1}
                  </Text>
                </View>
              ) : (
                <View style={{flex: 1}}>
                  <Text style={styles.ProgressCounter}>
                    {questionCounter + 1}/{selectedSymptoms.length}
                  </Text>
                </View>
              )}
              <View style={{flex: 1}}>
                <Text style={styles.QuestionsTextFS}>
                  {this.state.currentQuestion}
                </Text>
              </View>
            </View>
            <View style={styles.SmallEmojiContainerFS}>
              <View style={styles.emojiLabels}>
                <TouchableOpacity
                  style={styles.EmojiButtons}
                  onPress={() => {
                    this.updateSelectedAnswer("😀");
                  }}
                >
                  <Text style={styles.EmojiText}>😀</Text>
                  <Text style={styles.TextUnderEmojis}>Not at all</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.emojiLabels}>
                <TouchableOpacity
                  style={styles.EmojiButtons}
                  onPress={() => {
                    this.updateSelectedAnswer("😐");
                  }}
                >
                  <Text style={styles.EmojiText}>😐</Text>
                  <Text style={styles.TextUnderEmojis}>Mild</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.emojiLabels}>
                <TouchableOpacity
                  style={styles.EmojiButtons}
                  onPress={() => {
                    this.updateSelectedAnswer("😟");
                  }}
                >
                  <Text style={styles.EmojiText}>😟</Text>
                  <Text style={styles.TextUnderEmojis}>Severe</Text>
                </TouchableOpacity>
              </View>
            </View>
            <View style={styles.BigEmojiContainerFS}>
              <View>
                <Text style={styles.BigSelectedEmoji}>
                  {this.state.currentlySelectedAnswer}
                </Text>
              </View>
            </View>
            <View style={styles.NavigationButtonContainerFS}>
              <View style={styles.ButtonWithLabelUnder}>
                <TouchableOpacity
                  onPress={() => {
                    this.previousQuestion();
                    this.deselectButtons();
                  }}
                >
                  <Image
                    source={require("../assets/icons/BackArrow.png")}
                    resizeMode="contain"
                    style={styles.ReturnButton}
                  />
                  <Text style={styles.TextUnderEmojis}>Previous</Text>
                </TouchableOpacity>
              </View>
              {!this.state.showSubmitButton ? (
                <View style={styles.ButtonWithLabelUnder}>
                  <TouchableOpacity
                    onPress={() => {
                      this.nextQuestion();
                      this.updateAnswerList(questionCounter);
                      this.deselectButtons();
                      this.handleShowSubmitOtherNotSelected();
                    }}
                  >
                    <Image
                      source={require("../assets/icons/RightArrow.png")}
                      resizeMode="contain"
                      style={styles.NextButton}
                    />
                    <Text style={styles.TextUnderEmojis}>Next</Text>
                  </TouchableOpacity>
                </View>
              ) : (
                <View style={styles.ButtonWithLabelUnder}>
                  <TouchableOpacity
                    onPress={() => {
                      this.nextQuestion();
                    }}
                  >
                    <Image
                      source={require("../assets/icons/RightArrow.png")}
                      resizeMode="contain"
                      style={styles.NextButton}
                    />
                    <Text style={styles.TextUnderEmojis}>Submit</Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>
          </View>
        ) : null}
        {/*
         *
         *
         * This is a divider between the other symptom page and the symptom rating page of this survey.
         *
         *
         *
         *
         *
         */}
        {this.state.currentPageNumber === 2 ? (
          <View style={styles.RootSubPageContainerFS}>
            <View style={styles.QuestionsContainerFSAnswers}>
              <View style={styles.SmallerQuestionsTextFS}>
                <View>
                  <Text style={styles.QuestionsTextFS}>
                    Please enter another symptom in the space below and rate how
                    much it affects your daily life.
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.SmallerQuestionsTextFS}>
                  (Press the "Add" button to add your symptom. You can add more
                  than one if you have more to enter.)
                </Text>
              </View>
              <View>
                <TextInput
                  style={styles.inputFS}
                  underlineColorAndroid="transparent"
                  placeholder="Enter Symptom Here"
                  placeholderTextColor="#989EA4"
                  autoCapitalize="words"
                  clearTextOnFocus="true"
                  onChangeText={this.handleSymptom}
                  ref={(input) => {
                    this.textInput = input;
                  }} //this provides a reference to this text input in order for
                  // the clear method functionality to work when hitting the 'add' or 'previous' buttons
                />
              </View>
            </View>
            <View style={styles.SmallEmojiContainerFS}>
              <View style={styles.emojiLabels}>
                <TouchableOpacity
                  style={styles.EmojiButtons}
                  onPress={() => {
                    this.updateSelectedAnswer("😀");
                  }}
                >
                  <Text style={styles.EmojiText}>😀</Text>
                  <Text style={styles.TextUnderEmojis}>Not at all</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.emojiLabels}>
                <TouchableOpacity
                  style={styles.EmojiButtons}
                  onPress={() => {
                    this.updateSelectedAnswer("😐");
                  }}
                >
                  <Text style={styles.EmojiText}>😐</Text>
                  <Text style={styles.TextUnderEmojis}>Mild</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.emojiLabels}>
                <TouchableOpacity
                  style={styles.EmojiButtons}
                  onPress={() => {
                    this.updateSelectedAnswer("😟");
                  }}
                >
                  <Text style={styles.EmojiText}>😟</Text>
                  <Text style={styles.TextUnderEmojis}>Severe</Text>
                </TouchableOpacity>
              </View>
            </View>
            <View style={styles.BigEmojiContainerFS}>
              <Text style={styles.BigSelectedEmoji}>
                {this.state.currentlySelectedAnswer}
              </Text>
            </View>
            <View style={styles.NavigationButtonContainerFS}>
              <View style={styles.ButtonWithLabelUnderFS}>
                <TouchableOpacity
                  onPress={() => {
                    this.handleBackButtonWithAddingSymptoms();
                    this.textInput.clear();
                    this.deselectButtons();
                  }}
                >
                  <Image
                    source={require("../assets/icons/BackArrow.png")}
                    resizeMode="contain"
                    style={styles.ReturnButton}
                  />
                  <Text style={styles.TextUnderEmojis}>Previous</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.ButtonWithLabelUnderFS}>
                <TouchableOpacity
                  onPress={() => {
                    this.handleAddSymptomToSymptomList();
                    this.textInput.clear();
                  }}
                >
                  <Image
                    source={require("../assets/icons/addIcon.png")}
                    resizeMode="contain"
                    style={styles.AddSymptomButton}
                  />
                  <Text style={styles.TextUnderEmojis}>Add</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.ButtonWithLabelUnderFS}>
                <TouchableOpacity
                  onPress={() => {
                    this.updatePageController(3);

                    this.textInput.clear();
                  }}
                >
                  <Image
                    source={require("../assets/icons/RightArrow.png")}
                    resizeMode="contain"
                    style={styles.NextButton}
                  />
                  <Text style={styles.TextUnderEmojis}>Submit</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        ) : null}
        {/*
         *
         *
         * This is a divider between the other symptom page and the results page of this survey.
         *
         *
         *
         *
         *
         */}
        {this.state.currentPageNumber === 3 ? (
          <View style={{flex: 1,}}>
            <View style={styles.RootSubPageContainer}>
              <View style={styles.QuestionsContainerWSResults}>
                <Text style={styles.ResultsTitle}>Overview</Text>
              </View>
              <View styles={{flex: 5,}}>
                <ScrollView contentContainerStyle={styles.InitialResults}>
                  {this.patient.getSurveyResponse().map((entry) => (
                    <View key={entry.Question} style={{flexDirection: "row"}}>
                      <View style={styles.WeeklyResultsQuestionContainer}>
                        <Text
                          numberOfLines={1}
                          style={styles.WeeklyResultTextQuestion}
                        >
                          {entry.Question}
                        </Text>
                      </View>
                      <View style={styles.WeeklyResultsAnswerContainer}>
                        <Text style={styles.WeeklyResultTextAnswer}>
                          {entry.Answer}
                        </Text>
                      </View>
                    </View>
                  ))}
                </ScrollView>
              </View>
            </View>
          </View>
        ) : null}
      </SafeAreaView>
    );
  }
}
